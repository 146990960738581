import React from "react";

import { Grid, Card, CardContent } from "@mui/material";
import DashboardScreen from "./DashboardScreen";

export default function Dashboard() {
  return (
    <React.Fragment>
      <div className="dataoutlet">
        <Grid container>
          <Grid item xs={12} sm={12}>
            <div className="formSection">
              <Card variant="none">
                <CardContent>
                  <DashboardScreen />
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
