import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
} from "@mui/material";

import {
  getDashBoardStatistics,
  setSelectedBranch,
} from "../reducers/ReportsDataThunk";
export default function ReportsDashboard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dbbranchdata = useSelector((state) => state.dashboard);
  const [branchname, setbranchname] = useState();
  const [branchdata, setbranchdata] = React.useState([]);

  const hospitalId = user.userProfile.hospital_id;
  useEffect(() => {
    setbranchdata(dbbranchdata.branchList);
  }, [dbbranchdata.branchList]);

  const handleAddFormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    const fieldvalue = event.target.value;
    // console.log("Field Name is ");
    // console.log(fieldname);
    // console.log("Field Value is ");
    // console.log(fieldvalue);
    const newFormData = { ...branchname };
    newFormData[fieldname] = fieldvalue;
    // console.log("Field Value is ");
    // console.log(newFormData);

    setbranchname(newFormData);
    // setBMRNData(newFormData);
  };
  const HandleAddFormSubmit = (event) => {
    event.preventDefault();
    const newFormData = { ...branchname };
    const branch_id = branchname.Bramch;
    const req = { branch_id, hospitalId };

    dispatch(setSelectedBranch(branch_id));
    dispatch(getDashBoardStatistics(req));
    navigate("/Reports");
  };

  return (
    <React.Fragment>
      <div className="dataoutlet">
        <form onSubmit={HandleAddFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
              <Card>
                <CardContent></CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div className="formSection">
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <label
                          htmlFor="Hospital"
                          className="dataentry_label"
                          style={{ display: "block" }}
                        >
                          Hospital
                        </label>
                        <Select
                          labelId="Hospital"
                          id="Hospital"
                          name="Hospital"
                          label=""
                          size="small"
                          className="dataentry_select_edu"
                        >
                          <MenuItem value="Select Hospital">
                            Select Hospital
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <label
                          htmlFor="Branch"
                          className="dataentry_label"
                          style={{ display: "block" }}
                        >
                          Branch
                        </label>
                        <Select
                          labelId="Bramch"
                          id="Bramch"
                          name="Bramch"
                          label=""
                          size="small"
                          className="dataentry_select_edu"
                          value={branchname}
                          onChange={handleAddFormChange}
                        >
                          <MenuItem value="Select Branch">
                            Select Branch
                          </MenuItem>
                          {branchdata && branchdata.length
                            ? branchdata.map((num) => (
                                <MenuItem value={num.hospital_branch_id}>
                                  {num.branch_name}
                                </MenuItem>
                              ))
                            : undefined}
                        </Select>
                      </Grid>
                    </Grid>
                    <Button
                      variant="outlined"
                      className="purple"
                      type="submit"
                      /* onClick={handleClick({
                        vertical: "top",
                        horizontal: "center",
                      })}  */
                    >
                      View Reports
                    </Button>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </React.Fragment>
  );
}
