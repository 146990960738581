import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";

import "../styles/DataEntry.scss";
import "../../common/styles/header.scss";
import moment from "moment";
import { Chart } from "react-google-charts";

import ReactChart from "react-apexcharts";
import GauzeChart from "./GauzeChart";
import { scoreTrend } from "../../Dashboard/reducers/Dashboardthunk";
import logo from "../../login/assets/images/avyantra_logo.png";
import prelogo from "../../login/assets/images/presco_logo.png";
import Download from "../../common/assets/images/Download.svg";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { getPatientDetailsByStudyId } from "../reducers/patientDataThunk";
import ReactSpeedometer from "react-d3-speedometer";
import { Buffer } from "buffer";
import NotificationPdf from "../../ReportsDashboard/components/NotificationPdf";
import "../../poppins/Poppins-Regular-normal";
import "../../poppins/Poppins-Bold-bold";

const PrescDoc = (props) => {
  const textColor = "#000000";
  const dispatch = useDispatch();
  const patient = useSelector((state) => state.patient);
  const studyId = patient.bmrndata?.study_id;

  const baby_medical_record_number =
    patient.bmrndata?.baby_medical_record_number;
  //const scoreData = patient?.updateScore?.response?.score;
  //const scoreData = patient?.bmrndata?.score;

  const scoreData =
    patient && patient?.bmrndata
      ? patient?.bmrndata?.score
      : patient?.updateScore?.response?.score;
  const readingId = patient.bmrndata?.reading_id;

  const [addScoreData, setAddScoreData] = useState({});
  const [open, setOpen] = React.useState(false);
  const [prescDoc, setPrescDoc] = React.useState(false);
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [chartsData, setChartsData] = useState([]);
  const [TrendOptions, setTrendOptions] = React.useState([]);
  const [TrendView, setTrendView] = useState([]);
  const [showDownload, setShowDownload] = useState(true);

  const [notificationData, setNotificationData] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  React.useEffect(() => {
    const generateScore = {
      scoreData,
      color: getColorCode(scoreData),
      risk: riskAssess(getColorCode(scoreData)),
    };
    setAddScoreData(generateScore);
  }, [scoreData]);

  useEffect(() => {
    if (TrendView && TrendView.length) {
      const data = TrendView.map((reading) => {
        return { ...reading };
      });
      setTrendOptions(data.concat([]));
    }
  }, [TrendView]);

  useEffect(() => {
    dispatch(scoreTrend(studyId))
      .unwrap()
      .then((resdata1) => {
        const trendscore = resdata1.response;
        setTrendView(trendscore);
      });
  }, []);

  useEffect(() => {
    const req = { studyId, readingId };
    dispatch(getPatientDetailsByStudyId(req));
  }, []);

  React.useEffect(() => {
    if (TrendOptions && TrendOptions.length) {
      const data = [
        ["Element", "Score", { role: "style" }, { role: "annotation" }],
      ];
      TrendOptions.map((TrendOption) => {
        data.push([
          moment(TrendOption.createdAt).format("DD - MM - YY"),
          TrendOption.score,
          getColorCode(TrendOption.score),
          `${TrendOption.score} (R${TrendOption.reading})`,
        ]);
      });

      setChartsData(data);
    }
  }, [TrendOptions]);

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };
  const handleClose = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const openDialog = () => {
    setOpen(true);
  };

  /* React.useEffect(() => {
    setSelectedPatient(studyId);
    setPrescDoc(true);
  }, []); */

  const closeDialog = () => {
    setOpen(false);
    setPrescDoc(false);
  };

  const getColorCode = (score) => {
    if (score) {
      if (score >= 0 && score <= 5) {
        return "#74D976";
      }
      if (score > 5 && score <= 8) {
        return "#F9D372";
      }
      if (score > 8 && score <= 10) {
        return "#F56D6D";
      }
    }
  };

  const riskAssess = (colorCode) => {
    if (colorCode === "#74D976") {
      return "Low Risk";
    }
    if (colorCode === "#F9D372") {
      return "Medium Risk";
    }
    if (colorCode === "#F56D6D") {
      return "High Risk";
    }
  };

  const today = new Date(),
    time = today.getHours() + ":" + today.getMinutes();
  const values = {
    someDate: new Date().toISOString().substring(0, 10),
  };

  const div2PDF = async (e) => {
    e.preventDefault();
    setShowDownload(false);
    setTimeout(async () => {
      let prescription = window.document.getElementById("score-generation");

      const prescriptionCanvas = await html2canvas(prescription);
      const prescriptionImage = prescriptionCanvas.toDataURL("image/png");
      // var positionInfo = prescription.getBoundingClientRect();
      // console.log(positionInfo.width, positionInfo.height);

      const pdf = new jsPDF("p", "pt", "a4");

      pdf.setFont("Poppins-Bold", "bold");

      var width = prescription.offsetWidth;
      var height = prescription.offsetHeight;

      const imageHeight = (500 * height) / width;

      const heading =
        patient.bmrndata?.baby_medical_record_number + " Prescription";

      pdf.addImage(prescriptionImage, "png", 50, 50, 500, imageHeight);
      // pdf.save(heading + ".pdf");
      const blobData = pdf.output("arraybuffer");
      const buffer = Buffer.from(blobData);
      const base64String = buffer.toString("base64");
      setNotificationData(buffer);
      setDialogOpen(true);
      setShowDownload(true);
    }, 500);
  };

  const chartOptions = {
    // tooltip: { isHtml: true },
    // legend: "none",
    bar: { groupWidth: "30%" },
    hAxis: { title: "Date" },
    series: {
      0: {
        visibleInLegend: false,
      },
    },
    vAxis: {
      title: "Score",
      viewWindow: {
        min: 0,
      },
    },
  };

  return (
    <>
      <NotificationPdf
        data={notificationData}
        open={dialogOpen}
        action={setDialogOpen}
        title="SEPSIS RISK SCORE REPORT"
        style={{ fontFamily: "Poppins" }}
      />
      <div className="dataoutlet">
        <div className="datacontent">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} style={{ margin: "0px auto" }}>
              <Card variant="none">
                <CardContent style={{ padding: "1px" }}>
                  <form id="score-generation">
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <Grid container style={{ padding: "10px 5px" }}>
                          <Grid item xs={12} sm={6}>
                            <div>
                              <img src={logo} style={{ width: "40%" }} />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className="generatedon">
                              Generated On :&nbsp;
                              {moment(values.someDate).format("DD - MM - YY")}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12} sm={12}>
                            <img
                              src={prelogo}
                              className="pre_img"
                              style={{
                                width: "10%",
                                margin: "0px auto",
                                height: "auto",
                              }}
                            />
                            <div className="generatedon1">
                              Neonatal Sepsis Prediction Platform
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12} sm={10}></Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{
                              display: showDownload ? "block" : "none",
                            }}
                          >
                            <div className="generatedon1">
                              <Button
                                variant="contained"
                                size="small"
                                className="report-download-button"
                                style={{ marginRight: "10px" }}
                                // disabled={isLoading || !enableDownload}
                                onClick={(e) => div2PDF(e)}
                              >
                                Print Report
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      spacing={2}
                      style={{ marginTop: "5px" }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ paddingLeft: "2px", paddingRight: "2px" }}
                      >
                        <div
                          className="prescreport"
                          style={{
                            width: "100%",
                            fontWeight: 600,
                            color: "#000000",
                            fontSize: "1.25em",
                            padding: "0.5rem 0",
                          }}
                        >
                          <span style={{ margin: "auto", color: "#fff" }}>
                            SEPSIS RISK SCORE REPORT
                          </span>
                        </div>
                      </Grid>
                      {/* <Grid item xs={12} sm={12}>
                        <p className="presc_ptdetail">Patient Detail</p>
                      </Grid> */}
                      <Grid container style={{ padding: "10px 20px" }}>
                        <Grid item xs={12} sm={12} md={12}>
                          <p className="presc_ptdetail">Patient Detail</p>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            style={{
                              border: "0.5px solid #D2D2D2",
                              padding: "5px 5px",
                              margin: "5px 5px",
                              borderRadius: "0.5rem",
                            }}
                          >
                            <Grid item xs={12} sm={8} md={8}>
                              <div
                                className="presccontent"
                                style={{ textAlign: "initial" }}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="center"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className="PrescTitleHeader"
                                  >
                                    BMRN
                                    <br />
                                    <span>
                                      {
                                        patient.ptDetailsVyStdId
                                          ?.baby_medical_record_number
                                      }
                                    </span>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className="PrescTitleHeader"
                                  >
                                    Mother's Name
                                    <br />
                                    <span>
                                      {patient.ptDetailsVyStdId?.mother_name}
                                    </span>
                                  </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "10px" }}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className="PrescTitleHeader"
                                  >
                                    Hospital
                                    <br />
                                    <span>
                                      {patient.ptDetailsVyStdId?.hospital_name}
                                    </span>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className="PrescTitleHeader"
                                  >
                                    Ward
                                    <br />
                                    <span>
                                      {patient.ptDetailsVyStdId?.ward_name}
                                    </span>
                                  </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "10px" }}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className="PrescTitleHeader"
                                  >
                                    Date: <br />
                                    <span>
                                      {moment(
                                        patient.bmrndata?.createdAt
                                      ).format("DD - MM - YY")}
                                    </span>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className="PrescTitleHeader"
                                  >
                                    Time: <br />
                                    <span>
                                      {moment(
                                        patient.bmrndata?.createdAt
                                      ).format("hh:mm")}
                                    </span>
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                              <div
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  textAlign: "center",
                                }}
                                className="presccontent"
                              >
                                Reading : <span>R{readingId}</span>
                              </div>
                              {/* <div className="speedMeterOverride">
                                <ReactSpeedometer
                                  width={200}
                                  height={130}
                                  forceRender={true}
                                  maxSegmentLabels={1}
                                  customSegmentStops={[0, 5, 8, 10]}
                                  segmentColors={[
                                    "#74d976",
                                    "#f9d372",
                                    "#f56d6d",
                                  ]}
                                  needleColor={addScoreData?.color}
                                  currentValueText={addScoreData?.risk}
                                  minValue={0}
                                  maxValue={10}
                                  value={addScoreData?.scoreData}
                                  textColor={textColor}
                                  ringWidth={27}
                                  needleHeightRatio={0.7}
                                />
                              </div> */}
                              <GauzeChart data={addScoreData} />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <p className="presc_ptdetail">Score Trend</p>
                          <div
                            style={{
                              border: "0.5px solid #D2D2D2",
                              padding: "5px 5px",
                              margin: "5px 5px",
                              borderRadius: "0.5rem",
                            }}
                          >
                            {/*  <ReactChart
                              width="100%"
                              height="300px"
                              series={chartsData}
                              options={chartOptions}
                              type="bar"
                            /> */}
                            <Chart
                              chartType="ColumnChart"
                              width="100%"
                              height="300px"
                              data={chartsData}
                              options={chartOptions}
                            />
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          className="table-grid"
                          style={{
                            padding: "0px 5px",
                            margin: "10px 0px",
                            width: "100%",
                          }}
                        >
                          <TableContainer
                            style={{
                              border: "1px solid #D9D9D9",
                              borderRadius: "0.5rem",
                            }}
                          >
                            <Table aria-label="simple table">
                              <TableHead style={{ background: "#D9D9D9" }}>
                                <TableCell align="center" id="table-cell">
                                  <span className="color-indicator bg-green"></span>
                                </TableCell>
                                <TableCell align="center" id="table-cell">
                                  Low Risk
                                </TableCell>
                                <TableCell align="center" id="table-cell">
                                  <span className="color-indicator bg-orange"></span>
                                </TableCell>
                                <TableCell align="center" id="table-cell">
                                  Medium Risk
                                </TableCell>
                                <TableCell align="center" id="table-cell">
                                  <span className="color-indicator bg-red"></span>
                                </TableCell>
                                <TableCell align="center" id="table-cell">
                                  High Risk
                                </TableCell>

                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                              </TableHead>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <div className="prescfooter">avyantra@gmail.com</div>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default PrescDoc;
