import { createSlice } from "@reduxjs/toolkit";
import * as WardThunk from "../reducers/wardThunk";
import { initialState } from "./wardInitialState";

const wardSlice = createSlice({
  name: "ward",
  initialState,
  extraReducers: {
    [WardThunk.addWardDetails.fulfilled]: (state, action) => {
      state.warddata = action.payload.response;
    },
    [WardThunk.getWardDetail.fulfilled]: (state, action) => {
      state.wardList = action.payload.response;
    },

    [WardThunk.setAddWardFormData.fulfilled]: (state, action) => {
      state.warddata = action.payload;
    },
    [WardThunk.setReferalDoctorFormData.fulfilled]: (state, action) => {
      state.RefDoctorData = action.payload;
    },

    [WardThunk.getBranchDetail.fulfilled]: (state, action) => {
      state.branchList = action.payload.response;
    },

    [WardThunk.getmapedBranchDetail.fulfilled]: (state, action) => {
      state.branchMaping = action.payload.response;
    },
    [WardThunk.getRefDoctorsFromTable.fulfilled]: (state, action) => {
      state.RefDoctorsList = action.payload.response;
    },

    [WardThunk.addMapWithBranch.fulfilled]: (state, action) => {
      state.mapData = action.payload.response;
    },

    [WardThunk.setErrorMessage.fulfilled]: (state, action) => {
      state.errorMessage = action.payload;
    },
    [WardThunk.setsuccessMessage.fulfilled]: (state, action) => {
      state.successMessage = action.payload;
    },
    [WardThunk.addReferralDoctorToTable.fulfilled]: (state, action) => {
      state.refDrDetails = action.payload.response;
    },
    [WardThunk.updateReferalDoctorDetails.fulfilled]: (state, action) => {
      state.refDrDetails = action.payload.response;
    },
    [WardThunk.deleteReferralDoctor.fulfilled]: (state, action) => {
      state.deleteRefDoctor = action.payload.response;
    },
    [WardThunk.deleteAdmin.fulfilled]: (state, action) => {
      state.deleteAdmin = action.payload.response;
    },
    [WardThunk.activateAdmin.fulfilled]: (state, action) => {
      state.activateAdmin = action.payload.response;
    },
    [WardThunk.activateBranch.fulfilled]: (state, action) => {
      state.activateBranch = action.payload.response;
    },
    [WardThunk.activateWard.fulfilled]: (state, action) => {
      state.activateWard = action.payload.response;
    },
    [WardThunk.activateReferalDr.fulfilled]: (state, action) => {
      state.activateReferalDr = action.payload.response;
    },
    [WardThunk.resendVerification.fulfilled]: (state, action) => {
      state.resendVerification = action.payload.response;
    },

    [WardThunk.getHospitalRoles.fulfilled]: (state, action) => {
      state.RolesLIst = action.payload.response;
    },
  },
});

export default wardSlice.reducer;
