import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Alert,
  AlertTitle,
  Snackbar,
} from "@mui/material";

import "../../login/styles/login.scss";
import "../../dataEntry/styles/DataEntry.scss";

import logo from "../../common/assets/images/avyantra_logo.png";
import prelogo from "../../common/assets/images/presco_logo.png";

const EmailVerifyMessage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={12}>
        <Grid container style={{
              marginTop: "3%",
            }}>
            <Grid item xs={12} sm={12}>
              <img src={prelogo} className="pre_img" />
              <div className="pre_caption">
                Neonatal Sepsis Prediction Platform
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
            <Box className="login-container">
              <Grid
                container
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
                style={{
                  marginTop: "3%",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  //columns={{ xs: 1, sm: 12, md: 6 }}
                >
                  <Card variant="outlined">
                    <CardContent>
                      <p className="cre_acc">Verification!</p>
                      <p className="forgot_cont">
                        Doctor, You have successfully Verified Your Account!
                      </p>

                      <form id="mother-medical-form">
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <p style={{ margin: "0px 15px" }}>
                              Don't have an account?
                              <Link to="/Signup">Sign Up</Link>
                            </p>
                          </Grid>
                        </Grid>
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <div className="signUpBg">
            <Grid className="copy-posi">
              <p className="Copyright">
                Copyright © 2019-2022 Avyantra Health Technologies. All Rights
                Reserved
              </p>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EmailVerifyMessage;
