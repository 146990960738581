import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Grid, TextField, Button, Box } from "@mui/material";

const NewPwd = () => {
  const user = useSelector((state) => state.user);
  const Profile = useSelector((state) => state.Profile);
  const dispatch = useDispatch();

  const userId = user?.userProfile?.user_id;

  const handleAddFormChange = (event) => {
    // console.log("We are in handle form change");
  };

  const HandleAddFormSubmit = (event) => {
    event.preventDefault();
    //const newFormData = { ...profileData };
  };

  return (
    <React.Fragment>
      <Card variant="none">
        <CardContent>
          <form onSubmit={HandleAddFormSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={10}>
                <h4 className="branch-header">password Change</h4>
              </Grid>
            </Grid>
            <hr />
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <label
                      htmlFor="Old password"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Old Password
                    </label>
                    <TextField
                      id="old_pwd"
                      name="old_pwd"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      onChange={handleAddFormChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label
                      htmlFor="New Password"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      New Password
                    </label>
                    <TextField
                      id="new_pwd"
                      name="new_pwd"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      onChange={handleAddFormChange}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <label
                      htmlFor="Re Enter Password"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Password
                    </label>
                    <TextField
                      id="confirm_pwd"
                      name="confirm_pwd"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ "& > :not(style)": { m: 1 } }}>
              <Button
                variant="outlined"
                color="primary"
                aria-label="add"
                className="floating-btn"
                type="submit"
              >
                Edit
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default NewPwd;
