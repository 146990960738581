import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  TextField,
  Grid,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { Chart as ChartJS, registerables } from "chart.js";

import { DataGrid } from "@mui/x-data-grid";
import { CustomPagination } from "../../Dashboard/components/DashboardScreen";
import moment from "moment";

import "../../dataEntry/styles/DataEntry.scss";

import { GetBabyHealthRecordsData } from "../reducers/ReportsDataThunk";
import Stack from "@mui/material/Stack";

const BabyHealthRecords = () => {
  ChartJS.register(...registerables);
  const user = useSelector((state) => state.user);
  const dbbranchdata = useSelector((state) => state.dashboard);

  const hospital_id = user.userProfile.hospital_id;
  const branch = useSelector((state) => state.Reports?.SelectedBranchdata);
  const dispatch = useDispatch();
  const [BabyHealthRecords, setBabyHealthRecords] = useState([]);
  const [fromDate, setFromDate] = React.useState(
    moment().subtract(1, "Year").format()
  );
  const [toDate, setToDate] = React.useState(moment().format());
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    getReports();
  }, [fromDate, toDate, branch?.hospital_branch_id]);
  const getReports = () => {
    setIsLoading(true);
    let params;
    params = {
      start: moment(fromDate).format("MM-DD-YYYY"),
      end: moment(toDate).format("MM-DD-YYYY"),
    };
    const req = { hospital_id, branch_id: branch?.hospital_branch_id, params };
    dispatch(GetBabyHealthRecordsData(req))
      .unwrap()
      .then((resdata) => {
        let id = 1;
        const data = resdata.response.healthRecords.map((record) => {
          return { ...record, id: id++ };
        });
        setIsLoading(false);
        setBabyHealthRecords(data);
      });
  };

  const columns = [
    {
      field: "baby_medical_record_number",
      headerName: "BMRN",
      flex: 1,
      width: 140,
      renderCell: (params) => (
        <span className="dadata babyBodyData">
          {params.row.baby_medical_record_number}
        </span>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date Of Admission",
      flex: 1,
      width: 150,
      renderCell: (params) => (
        <span className="dadata">
          {moment(params.row.createdAt).format("DD - MM - YY")}
        </span>
      ),
    },

    {
      field: "reading_id",
      headerName: "Reading",
      flex: 1,
      width: 150,
      renderCell: (params) => (
        <span className="dadata">
          {"R"}
          {params.row.reading_id}
        </span>
      ),
    },
    {
      field: "hospital_name",
      headerName: "Hospital Name",
      flex: 1,
      width: 160,
      renderCell: (params) => (
        <span className="dadata">{params.row.hospital_name}</span>
      ),
    },
    {
      field: "branch_name",
      headerName: "Branch Name",
      flex: 1,
      width: 200,

      renderCell: (params) => (
        <span className="dadata">{params.row.branch_name}</span>
      ),
    },
  ];

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div>
          <Card variant="none">
            <CardContent>
              <div>
                <div className="reports_chart_title">
                  {/* <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        margin: "0px auto",
                        textAlign: "center",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Baby Health Records
                    </Grid>
                  </Grid>
                  <hr /> */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      style={{
                        // margin: "0px auto",
                        // textAlign: "center",
                        marginTop: 30,
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Baby Health Records
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <label
                        htmlFor="From"
                        className="dataentry_label1"
                        style={{ display: "block" }}
                      >
                        From
                      </label>
                      <DatePicker
                        id="from_month"
                        name="from_month"
                        minDate="01-01-2020"
                        maxDate={toDate}
                        value={fromDate}
                        inputFormat="DD-MM-YYYY"
                        className="dataentry_input"
                        onChange={(date) => {
                          setFromDate(date["$d"]);
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            className="dataentry_input"
                            helperText={null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <label
                        htmlFor="To"
                        className="dataentry_label1"
                        style={{ display: "block" }}
                      >
                        To
                      </label>
                      <DatePicker
                        id="to_month"
                        name="to_month"
                        size="small"
                        className="dataentry_input"
                        inputFormat="DD-MM-YYYY"
                        minDate={fromDate}
                        maxDate={moment().format()}
                        value={toDate}
                        onChange={(date) => {
                          setToDate(date["$d"]);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            className="dataentry_input"
                            helperText={null}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
                <hr className="tag-line-border" />
                <div style={{ height: 480, width: "100%" }}>
                  {isLoading ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        height: "480px",
                      }}
                    >
                      <Grid sm={1}>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  ) : (
                    <DataGrid
                      rows={BabyHealthRecords}
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      showCellRightBorder={true}
                      getRowId={(row) => row.id}
                      className="tableborder babyHealthTable"
                      components={{
                        Footer: CustomPagination,
                        NoRowsOverlay: () => (
                          <Stack
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            No Baby Health Records Found
                          </Stack>
                        ),
                        NoResultsOverlay: () => (
                          <Stack
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            No Baby Health Records Found
                          </Stack>
                        ),
                      }}
                    />
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default BabyHealthRecords;
