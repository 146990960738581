import { createAsyncThunk } from "@reduxjs/toolkit";
import PatientDataService from "../../api/apiCommonService";


export const GetSepsisStatusData = createAsyncThunk(
    "GetSepsisStatusData",
    async (req) => {
      const res = await PatientDataService.GetSepsisStatusData(req);
      return res.data;
    }
  );

  export const getReadingVsSepsisData = createAsyncThunk(
    "getReadingVsSepsisData",
    async (req) => {
      const res = await PatientDataService.getReadingVsSepsisData(req);
      return res.data;
    }
  );
  
  export const getReadingVsSepsisDataByReadingId = createAsyncThunk(
    "getReadingVsSepsisDataByReadingId",
    async (req) => {
      const res = await PatientDataService.getReadingVsSepsisDataByReadingId(req);
      return res.data;
    }
  );
  
  export const GetBabyHealthRecordsData = createAsyncThunk(
    "GetBabyHealthRecordsData",
    async (req) => {
      const res = await PatientDataService.GetBabyHealthRecordsData(req);
      return res.data;
    }
  );
  export const GetGenderDistributionData = createAsyncThunk(
    "GetGenderDistributionData",
    async (req) => {
      const res = await PatientDataService.GetGenderDistributionData(req);
      return res.data;
    }
  );
  export const GetPreTermCheckData = createAsyncThunk(
    "GetPreTermCheckData",
    async (req) => {
      const res = await PatientDataService.GetPreTermCheckData(req);
      return res.data;
    }
  );
  export const GetTypeOFDeliveryData = createAsyncThunk(
    "GetTypeOFDeliveryData",
    async (req) => {
      const res = await PatientDataService.GetTypeOFDeliveryData(req);
      return res.data;
    }
  );
  export const GetEosVsLosData = createAsyncThunk(
    "GetEosVsLosData",
    async (req) => {
      const res = await PatientDataService.GetEosVsLosData(req);
      return res.data;
    }
  );
  export const GetFinalVsPreliminaryData = createAsyncThunk(
    "GetFinalVsPreliminaryData",
    async (req) => {
      const res = await PatientDataService.GetFinalVsPreliminaryData(req);
      return res.data;
    }
  );
  export const GetCrpVSBloodCultureData = createAsyncThunk(
    "GetCrpVSBloodCultureData",
    async (req) => {
      const res = await PatientDataService.GetCrpVSBloodCultureData(req);
      return res.data;
    }
  );
  export const getDashBoardStatistics = createAsyncThunk(
    "getDashBoardStatistics",
    async (req) => {
      const res = await PatientDataService.getDashBoardStatistics(req);
      return res.data;
    }
  );
  export const setSelectedBranch = createAsyncThunk(
    "setSelectedBranch",
    async (req) => req
  );