import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import moment from "moment";
import {
  Grid,
  Card,
  CardContent,
  Button,
  DialogTitle,
  Alert,
  AlertTitle,
  Dialog,
  DialogContent,
} from "@mui/material";
import "../../dataEntry/styles/DataEntry.scss";
import { Box } from "@mui/system";
import { deletePatientReading } from "../reducers/patientDataThunk";
import { ToastContainer, toast } from "react-toastify";

const DeleteSelReading = (props) => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const dbbranchdata = useSelector((state) => state.dashboard);

  const hospitalId = user.userProfile.hospital_id;
  const hospitalBranchId = user.branchdata.branch_id;
  const studyId =
    props && props.selectedPatient
      ? props.selectedPatient.study_id
      : patient.bmrndata?.study_id;

  const readingId =
    props && props.selectedPatient
      ? props.selectedPatient.reading_id
      : patient.bmrndata?.reading_id;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const closeDialog = (e) => {
    e.preventDefault();
    props.onClose();
  };
  const deleteselrecord = (e) => {
    const req = { readingId, studyId };
    e.preventDefault();
    dispatch(deletePatientReading(req)).then(() => {
      toast.success("Selected Reading Deleted Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });

    props.onClose();
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Dialog
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
        open={props.open}
        onClose={props.onClose}
      >
        <DialogTitle style={{ borderBottom: "1px solid #000" }}>
          <Link
            to="/"
            className="FIle-left"
            onClick={closeDialog}
            style={{ marginRight: "20px" }}
          >
            <img
              src={Back}
              className="file-img-left"
              alt=""
              style={{ width: "12px" }}
            />
          </Link>
          <span>Delete Conformation</span>
          <Link to="/" className="FIle-right-extreme" onClick={closeDialog}>
            <img src={Close} className="file-img-right-extreme" alt="" />
          </Link>
        </DialogTitle>
        <DialogContent>
          <Box>
            <form id="bmrn-form">
              <Card style={{ marginTop: 10 }} variant="none">
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <Alert severity="error">
                        <AlertTitle>
                          Are you sure you want to delete this Reading
                        </AlertTitle>
                      </Alert>
                    </Grid>
                    <div className="formSection" style={{ marginTop: "40px" }}>
                      <Grid container>
                        <Grid item xs={12} sm={4}>
                          <Button
                            variant="contained"
                            className="back"
                            onClick={closeDialog}
                          >
                            Back
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Button
                            variant="contained"
                            className="purple-add"
                            type="submit"
                            onClick={(e) => deleteselrecord(e, studyId)}
                          >
                            <span>Delete</span>
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default DeleteSelReading;
