import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  FormGroup,
  InputAdornment,
  Checkbox,
  ListItemText,
} from "@mui/material";
import "../styles/DataEntry.scss";
import BabyGeneral from "./BabyGeneral";
import { ToastContainer, toast } from "react-toastify";
import {
  babyHealthParametersUpdate,
  createBabyHealthParameters,
  setBabyHealthParameters,
  getBabyHealthParametersByReadingId,
  setFormTab,
  getMotherProfileByReadingId,
  getBabyProfileByReadingId,
  getBabyBranch,
} from "../reducers/patientDataThunk";
import {
  URINE_OUTPUT,
  SKIN_COLOR,
  APENA,
  CAPILLARY_REFILL,
  CRYSOUND,
  RESP_SUPPORT,
} from "../utils/mockData";
import CircularProgress from "@mui/material/CircularProgress";
import { initialState } from "../reducers/InitialState";

const Numerics = /^[0-9 ]*$/;

const BabyHealthParameters = (props) => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const login = useSelector((state) => state.login);
  const dbbranchdata = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const hospital_id = user.userProfile.hospital_id;
  const study_id = patient.bmrndata?.study_id;
  const studyid = patient.bmrndata?.study_id;
  const reading = patient?.Selectedreadingid;
  const readingId =
    JSON.stringify(reading) != "{}" ? reading : patient?.bmrndata?.reading_id;
  const loggedUserId = user.userProfile?.user_id;
  const adddata = patient.babyHealthParameters;
  const [addFormData, setAddFormData] = useState(patient.babyHealthParameters || initialState.babyHealthParameters);
  console.log('jjjjjjjgggggg',addFormData)
  console.log('kkkkkkkkkkkkkk',patient.babyHealthParameters)
  const [updateRecord, setUpdateRecord] = useState();
  const [errorFields, setErrorFields] = useState({});
  const [
    showFrequencyOfStoolsErrorMessage,
    setShowFrequencyOfStoolsErrorMessage,
  ] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showHeartRateErrorMessage, setShowHeartRateErrorMessage] = useState();
  const [showBreathingRateErrorMessage, setShowBreathingRateErrorMessage] =
    useState();
  const [
    showOxygenSaturationErrorMessage,
    setShowOxygenSaturationErrorMessage,
  ] = useState();
  const [showBPLLErrorMessage, setShowBPLLErrorMessage] = useState();
  const [showBPULErrorMessage, setShowBPULErrorMessage] = useState();
  const [showBPMAErrorMessage, setShowBPMAErrorMessage] = useState();
  const [
    showAbsoluteNeutrophilCountErrorMessage,
    setShowAbsoluteNeutrophilCountErrorMessage,
  ] = useState();
  //const [wardData, setWardData] = useState([]);
  const [isValid, setValid] = React.useState(true);
  useEffect(() => {
    verifyErrorFields();
  }, [errorFields]);

  const verifyErrorFields = async () => {
    let enableSubmitButton = true;
    const keys = Object.keys(errorFields);
    keys.map((key) => {
      errorFields[key] !== false ? (enableSubmitButton = false) : null;
    });
    setValid(enableSubmitButton);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (patient?.bmrndata?.study_id) {
      dispatch(getBabyBranch({ studyid: patient?.bmrndata?.study_id }));
    }
  }, [patient?.bmrndata?.study_id]);

  /*  const getWardDetailsByBranch = (branch_id) => {
    const req = { hospital_id, branch_id };
    dispatch(getWardsByBranch(req))
      .unwrap()
      .then((resdata) => {
        if (resdata.response) {
          setWardData(resdata.response);
        } else {
          setWardData([]);
        }
      });
  }; */

  useEffect(() => {
    setAddFormData(patient.babyHealthParameters || initialState.babyHealthParameters);
  }, [patient.babyHealthParameters]);

  useEffect(() => {
    if (patient?.bmrndata?.reading_id && patient.bmrndata?.study_id) {
      updateData(patient?.bmrndata?.reading_id, patient.bmrndata?.study_id);
    }
  }, [patient?.bmrndata?.reading_id, patient.bmrndata?.study_id]);

  const updateData = (readingId, studyid) => {
    const req = { readingId, studyid };

    dispatch(getBabyHealthParametersByReadingId(req))
      .unwrap()
      .then((resdata) => {
        let BHPData = {
          response: Object.assign({}, resdata.response[0]),
        };

        const data =
          BHPData.response && BHPData.response ? BHPData.response : undefined;

        const data1 =
          BHPData.response && BHPData.response.baby_appearance
            ? setUpdateRecord(true)
            : setUpdateRecord(false);
        if (BHPData.response) {
          setAddFormData(
            BHPData.response && JSON.stringify(BHPData.response) != "{}"
              ? BHPData.response
              : {}
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;
    const fieldvalue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldname] = fieldvalue;

    setAddFormData(newFormData);
    setBabyHealthParameters(newFormData);
  };
  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();
    await submitData(12);
    const req = { study_id };
    dispatch(getMotherProfileByReadingId(req));
    dispatch(getBabyProfileByReadingId(req));
  };

  useEffect(() => {
    if (props.triggerChildFunction) submitData();
  }, [props.triggerChildFunction]);
  const submitData = async (nextTab) => {
    const newFormData = { ...addFormData };
    setBabyHealthParameters(newFormData);
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    newFormData.study_id = patient.bmrndata?.study_id;
    newFormData.loggedUserId = loggedUserId;
    newFormData.reading =
      JSON.stringify(readingId) != "{}"
        ? readingId
        : patient?.bmrndata?.reading_id;
    if (!updateRecord) {
      const req = {
        study_id,
        data: newFormData,
        readingId,
      };
      dispatch(createBabyHealthParameters(req)).then((resdata) => {
        const req = { readingId, studyid };
        dispatch(getBabyHealthParametersByReadingId(req)).then(() => {
          if (props.triggerChildFunction) {
            dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
          } else {
            toast.success("Data Submitted Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
      });
    } else {
      newFormData.reading = patient.Selectedreadingid;
      const req = {
        study_id,
        reading:
          JSON.stringify(readingId) != "{}"
            ? readingId
            : patient?.bmrndata?.reading_id,
        data: newFormData,
        loggedUserId,
      };
      dispatch(babyHealthParametersUpdate(req)).then((resdata) => {
        const req = { readingId, studyid };
        dispatch(getBabyHealthParametersByReadingId(req)).then(() => {
          if (props.triggerChildFunction) {
            dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
          } else {
            toast.success("Data Updated Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
      });
    }
  };

  const verifyOxygenSaturation = (event) => {
    if (event.target.value >= 0 && event.target.value <= 100) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          oxygen_saturation: false,
        })
      );
      setShowOxygenSaturationErrorMessage("");
    } else {
      setShowOxygenSaturationErrorMessage(
        "Oxygen Saturation Value Should be Between 0 To 100"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          oxygen_saturation: true,
        })
      );
    }
  };

  const verifyBreathingRate = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          breathing_rate: false,
        })
      );
      setShowBreathingRateErrorMessage("");
    } else {
      setShowBreathingRateErrorMessage(
        "Breathing Rate Value Should be Between 0 To 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          breathing_rate: true,
        })
      );
    }
  };

  const verifyHeartRate = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          heart_rate: false,
        })
      );
      setShowHeartRateErrorMessage("");
    } else {
      setShowHeartRateErrorMessage(
        "Heart Rate Value Should be Between 0 To 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          heart_rate: true,
        })
      );
    }
  };
  const verifyFrequencyOfStools = (event) => {
    if (event.target.value >= 0 && event.target.value <= 12) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          frequency_of_stools: false,
        })
      );
      setShowFrequencyOfStoolsErrorMessage("");
    } else {
      setShowFrequencyOfStoolsErrorMessage(
        "Frequency Of Stools Value Should be Between 0 to 12"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          frequency_of_stools: true,
        })
      );
    }
  };

  const verifyBabyBloodPressureUpperLimb = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_upper_limb: false,
        })
      );
      setShowBPULErrorMessage("");
    } else {
      setShowBPULErrorMessage("BP (Systolic) Value Should be Between 0 To 200");
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_upper_limb: true,
        })
      );
    }
  };

  const verifyBabyBloodPressureLowerLimb = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_lower_limb: false,
        })
      );
      setShowBPLLErrorMessage("");
    } else {
      setShowBPLLErrorMessage(
        "BP (Diastolic) Value Should be Between 0 To 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_lower_limb: true,
        })
      );
    }
  };

  const verifyBabyBloodPressureMeanArterial = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_mean_arterial_bp: false,
        })
      );

      setShowBPMAErrorMessage("");
    } else {
      setShowBPMAErrorMessage(
        "BP (Mean Arterial) Value Should be Between 0 To 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_mean_arterial_bp: true,
        })
      );
    }
  };

  const verifyAbsoluteNeutrophilCount = (event) => {
    if (event.target.value >= 0 && event.target.value <= 500000) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          absolute_neutrophil_count: false,
        })
      );
      setShowAbsoluteNeutrophilCountErrorMessage("");
    } else {
      setShowAbsoluteNeutrophilCountErrorMessage(
        "Absolute Neutrophil Count Value Should be Between 0 - 500000"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          absolute_neutrophil_count: true,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {isLoading ? (
        <div
          style={{
            height: "370px",
            paddingTop: "230px",
            textAlign: "center",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        <Card variant="none">
          <CardContent>
            <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Record Id"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Appearance
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.baby_appearance}
                    name="baby_appearance"
                    id="diagnosis"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    //required
                  >
                    <ToggleButton
                      name="baby_appearance"
                      value="Dull"
                      size="small"
                      className="dataentry_input"
                    >
                      Dull
                    </ToggleButton>
                    <ToggleButton
                      name="baby_appearance"
                      value="Lethargic"
                      size="small"
                      className="dataentry_input"
                    >
                      Lethargic
                    </ToggleButton>
                    <ToggleButton
                      name="baby_appearance"
                      value="Normal"
                      size="small"
                      className="dataentry_input"
                    >
                      Normal
                    </ToggleButton>
                    <ToggleButton
                      name="baby_appearance"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Skin Color"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Skin Color
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <Select
                    labelId="baby_skin_colour"
                    id="baby_skin_colour"
                    name="baby_skin_colour"
                    onChange={handleAddFormChange}
                    size="small"
                    className="dataentry_select_edu"
                    value={addFormData?.baby_skin_colour}
                    //required
                  >
                    {SKIN_COLOR.map((type) => (
                      <MenuItem value={type}>{type}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Cry Sound"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Cry Sound
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <Select
                    labelId="Baby Cry Sound"
                    id="baby_cry_sound"
                    name="baby_cry_sound"
                    label=""
                    onChange={handleAddFormChange}
                    size="small"
                    className="dataentry_select_edu"
                    value={addFormData?.baby_cry_sound}
                    //required
                  >
                    {CRYSOUND.map((type) => (
                      <MenuItem value={type}>{type}</MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="baby_feeding_status"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Feeding Status
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.baby_feeding_status}
                    name="baby_feeding_status"
                    id="diagnosis"
                    exclusive
                    //required
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="baby_feeding_status"
                      value="Poor"
                      size="small"
                      className="dataentry_input"
                    >
                      Poor
                    </ToggleButton>
                    <ToggleButton
                      name="baby_feeding_status"
                      value="Normal"
                      size="small"
                      className="dataentry_input"
                    >
                      Normal
                    </ToggleButton>
                    <ToggleButton
                      name="baby_feeding_status"
                      value="No Feeding"
                      size="small"
                      className="dataentry_input"
                    >
                      No Feeding
                    </ToggleButton>
                    <ToggleButton
                      name="baby_feeding_status"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="baby_jaundice"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Jaundice (Present)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.baby_jaundice}
                    name="baby_jaundice"
                    id="diagnosis"
                    exclusive
                    //required
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="baby_jaundice"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="baby_jaundice"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="baby_jaundice"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="breast_feeding_initiation"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Breast Feeding Initiation
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.breast_feeding_initiation}
                    name="breast_feeding_initiation"
                    id="diagnosis"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    //required
                  >
                    <ToggleButton
                      name="breast_feeding_initiation"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="breast_feeding_initiation"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="breast_feeding_initiation"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Groaning"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Groaning
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    name="groaning"
                    id="diagnosis"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    value={addFormData?.groaning}
                    //required
                  >
                    <ToggleButton
                      name="groaning"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="groaning"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="groaning"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Grunting"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Grunting
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>

                  <ToggleButtonGroup
                    color="primary"
                    name="grunting"
                    id="diagnosis"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    value={addFormData?.grunting}
                    //required
                  >
                    <ToggleButton
                      name="grunting"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="grunting"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="grunting"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Fast Breathing"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Fast Breathing
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>

                  <ToggleButtonGroup
                    color="primary"
                    name="fast_breathing"
                    id="fast_breathing"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    value={addFormData?.fast_breathing}
                    //required
                  >
                    <ToggleButton
                      name="fast_breathing"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="fast_breathing"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="fast_breathing"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Oxygen Saturation"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Oxygen Saturation
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <FormGroup row>
                    <TextField
                      id="OxygenSaturation"
                      label=""
                      name="oxygen_saturation"
                      variant="outlined"
                      size="small"
                      //required
                      className={
                        "dataentry_input " +
                        (errorFields.oxygen_saturation ? "errorField" : "")
                      }
                      value={addFormData?.oxygen_saturation}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        handleAddFormChange(e);
                        verifyOxygenSaturation(e);
                      }}
                      error={
                        errorFields.oxygen_saturation &&
                        showOxygenSaturationErrorMessage
                      }
                      errorText={showOxygenSaturationErrorMessage}
                      helperText={
                        errorFields.oxygen_saturation &&
                        showOxygenSaturationErrorMessage
                          ? showOxygenSaturationErrorMessage
                          : ""
                      }
                      onKeyDown={(event) => {
                        if (
                          !Numerics.test(event.key) &&
                          event.key != "Backspace"
                        ) {
                          event.preventDefault();
                          setShowOxygenSaturationErrorMessage(
                            "Only Numerics are Acceptable"
                          );
                          setErrorFields(
                            Object.assign({}, errorFields, {
                              oxygen_saturation: true,
                            })
                          );
                        }
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Breathing Rate"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Breathing Rate (Breaths Per Minute)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="BreathingRate"
                    name="breathing_rate"
                    variant="outlined"
                    size="small"
                    //required
                    className={
                      "dataentry_input " +
                      (errorFields.breathing_rate ? "errorField" : "")
                    }
                    value={addFormData?.breathing_rate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          Per Minute
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBreathingRate(e);
                    }}
                    error={
                      errorFields.breathing_rate &&
                      showBreathingRateErrorMessage
                    }
                    errorText={showBreathingRateErrorMessage}
                    helperText={
                      errorFields.breathing_rate &&
                      showBreathingRateErrorMessage
                        ? showBreathingRateErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBreathingRateErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            breathing_rate: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="HeartRate"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Heart Rate
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="heart_rate"
                    label=""
                    name="heart_rate"
                    variant="outlined"
                    size="small"
                    className="dataentry_input"
                    //required
                    value={addFormData?.heart_rate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          bpm
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyHeartRate(e);
                    }}
                    error={errorFields.heart_rate && showHeartRateErrorMessage}
                    errorText={showHeartRateErrorMessage}
                    helperText={
                      errorFields.heart_rate && showHeartRateErrorMessage
                        ? showHeartRateErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowHeartRateErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            heart_rate: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>


                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="BP (Systolic)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    BP (Systolic)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="BabyBloodPressureUpperLimb"
                    name="baby_blood_pressure_upper_limb"
                    className={
                      "dataentry_input " +
                      (errorFields.baby_blood_pressure_upper_limb
                        ? "errorField"
                        : "")
                    }
                    variant="outlined"
                    size="small"
                    //required
                    value={addFormData?.baby_blood_pressure_upper_limb}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyBloodPressureUpperLimb(e);
                    }}
                    error={
                      errorFields.baby_blood_pressure_upper_limb &&
                      showBPULErrorMessage
                    }
                    errorText={showBPULErrorMessage}
                    helperText={
                      errorFields.baby_blood_pressure_upper_limb &&
                      showBPULErrorMessage
                        ? showBPULErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBPULErrorMessage("Only Numerics are Acceptable");
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_blood_pressure_upper_limb: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mmHg
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="BP (Diastolic)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    BP (Diastolic)
                   {/*  <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    name="baby_blood_pressure_lower_limb"
                    id="BabyBloodPressureLowerLimb"
                    className={
                      "dataentry_input " +
                      (errorFields.baby_blood_pressure_lower_limb
                        ? "errorField"
                        : "")
                    }
                    variant="outlined"
                    size="small"
                    //required
                    value={addFormData?.baby_blood_pressure_lower_limb}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyBloodPressureLowerLimb(e);
                    }}
                    error={
                      errorFields.baby_blood_pressure_lower_limb &&
                      showBPLLErrorMessage
                    }
                    errorText={showBPLLErrorMessage}
                    helperText={
                      errorFields.baby_blood_pressure_lower_limb &&
                      showBPLLErrorMessage
                        ? showBPLLErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBPLLErrorMessage("Only Numerics are Acceptable");
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_blood_pressure_lower_limb: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mmHg
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="BP (Mean Arterial)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    BP (Mean Arterial)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    name="baby_blood_pressure_mean_arterial_bp"
                    id="BabyBloodPressureMeanArterial"
                    className={
                      "dataentry_input " +
                      (errorFields.baby_blood_pressure_mean_arterial_bp
                        ? "errorField"
                        : "")
                    }
                    variant="outlined"
                    size="small"
                    //required
                    value={addFormData?.baby_blood_pressure_mean_arterial_bp}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyBloodPressureMeanArterial(e);
                    }}
                    error={
                      errorFields.baby_blood_pressure_mean_arterial_bp &&
                      showBPMAErrorMessage
                    }
                    errorText={showBPMAErrorMessage}
                    helperText={
                      errorFields.baby_blood_pressure_mean_arterial_bp &&
                      showBPMAErrorMessage
                        ? showBPMAErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setshowBPMAErrorMessage("Only Numerics are Acceptable");
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_blood_pressure_mean_arterial_bp: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mmHg
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="CapillaryRefill"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Capillary Refill
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <Select
                    labelId="capillary refill"
                    id="capillary_refill_unit"
                    name="capillary_refill_unit"
                    label=""
                    onChange={handleAddFormChange}
                    size="small"
                    className="dataentry_select_edu"
                    value={addFormData?.capillary_refill_unit}
                    //required
                  >
                    {CAPILLARY_REFILL.map((type) => (
                      <MenuItem value={type}>{type}</MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Seizures"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Seizures
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.seizures}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    //required
                  >
                    <ToggleButton
                      name="seizures"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="seizures"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="seizures"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="AFBulge"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    AF Bulge
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.af_bulge}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    //required
                  >
                    <ToggleButton
                      name="af_bulge"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="af_bulge"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="af_bulge"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="BabyMovementWithStimulation"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Baby Movement with Stimulation
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.baby_movement}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    //required
                  >
                    <ToggleButton
                      name="baby_movement"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="baby_movement"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="baby_movement"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="AbdominalDistension"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Abdominal Distension
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.abdominal_dystension}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    //required
                  >
                    <ToggleButton
                      name="abdominal_dystension"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="abdominal_dystension"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="abdominal_dystension"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Frequency of Stools)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Frequency of Stools
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    name="frequency_of_stools"
                    id="FrequencyOfStools"
                    className={
                      "dataentry_input " +
                      (errorFields.frequency_of_stools ? "errorField" : "")
                    }
                    variant="outlined"
                    size="small"
                    //required
                    //disabled={!defaultCheck.FrequencyOfStools}
                    value={addFormData?.frequency_of_stools}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyFrequencyOfStools(e);
                    }}
                    error={
                      errorFields.frequency_of_stools &&
                      showFrequencyOfStoolsErrorMessage
                    }
                    errorText={showFrequencyOfStoolsErrorMessage}
                    helperText={
                      errorFields.frequency_of_stools &&
                      showFrequencyOfStoolsErrorMessage
                        ? showFrequencyOfStoolsErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowFrequencyOfStoolsErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            frequency_of_stools: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>


                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Absolute Neutrophil Count"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Absolute Neutrophil Count
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="AbsoluteNeutrophilCount"
                    name="absolute_neutrophil_count"
                    label=""
                    //required
                    variant="outlined"
                    value={addFormData?.absolute_neutrophil_count}
                    className={
                      "dataentry_input " +
                      (errorFields.absolute_neutrophil_count
                        ? "errorField"
                        : "")
                    }
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          cells/µL
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyAbsoluteNeutrophilCount(e);
                    }}
                    error={
                      errorFields.absolute_neutrophil_count &&
                      showAbsoluteNeutrophilCountErrorMessage
                    }
                    errorText={showAbsoluteNeutrophilCountErrorMessage}
                    helperText={
                      errorFields.absolute_neutrophil_count &&
                      showAbsoluteNeutrophilCountErrorMessage
                        ? showAbsoluteNeutrophilCountErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowAbsoluteNeutrophilCountErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            absolute_neutrophil_count: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>

              </Grid>

              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "20px",
                }}
              >
                {isValid ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    aria-label="add"
                    className="buttonStyle sign-up-button"
                    type="submit"
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="gray"
                    aria-label="add"
                    className="buttonStyle1 sign-up-button"
                    disabled
                  >
                    Save
                  </Button>
                )}
              </div>
            </form>
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  );
};

export default BabyHealthParameters;
