import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Card,
  CardContent,
  Button,
  InputAdornment,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import "../styles/DataEntry.scss";
import { SKIN_COLOR, CRYSOUND } from "../utils/mockData";
import { ToastContainer, toast } from "react-toastify";
import {
  setFormTab,
  getMotherProfileByReadingId,
  getBabyProfileByReadingId,
  getBabyQuickSepsis,
  createBabyQuickSepsis,
  updateBabyQuickSepsis,
  getBabyBranch,
} from "../reducers/patientDataThunk";

import CircularProgress from "@mui/material/CircularProgress";
const Numerics = /^[0-9 ]*$/;
const BabyQuickSepsis = (props) => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const login = useSelector((state) => state.login);
  const dbbranchdata = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const hospital_id = user.userProfile.hospital_id;

  const study_id = patient.bmrndata?.study_id;
  const studyid = patient.bmrndata?.study_id;
  const reading = patient?.Selectedreadingid;

  const readingId =
    JSON.stringify(reading) != "{}" ? reading : patient?.bmrndata?.reading_id;
  const loggedUserId = user.userProfile?.user_id;
  const adddata = patient.babyHealthParameters;
  const [addFormData, setAddFormData] = useState(adddata || {});
  const [updateRecord, setUpdateRecord] = useState();
  const [errorFields, setErrorFields] = useState({});
  const [
    showFrequencyOfStoolsErrorMessage,
    setShowFrequencyOfStoolsErrorMessage,
  ] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [
    showOxygenSaturationErrorMessage,
    setShowOxygenSaturationErrorMessage,
  ] = useState();
  const [showHeartRateErrorMessage, setShowHeartRateErrorMessage] = useState();
  const [showBreathingRateErrorMessage, setShowBreathingRateErrorMessage] =
    useState();
  const [isValid, setValid] = React.useState(true);
  useEffect(() => {
    verifyErrorFields();
  }, [errorFields]);

  const verifyErrorFields = async () => {
    let enableSubmitButton = true;
    const keys = Object.keys(errorFields);
    keys.map((key) => {
      errorFields[key] !== false ? (enableSubmitButton = false) : null;
    });
    setValid(enableSubmitButton);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (patient?.bmrndata?.study_id) {
      dispatch(getBabyBranch({ studyid: patient?.bmrndata?.study_id }));
    }
  }, [patient?.bmrndata?.study_id]);

  useEffect(() => {
    if (patient?.bmrndata?.reading_id && patient.bmrndata?.study_id) {
      updateData(patient?.bmrndata?.reading_id, patient.bmrndata?.study_id);
    }
  }, [patient?.bmrndata?.reading_id, patient.bmrndata?.study_id]);

  const updateData = (readingId, studyid) => {
    setIsLoading(true);
    const req = { readingId, studyid };
    dispatch(getBabyQuickSepsis(req))
      .unwrap()
      .then((resdata) => {
        let QSCData = {
          response: Object.assign({}, resdata.data.response[0]),
        };
        const data =
          QSCData.response && QSCData.response ? QSCData.response : undefined;
        const data1 =
          QSCData.response &&
          QSCData.response &&
          Object.keys(QSCData.response).length > 0
            ? setUpdateRecord(true)
            : setUpdateRecord(false);
        if (QSCData.response) {
          setAddFormData(
            QSCData.response && JSON.stringify(QSCData.response) != "{}"
              ? QSCData.response
              : {}
          );

          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;
    const fieldvalue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldname] = fieldvalue;

    setAddFormData(newFormData);
  };
  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();
    await submitData(12);
    const req = { study_id };
    dispatch(getMotherProfileByReadingId(req));
    dispatch(getBabyProfileByReadingId(req));
  };

  useEffect(() => {
    if (props.triggerChildFunction) submitData();
  }, [props.triggerChildFunction]);
  const submitData = async (nextTab) => {
    const newFormData = { ...addFormData };
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    newFormData.study_id = patient.bmrndata?.study_id;
    newFormData.loggedUserId = loggedUserId;
    newFormData.reading =
      JSON.stringify(readingId) != "{}"
        ? readingId
        : patient?.bmrndata?.reading_id;
    if (!updateRecord) {
      const req = {
        study_id,
        data: newFormData,
        readingId,
      };
      dispatch(createBabyQuickSepsis(req))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getBabyQuickSepsis(req)).then(() => {
            if(props.triggerChildFunction){
              dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
              }
              else{
                toast.success("Data Updated Successfully", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
          });
        });
    } else {
      newFormData.reading = patient.Selectedreadingid;
      const req = {
        study_id,
        reading:
          JSON.stringify(readingId) != "{}"
            ? readingId
            : patient?.bmrndata?.reading_id,
        data: newFormData,
        loggedUserId,
      };
      dispatch(updateBabyQuickSepsis(req)).then((resdata) => {
        const req = { readingId, studyid };
        dispatch(getBabyQuickSepsis(req)).then(() => {if(props.triggerChildFunction){
          dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
          }
          else{
            toast.success("Data Updated Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
      });
    }
  };

  const verifyHeartRate = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          heart_rate: false,
        })
      );
      setShowHeartRateErrorMessage("");
    } else {
      setShowHeartRateErrorMessage(
        "Heart Rate Value Should be Between 0 To 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          heart_rate: true,
        })
      );
    }
  };

  const verifyBreathingRate = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          breathing_rate: false,
        })
      );
      setShowBreathingRateErrorMessage("");
    } else {
      setShowBreathingRateErrorMessage(
        "Breathing Rate Value Should be Between 0 To 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          breathing_rate: true,
        })
      );
    }
  };

  const verifyOxygenSaturation = (event) => {
    if (event.target.value >= 0 && event.target.value <= 100) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          oxygen_saturation: false,
        })
      );
      setShowOxygenSaturationErrorMessage("");
    } else {
      setShowOxygenSaturationErrorMessage(
        "Oxygen Saturation Value Should be Between 0 To 100"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          oxygen_saturation: true,
        })
      );
    }
  };

  /*
  const verifyTotalLeucocytesCount = (event) => {
    if (event.target.value > 1000 && event.target.value <= 500000) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          total_leucocute_count: false,
        })
      );
      setShowTotalLeucocuteCountErrorMessage("");
    } else {
      setShowTotalLeucocuteCountErrorMessage(
        "Total Leucocytes Count Value Should be Between 1000 - 500000."
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          total_leucocute_count: true,
        })
      );
    }
  };
  const verifyBabyBloodPressureUpperLimb = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_upper_limb: false,
        })
      );
      setShowBPULErrorMessage("");
    } else {
      setShowBPULErrorMessage("BP (Systolic) Value Should be Between 0 To 200");
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_upper_limb: true,
        })
      );
    }
  };

  const verifyBabyBloodPressureLowerLimb = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_lower_limb: false,
        })
      );
      setShowBPLLErrorMessage("");
    } else {
      setShowBPLLErrorMessage(
        "BP (Diastolic) Value Should be Between 0 To 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_lower_limb: true,
        })
      );
    }
  };

  const verifyBabyBloodPressureMeanArterial = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_mean_arterial_bp: false,
        })
      );
      setShowBPMAErrorMessage("");
    } else {
      setShowBPMAErrorMessage(
        "BP (Mean Arterial) Value Should be Between 0 To 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_mean_arterial_bp: true,
        })
      );
    }
  };
  const verifyAbsoluteNeutrophilCount = (event) => {
    if (event.target.value >= 500 && event.target.value <= 500000) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          absolute_neutrophil_count: false,
        })
      );
      setShowAbsoluteNeutrophilCountErrorMessage("");
    } else {
      setShowAbsoluteNeutrophilCountErrorMessage(
        "Absolute Neutrophil Count Value Should be Between 500 - 500000"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          absolute_neutrophil_count: true,
        })
      );
    }
  };
  */
  const verifyFrequencyOfStools = (event) => {
    if (event.target.value >= 0 && event.target.value <= 12) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          frequency_of_stools: false,
        })
      );
      setShowFrequencyOfStoolsErrorMessage("");
    } else {
      setShowFrequencyOfStoolsErrorMessage(
        "Frequency Of Stools Value Should be Between 0 to 12"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          frequency_of_stools: true,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {isLoading ? (
        <div
          style={{
            height: "370px",
            paddingTop: "230px",
            textAlign: "center",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        <Card variant="none">
          <CardContent>
            <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Skin Color"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Skin Color
                  </label>
                  <Select
                    labelId="baby_skin_colour"
                    id="baby_skin_colour"
                    name="baby_skin_colour"
                    onChange={handleAddFormChange}
                    size="small"
                    className="dataentry_select_edu"
                    value={addFormData?.baby_skin_colour}
                  >
                    {SKIN_COLOR.map((type) => (
                      <MenuItem value={type}>{type}</MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Cry Sound"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Cry Sound
                  </label>
                  <Select
                    labelId="Baby Cry Sound"
                    id="baby_cry_sound"
                    name="baby_cry_sound"
                    label=""
                    onChange={handleAddFormChange}
                    size="small"
                    className="dataentry_select_edu"
                    value={addFormData?.baby_cry_sound}
                  >
                    {CRYSOUND.map((type) => (
                      <MenuItem value={type}>{type}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="baby_feeding_status"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Feeding Status
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.baby_feeding_status}
                    name="baby_feeding_status"
                    id="diagnosis"
                    exclusive
                    //required
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="baby_feeding_status"
                      value="Poor"
                      size="small"
                      className="dataentry_input"
                    >
                      Poor
                    </ToggleButton>
                    <ToggleButton
                      name="baby_feeding_status"
                      value="Normal"
                      size="small"
                      className="dataentry_input"
                    >
                      Normal
                    </ToggleButton>
                    <ToggleButton
                      name="baby_feeding_status"
                      value="No Feeding"
                      size="small"
                      className="dataentry_input"
                    >
                      No Feeding
                    </ToggleButton>
                    <ToggleButton
                      name="baby_feeding_status"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Oxygen Saturation"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Oxygen Saturation
                  </label>
                  <TextField
                    id="OxygenSaturation"
                    label=""
                    name="oxygen_saturation"
                    variant="outlined"
                    size="small"
                    className={
                      "dataentry_input " +
                      (errorFields.oxygen_saturation ? "errorField" : "")
                    }
                    value={addFormData?.oxygen_saturation}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyOxygenSaturation(e);
                    }}
                    error={
                      errorFields.oxygen_saturation &&
                      showOxygenSaturationErrorMessage
                    }
                    errorText={showOxygenSaturationErrorMessage}
                    helperText={
                      errorFields.oxygen_saturation &&
                      showOxygenSaturationErrorMessage
                        ? showOxygenSaturationErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowOxygenSaturationErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            oxygen_saturation: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="HeartRate"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Heart Rate
                  </label>
                  <TextField
                    id="heart_rate"
                    label=""
                    name="heart_rate"
                    variant="outlined"
                    size="small"
                    className="dataentry_input"
                    //required
                    value={addFormData?.heart_rate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          bpm
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyHeartRate(e);
                    }}
                    error={errorFields.heart_rate && showHeartRateErrorMessage}
                    errorText={showHeartRateErrorMessage}
                    helperText={
                      errorFields.heart_rate && showHeartRateErrorMessage
                        ? showHeartRateErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowHeartRateErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            heart_rate: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Breathing Rate"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Breathing Rate (Breaths Per Minute)
                  </label>
                  <TextField
                    id="BreathingRate"
                    name="breathing_rate"
                    variant="outlined"
                    size="small"
                    //required
                    className={
                      "dataentry_input " +
                      (errorFields.breathing_rate ? "errorField" : "")
                    }
                    value={addFormData?.breathing_rate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          Per Minute
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBreathingRate(e);
                    }}
                    error={
                      errorFields.breathing_rate &&
                      showBreathingRateErrorMessage
                    }
                    errorText={showBreathingRateErrorMessage}
                    helperText={
                      errorFields.breathing_rate &&
                      showBreathingRateErrorMessage
                        ? showBreathingRateErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBreathingRateErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            breathing_rate: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Frequency of Stools)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Frequency of Stools
                  </label>
                  <TextField
                    name="frequency_of_stools"
                    id="FrequencyOfStools"
                    className={
                      "dataentry_input " +
                      (errorFields.frequency_of_stools ? "errorField" : "")
                    }
                    variant="outlined"
                    size="small"
                    //disabled={!defaultCheck.FrequencyOfStools}
                    value={addFormData?.frequency_of_stools}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyFrequencyOfStools(e);
                    }}
                    error={
                      errorFields.frequency_of_stools &&
                      showFrequencyOfStoolsErrorMessage
                    }
                    errorText={showFrequencyOfStoolsErrorMessage}
                    helperText={
                      errorFields.frequency_of_stools &&
                      showFrequencyOfStoolsErrorMessage
                        ? showFrequencyOfStoolsErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowFrequencyOfStoolsErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            frequency_of_stools: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "20px",
                }}
              >
                {isValid ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    aria-label="add"
                    className="buttonStyle sign-up-button"
                    type="submit"
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="gray"
                    aria-label="add"
                    className="buttonStyle1 sign-up-button"
                    disabled
                  >
                    Save
                  </Button>
                )}
              </div>
            </form>
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  );
};

export default BabyQuickSepsis;
