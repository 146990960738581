import React from "react";
import { Grid } from "@mui/material";
const ChartLegends = (props) => {
  const data = React.useMemo(() => {
    const firstCount = props.labelOneCount
      ? (
          (props.labelOneCount * 100) /
          (props.labelTwoCount + props.labelOneCount)
        ).toFixed(2)
      : null;
    const secondCount = props.labelTwoCount
      ? (
          (props.labelTwoCount / (props.labelTwoCount + props.labelOneCount)) *
          100
        ).toFixed(2)
      : null;
    return [firstCount, secondCount];
  }, [props.labelOneCount, props.labelTwoCount]);

  return (
    <>
      <Grid container>
        <Grid
          sm={12}
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            fontSize: "13px",
          }}
        >
          <div
            className="ChartsLegends"
            style={{ backgroundColor: `${props.colorOne}` }}
          ></div>
          {props.labelOne}&nbsp;
          {props.labelOneCount ? `(${data[0].slice(0, 3)}%)` : null}
        </Grid>
        <Grid
          sm={12}
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            fontSize: "13px",
          }}
        >
          <div
            className="ChartsLegends"
            style={{ backgroundColor: `${props.colorTwo}` }}
          ></div>
          {props.labelTwo}&nbsp;
          {props.labelTwoCount ? `(${data[1].slice(0, 3)}%)` : null}
        </Grid>
      </Grid>
    </>
  );
};

export default ChartLegends;
