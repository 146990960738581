export const initialState = {
    formTab: "null",
    isacccreate: false,
    isaddinfo:false,
    accountcreation:{
        user_type:"",
        email_phone:"",
        password:"",
    },
    subscriptionPopup:{},
    showSubScription: false,
    showTermsConditions: false,
    userTypeList:[],
    addiinformation:{
        Profession:"",
        typeOfInst:"Government",
        Institution_name:"",
        state:"",
        coupon_code:"",

    }
}