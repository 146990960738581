import { createSlice } from "@reduxjs/toolkit";
import * as UserThunk from "./userThunk";

const initialState = {
  userProfile: {},
  branchdata: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [UserThunk.login.fulfilled]: (state, action) => {
      state.userProfile = action.payload;
      // console.log("User logged in");
    },
    [UserThunk.logout.fulfilled]: (state, action) => {
      state.userProfile = {};
      // console.log("User logged in");
    },

    [UserThunk.logindetails.fulfilled]: (state, action) => {
      state.userProfile = action.payload.response;
      // console.log("User logged in");
    },

    [UserThunk.loginDetailsByAdmin.fulfilled]: (state, action) => {
      state.userProfile = action.payload.response;
      // console.log("User logged in");
    },

    [UserThunk.getbranchdetailsbyuserid.fulfilled]: (state, action) => {
      state.branchdata = action.payload.response;
      // console.log("User logged in");
    },

    [UserThunk.updateGlobalSearch.fulfilled]: (state, action) => {
      state.globalSearchString = action.payload;
    },

    [UserThunk.getSubscriptionStatus.fulfilled]: (state, action) => {
      state.subScriptionStatus = action.payload.response;
      // console.log("User logged in");
    },
  },
});

export default userSlice.reducer;
