import React from "react";
import "./App.scss";
import { ThemeProvider } from "@mui/material/styles";
import { useRoutes } from "react-router-dom";
import { appRoutes } from "./routes/routes";
import "react-toastify/dist/ReactToastify.css";
import theme from "./theme";
function App() {
  let element = useRoutes(appRoutes);
  //return <div className="App">{element}</div>;
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
}
export default App;
