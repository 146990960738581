import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  Autocomplete,
  TextField,
  Alert,
  Snackbar,
} from "@mui/material";
import ReerralManagement from "./ReferralManagement";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function ReferralHeader() {
  return (
    <React.Fragment>
      <ReerralManagement />
    </React.Fragment>
  );
}
