import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Grid } from "@mui/material";
import moment from "moment";

import "../../dataEntry/styles/DataEntry.scss";
import SubscPopUp from "../../signUp/components/SubscriptionPopup";
import ActivePlan from "./ActivePlan";

import { getSubscriptions } from "../reducers/profileDataThunk";

const AdminAccess = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [activePlan, setActivePlan] = React.useState([]);

  useEffect(() => {
    dispatch(getSubscriptions())
      .unwrap()
      .then((resdata) => {
        setActivePlan(resdata.response.activeSubscriptions);
      });
  }, []);

  return (
    <React.Fragment>
      <Card variant="none">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <p className="identity_verify">Active Plan</p>
              <hr />
              <Card variant="none">
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <p className="subActivePlan">
                        <span className="subActivePlanSpan">Plan Name </span>
                        :Standard Plan
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <p className="subActivePlan">
                        <span className="subActivePlanSpan">Amount </span>: Rs{" "}
                        {activePlan[0]?.price} /-
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <p className="subActivePlan">
                        <span className="subActivePlanSpan">Created Date </span>
                        :{moment(activePlan[0]?.createdAt).format("DD-MM-YYYY")}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <p className="subActivePlan">
                        <span className="subActivePlanSpan">Expiry Date </span>:
                        {moment(activePlan[0]?.expiry_date).format(
                          "DD-MM-YYYY"
                        )}
                      </p>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <hr />
            </Grid>

            <Grid
              container
              spacing={2}
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                alignItems: "center",
              }}
            >
              <ActivePlan />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default AdminAccess;
