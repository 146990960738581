import { createAsyncThunk } from "@reduxjs/toolkit";
import PatientDataService from "../../api/apiCommonService";

export const getBranchDetail = createAsyncThunk(
  "hospitalBranchFetch",
  async (req) => {
    const res = await PatientDataService.getBranchDetail(req);
    return res.data;
  }
);

export const addbranchdetails = createAsyncThunk(
  "addbranchdetails",
  async (req) => {
    const res = await PatientDataService.addbranchdetails(req);
    return res.data;
  }
);

export const setAddBranchFormData = createAsyncThunk(
  "branchdata",
  async (data) => data
);

export const setErrorMessage = createAsyncThunk(
  "errorMessage",
  async (data) => data
);

export const setsuccessMessage = createAsyncThunk(
  "successMessage",
  async (data) => data
);

export const setBranchEdit = createAsyncThunk("isBranch", async (data) => data);
export const deleteBranch = createAsyncThunk(
  "deleteBranch",
  async (branch_id) => {
    const res = await PatientDataService.deleteBranch(branch_id);
    return res.data;
  }
);
