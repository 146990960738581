import React, { useState } from "react";
import {
  Box,Button,Card,CardContent,Grid,
} from "@mui/material";


import { useDispatch } from "react-redux";
import "../../login/styles/login.scss";
import "../../dataEntry/styles/DataEntry.scss";

import SignUp from "./signup";
const steps = ["Set Up", "Additional Information", "Verification"];

const TermsAndConditions = (props) => {
  const [defaultCheck, setDefaultCheck] = useState({
    acceprterms: false,
  });

  return (
    <React.Fragment>
      <div className="signUpBg">
        <Box sx={{ minWidth: 275 }} className="signup-container">
          <Card variant="outlined">
            <CardContent>
              <p className="subsc_plans">Terms and Conditions</p>
              <hr />
              <div className="terms_box">
               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Lorem ipsum dolor sit consectetur adipiscing
                elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco. Lorem ipsum dolor sit consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco .
                </p>
              </div>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <label
                    htmlFor="POBpin"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    <input
                      name="acceprterms"
                      type="checkbox"
                      checked={defaultCheck.acceprterms}
                      onChange={() => {
                        setDefaultCheck({ acceprterms: true });
                      }}
                    />I have read and accept the terms and conditions
                  </label>
                </Grid>
              </Grid>
              <div className="formSection">
                <Grid container>
                  <Grid item xs={12} sm={2}></Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      variant="contained"
                      className="back"
                      onClick={props.onClose}
                    >
                      Back
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Button
                      variant="contained"
                      className="purple-add"
                      type="submit"
                      onclick="termssubmit"
                    >
                      <span>Continue</span>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </CardContent>
          </Card>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default TermsAndConditions;
