export const WARD_FORM_MOCK = {
    ward_name:"",
};
export const MAP_BRANCH_WARD_FORM_MOCK = {
    ward_id:"",
}
export const initialState = {
    wardlist:[],
    branchList:[],
    branchMaping:[],
    warddata:{...WARD_FORM_MOCK},
    mapData:{...MAP_BRANCH_WARD_FORM_MOCK},
}