import { createAsyncThunk } from "@reduxjs/toolkit";
import PatientDataService from "../../api/apiCommonService";

export const getWardDetail = createAsyncThunk(
  "wardList",
  async (hospital_id) => {
    const res = await PatientDataService.getWardDetail(hospital_id);
    return res.data;
  }
);

export const getHospitalRoles = createAsyncThunk(
  "getHospitalRoles",
  async (hospital_id) => {
    const res = await PatientDataService.getHospitalRoles(hospital_id);
    return res.data;
  }
);

export const addReferralDoctorToTable = createAsyncThunk(
  "addReferralDoctorToTable",
  async (req) => {
    const res = await PatientDataService.addReferralDoctorToTable(req);
    return res.data;
  }
);

export const updateReferalDoctorDetails = createAsyncThunk(
  "updateReferalDoctorDetails",
  async (req) => {
    const res = await PatientDataService.updateReferalDoctorDetails(req);
    return res.data;
  }
);
export const addWardDetails = createAsyncThunk(
  "addWardDetails",
  async (req) => {
    const res = await PatientDataService.addWardDetails(req);
    return res.data;
  }
);
export const updateWardDetails = createAsyncThunk(
  "updateWardDetails",
  async (req) => {
    const res = await PatientDataService.updateWardDetails(req);
    return res.data;
  }
);

export const updateRoleDetails = createAsyncThunk(
  "updateWardDetails",
  async (req) => {
    const res = await PatientDataService.updateRoleDetails(req);
    return res.data;
  }
);

export const setAddWardFormData = createAsyncThunk(
  "warddata",
  async (data) => data
);

export const setReferalDoctorFormData = createAsyncThunk(
  "ReferalDoctordata",
  async (data) => data
);

export const getBranchDetail = createAsyncThunk(
  "hospitalBranchFetch",
  async () => {
    const res = await PatientDataService.getBranchDetail();
    return res.data;
  }
);

export const getmapedBranchDetail = createAsyncThunk(
  "hospitalMappedBranchFetch",
  async (req) => {
    const res = await PatientDataService.getmapedBranchDetail(req);
    return res.data;
  }
);

export const getMappedWardsOfBranch = createAsyncThunk(
  "getMappedWardsOfBranch",
  async (req) => {
    const res = await PatientDataService.getMappedWardsOfBranch(req);
    return res.data;
  }
);

export const activateBranch = createAsyncThunk(
  "activateBranch",
  async (referralDrId) => {
    const res = await PatientDataService.activateBranch(referralDrId);
    return res.data;
  }
);

export const activateWard = createAsyncThunk(
  "activateWard",
  async (referralDrId) => {
    const res = await PatientDataService.activateWard(referralDrId);
    return res.data;
  }
);
export const activateReferalDr = createAsyncThunk(
  "activateReferalDr",
  async (referralDrId) => {
    const res = await PatientDataService.activateReferalDr(referralDrId);
    return res.data;
  }
);

export const getRefDoctorsFromTable = createAsyncThunk(
  "getRefDoctorsFromTable",
  async (hospital_id) => {
    const res = await PatientDataService.getRefDoctorsFromTable(hospital_id);
    return res.data;
  }
);

export const addMapWithBranch = createAsyncThunk(
  "mapBranchWithWard",
  async (req) => {
    const res = await PatientDataService.addMapWithBranch(req);
    return res.data;
  }
);

export const setErrorMessage = createAsyncThunk(
  "errorMessage",
  async (data) => data
);

export const setsuccessMessage = createAsyncThunk(
  "successMessage",
  async (data) => data
);
export const deleteReferralDoctor = createAsyncThunk(
  "deleteReferralDoctor",
  async (referralId) => {
    const res = await PatientDataService.deleteReferralDoctor(referralId);
    return res.data;
  }
);
export const deleteAdmin = createAsyncThunk(
  "deleteAdmin",
  async (referralDrId) => {
    const res = await PatientDataService.deleteAdmin(referralDrId);
    return res.data;
  }
);

export const activateAdmin = createAsyncThunk(
  "activateAdmin",
  async (referralDrId) => {
    const res = await PatientDataService.activateAdmin(referralDrId);
    return res.data;
  }
);

export const resendVerification = createAsyncThunk(
  "resendVerification",
  async (req) => {
    const res = await PatientDataService.resendVerification(req);
    return res.data;
  }
);

export const deleteWard = createAsyncThunk("deleteward", async (ward_id) => {
  const res = await PatientDataService.deleteWard(ward_id);
  return res.data;
});
