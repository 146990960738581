import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Select,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
//import userSlice from "../reducers/userSlice";
import "../styles/login.scss";
import "../../dataEntry/styles/DataEntry.scss";
import logo from "../assets/images/avyantra_logo.png";
import prelogo from "../assets/images/presco_logo.png";

const Branch = (props) => {
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className="avy_logo">
                <img src={logo} className="avy-img" />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="login_contact">
                <Button className="login_contact_content">Contact us</Button>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <img src={prelogo} className="pre_img" />
              <div className="pre_caption">
                Neonatal Sepsis Prediction Platform
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="loginBackground">
        <Box sx={{ minWidth: 275 }} className="login-container">
          <Card variant="outlined">
            <CardContent>
              <p className="login_welcome">WELCOME</p>
              <p className="login_cont">Select a branch to continue</p>
              <form id="mother-medical-form">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label htmlFor="Branch" className="login_label">
                      Branch
                    </label>
                    <Select
                      labelId="Branch"
                      id="branch"
                      name="branch"
                      label=""
                      size="small"
                      className="dataentry_select_edu"
                    ></Select>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Box>

        <Grid className="copy-posi">
          <p className="Copyright">
            Copyright © 2019-2024 Avyantra Health Technologies. All Rights
            Reserved
          </p>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default Branch;
