import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  IconButton,
  Alert,
  Snackbar,
  Typography,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import LanguageIcon from "@mui/icons-material/Language";
import { Stepper, Step, StepLabel, StepContent } from "@mui/material";
import React, { useEffect } from "react";
import "../../login/styles/login.scss";
import "../../dataEntry/styles/DataEntry.scss";
import logo from "../../common/assets/images/avyantra_logo.png";
import prelogo from "../../common/assets/images/presco_logo.png";

import AccCreation from "./accountCreation";
import IdentityVerification from "./identityVerification";
import SubscPopUp from "./SubscriptionPopup";
import { styled } from "@mui/system";

const StyledStepLabel = styled(StepLabel)({
  "& .MuiStepLabel-iconContainer": {
    display: "none",
  },
});

const steps = [
  {
    label: "Set Up",
    key: 1,
  },
  {
    label: "Verification",
    key: 2,
  },
  {
    label: "Payment",
    key: 3,
  },
];

const SignUp = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <React.Fragment
      className="signup-container"
      style={{ background: "#ffffff", height: "100%" }}
    >
      <Grid container style={{ background: "#ffffff", height: "100%" }}>
        <Grid item xs={12} sm={12} sx={6}>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ maxWidth: "50%" }}>
              <div className="avy_logo">
                <a
                  href="https://avyantra.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={logo} className="avy-img" />
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ maxWidth: "50%" }}>
              <div className="login_contact">
                <a
                  href="https://avyantra.com/#contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contactUs"
                >
                  <Button className="login_contact_content1">Contact us</Button>
                </a>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <div className="signUpBg1" style={{ height: "10%", top: "94%" }}></div>
        <Grid container style={{ margin: "10px 10px" }}>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{ maxWidth: "95%" }}
              className="signup-container"
              style={{
                // height: "100%",
                marginTop: "7%",
                maxWidth: "100%",
                marginLeft: "2%",
              }}
            >
              <Card variant="outlined">
                <CardContent>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <img src={prelogo} className="pre_img1" />
                      <div className="pre_caption" style={{ fontSize: 12 }}>
                        Neonatal Sepsis Prediction Platform
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{
                      margin: "auto",
                      justifyContent: "center",
                      display: "grid",
                      margin: "1.5rem 0 3rem 0",
                    }}
                  >
                    <Stepper activeStep={activeStep} orientation="vertical">
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StyledStepLabel
                            style={{
                              padding: "5px 10px",
                              background: "#F0F1FC",
                              border: "1px solid rgba(137, 137, 137, 1)",
                              borderRadius: "30px",
                              textAlign: "center",
                              width: "12rem",
                            }}
                          >
                            {step.key}.&nbsp;{step.label}
                          </StyledStepLabel>
                          {/* <StepLabel
                            style={{
                              padding: "5px 10px",
                              background: "#F0F1FC",
                              border: "1px solid #6572E4",
                              borderRadius: "30px",
                            }}                         
                          >
                            {step.key}.{step.label}
                          </StepLabel> */}
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                  <hr
                    style={{
                      color: "rgba(135, 135, 135, 1)",
                    }}
                  />
                  <Grid
                    style={{
                      textAlign: "Center",
                      margin: "1.5rem 0 2rem 0",
                      color: "rgba(135, 135, 135, 1)",
                    }}
                  >
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{
                        fontSize: "11px",
                        fontWeight: 600,
                      }}
                    >
                      Need Support ?
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      Please feel free to contact us, we’ll be happy to help
                      out.
                    </Typography>
                    <div
                      style={{
                        margin: "0.4rem 0",
                      }}
                    >
                      <MailIcon />
                      &nbsp;&nbsp;&nbsp;
                      <LanguageIcon />
                    </div>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      style={{
                        fontSize: "9px",
                      }}
                    >
                      Copyright © 2019-2024 Avyantra Health Technologies. All
                      Rights Reserved
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Card style={{ marginLeft: "5%", boxShadow: "none" }}>
              <CardContent>
                {activeStep == 0 ? <AccCreation nextStep={handleNext} /> : null}
                {activeStep == 1 ? (
                  <IdentityVerification nextStep={handleNext} />
                ) : null}
                {activeStep == 2 ? <SubscPopUp /> : null}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SignUp;
