import { createTheme, ThemeProvider } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#6572e4",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#f48351",
      contrastText: "#ffffff",
    },
    gray: {
      main: "#f48351",
      contrastText: "#ffffff",
    },
    //contrastThreshold: 3,
    //tonalOffset: 0.2,
  },

  typography: {
    fontFamily: ["Poppins"],
  },
});

export default theme;
