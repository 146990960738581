import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Checkbox,
  ListItemText,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  InputAdornment,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
const Numerics = /^[0-9 ]*$/;
import "../styles/DataEntry.scss";
import { ANTIBIOTIC_NAME } from "../utils/mockData";
import {
  antibioticAddition,
  updatePatientFormData,
  setBabyAntibioticData,
  getBabyAntibioticDataByReadingId,
  babyPredictionScore,
  setFormTab,
} from "../reducers/patientDataThunk";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CircularProgress from "@mui/material/CircularProgress";

const BabyAntibiotic = (props) => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const study_id = patient.bmrndata?.study_id;
  const studyid = patient.bmrndata?.study_id;
  const reading = patient?.Selectedreadingid;
  const readingId =
    JSON.stringify(reading) != "{}" ? reading : patient?.bmrndata?.reading_id;
  const loggedUserId = user.userProfile?.user_id;
  const baby_date_of_admission = patient.getBabyProfile?.baby_date_of_admission;
  const [BloodSampleSentDate, setBloodSampleSentDate] = React.useState(
    moment().format()
  );
  const [DateOfAdministration, setDateOfAdministration] = React.useState(
    moment().format()
  );
  const [addBabyAntibioticData, setAddBabyAntibioticData] = useState({
    antibiotic_name: [],
  });
  const [errorFields, setErrorFields] = useState({});
  const [updateRecord, setUpdateRecord] = useState(false);
  const [
    showTimeOfAdministrationHoursErrorMessage,
    setShowTimeOfAdministrationHoursErrorMessage,
  ] = useState();
  const [
    showTimeOfAdministrationMinsErrorMessage,
    setShowTimeOfAdministrationMinsErrorMessage,
  ] = useState();

  const [
    showBloodSamplesSentHrsErrorMessage,
    setShowBloodSamplesSentHrsErrorMessage,
  ] = useState();
  const [
    showBloodSamplesSentMinsErrorMessage,
    setShowBloodSamplesSentMinsErrorMessage,
  ] = useState();

  const hasOthers = (selectedOpts) => {
    if (selectedOpts == "[]") {
      selectedOpts = [];
    }
    const test =
      selectedOpts && selectedOpts.length
        ? selectedOpts.filter((s) => s.itemName === "Other")
        : [];
    return test.length > 0;
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  let url = `patient/update/baby_antibiotic`;

  useEffect(() => {
    if (patient?.bmrndata?.reading_id && patient.bmrndata?.study_id) {
      const req = {
        readingId: patient?.bmrndata?.reading_id,
        studyid: patient.bmrndata?.study_id,
      };
      dispatch(getBabyAntibioticDataByReadingId(req))
        .unwrap()
        .then((resdata) => {
          let antiBioData = {
            response: Object.assign({}, resdata.response),
          };
          const data =
            antiBioData.response && antiBioData.response.antibiotic_name
              ? (antiBioData.response.antibiotic_name = JSON.parse(
                  antiBioData.response.antibiotic_name
                ))
              : undefined;

          const data1 =
            antiBioData.response && antiBioData.response.id
              ? setUpdateRecord(true)
              : setUpdateRecord(false);
          if (antiBioData.response) {
            setAddBabyAntibioticData(
              antiBioData.response &&
                JSON.stringify(antiBioData.response) != "{}"
                ? antiBioData.response
                : { antibiotic_name: [] }
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [patient?.bmrndata?.reading_id, patient.bmrndata?.study_id]);

  const handleChange = (event) => {
    const fieldname = event.target.name;
    let fieldvalue = event.target.value;
    if (fieldname == "antibiotic_name") {
      let baby_antibiotec = event.target.value.map((data) => {
        return data.itemName ? data.itemName : data;
      });
      let value = baby_antibiotec;
      baby_antibiotec.indexOf(baby_antibiotec[baby_antibiotec.length - 1]) !=
      baby_antibiotec.length - 1
        ? (value = baby_antibiotec.filter(
            (br) => br !== baby_antibiotec[baby_antibiotec.length - 1]
          ))
        : null;
      fieldvalue = value;
    }
    const newFormData = { ...addBabyAntibioticData };
    newFormData[fieldname] = fieldvalue;
    setAddBabyAntibioticData(newFormData);
  };
  const handleAddFormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    // console.log("Field Name is ");
    // console.log(fieldname);

    const fieldvalue = event.target.value;
    // console.log("Field Value is ");
    // console.log(fieldvalue);

    const newFormData = { ...addBabyAntibioticData };
    newFormData[fieldname] = fieldvalue;

    // console.log("Field Value is ");
    // console.log(newFormData);

    setAddBabyAntibioticData(newFormData);
    setBabyAntibioticData(newFormData);
  };

  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();

    await submitData(12);
  };

  useEffect(() => {
    if (props.triggerChildFunction) submitData();
  }, [props.triggerChildFunction]);

  const submitData = async (nextTab) => {
    const newFormData = { ...addBabyAntibioticData };
    // console.log("Data That the Application is sending is,");
    // console.log(newFormData);
    // console.log("About to call Axios API");
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }

    newFormData.study_id = patient.bmrndata?.study_id;
    newFormData.reading = patient.bmrndata?.reading_id;
    newFormData.loggedUserId = loggedUserId;

    newFormData.antibiotic_name = JSON.stringify(newFormData.antibiotic_name);
    if (!updateRecord) {
      dispatch(antibioticAddition(newFormData))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getBabyAntibioticDataByReadingId(req));
          props.updateSessionData(nextTab ? nextTab : props.nextTab);
          if(props.triggerChildFunction){
            dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
            }
            else{
              toast.success("Data Updated Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          // console.log(resdata);
        });
    } else {
      const req = {
        url,
        study_id,
        reading: patient?.bmrndata?.reading_id,
        data: newFormData,
        loggedUserId,
      };
      dispatch(updatePatientFormData(req))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getBabyAntibioticDataByReadingId(req));
          props.updateSessionData(nextTab ? nextTab : props.nextTab);if(props.triggerChildFunction){
            dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
            }
            else{
              toast.success("Data Updated Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          // console.log(resdata);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const verifyTimeOfAdministrationHours = (event) => {
    if (event.target.value >= 0 && event.target.value <= 23) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          time_of_administration_of_antiobiotic_hours: false,
        })
      );
      setShowTimeOfAdministrationHoursErrorMessage("");
    } else {
      setShowTimeOfAdministrationHoursErrorMessage(
        "Time of Anti Biotic Administration Hours Value should be between 0 and 23"
      );
      //document.getElementById("TimeOfAdministrationHours").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          time_of_administration_of_antiobiotic_hours: true,
        })
      );
    }
  };

  const verifyTimeOfAdministrationMins = (event) => {
    if (event.target.value >= 0 && event.target.value <= 59) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          time_of_administration_of_antiobiotic_minute: false,
        })
      );
      setShowTimeOfAdministrationMinsErrorMessage("");
    } else {
      setShowTimeOfAdministrationMinsErrorMessage(
        " Time of Anti Biotic Administration Minutes Value should be between 0 and 59"
      );
      //document.getElementById("TimeOfAdministrationMins").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          time_of_administration_of_antiobiotic_minute: true,
        })
      );
    }
  };

  const verifyBloodSamplesSentHrs = (event) => {
    if (event.target.value >= 0 && event.target.value <= 23) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          time_of_blood_samples_sent_for_culture_test_hours: false,
        })
      );
      setShowBloodSamplesSentHrsErrorMessage("");
    } else {
      setShowBloodSamplesSentHrsErrorMessage(
        " Blood Sample Collection Time Hours Value should be between 0 and 23"
      );
      //document.getElementById("BloodSamplesSentHrs").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          time_of_blood_samples_sent_for_culture_test_hours: true,
        })
      );
    }
  };

  const verifyBloodSamplesSentMins = (event) => {
    if (event.target.value >= 0 && event.target.value <= 59) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          time_of_blood_samples_sent_for_culture_test_minute: false,
        })
      );
      setShowBloodSamplesSentMinsErrorMessage("");
    } else {
      setShowBloodSamplesSentMinsErrorMessage(
        " Blood Sample Collection Time Mins Value should be between 0 and 59"
      );
      document.getElementById("BloodSamplesSentMins").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          time_of_blood_samples_sent_for_culture_test_minute: true,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {isLoading ? (
        <div
          style={{
            height: "100px",
            paddingTop: "230px",
            textAlign: "center",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Card variant="none">
            <CardContent>
              <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="PatientRecordID"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Record Id
                    </label>
                    <TextField
                      id="study_id"
                      name="study_id"
                      label=""
                      variant="outlined"
                      value={patient.bmrndata?.study_id}
                      onChange={handleAddFormChange}
                      className="dataentry_input"
                      size="small"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Antibiotic Given"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Antibiotic Given
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addBabyAntibioticData?.antibiotic_given}
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                    >
                      <ToggleButton
                        name="antibiotic_given"
                        value="Yes"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="antibiotic_given"
                        value="No"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="antibiotic_given"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Date of administration"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Date of Anti Biotic Administration
                    </label>
                    <DatePicker
                      id="date_of_administration_of_antiobiotic"
                      name="date_of_administration_of_antiobiotic"
                      size="small"
                      className="dataentry_input"
                      inputFormat="DD/MM/YYYY"
                      maxDate={moment().format()}
                      minDate={baby_date_of_admission}
                      value={
                        addBabyAntibioticData?.date_of_administration_of_antiobiotic
                      }
                      onChange={(date) => {
                        setDateOfAdministration(date["$d"]);
                        handleAddFormChange({
                          target: {
                            name: "date_of_administration_of_antiobiotic",
                            value: date["$d"],
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          className="dataentry_input"
                          helperText={null}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Time of Administration"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      Time of Anti Biotic Administration (0 - 23 Hrs)
                    </label>
                    <Grid container>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="TimeOfAdministrationHours"
                          name="time_of_administration_of_antiobiotic_hours"
                          //className="dataentry_input"
                          className={
                            "dataentry_input " +
                            (errorFields.time_of_administration_of_antiobiotic_hours
                              ? "errorField"
                              : "")
                          }
                          variant="outlined"
                          size="small"
                          value={
                            addBabyAntibioticData?.time_of_administration_of_antiobiotic_hours
                          }
                          onChange={(e) => {
                            handleAddFormChange(e);
                            verifyTimeOfAdministrationHours(e);
                          }}
                          error={
                            errorFields.time_of_administration_of_antiobiotic_hours &&
                            showTimeOfAdministrationHoursErrorMessage
                          }
                          errorText={showTimeOfAdministrationHoursErrorMessage}
                          helperText={
                            errorFields.time_of_administration_of_antiobiotic_hours &&
                            showTimeOfAdministrationHoursErrorMessage
                              ? showTimeOfAdministrationHoursErrorMessage
                              : ""
                          }
                          onKeyDown={(event) => {
                            if (
                              !Numerics.test(event.key) &&
                              event.key != "Backspace"
                            ) {
                              event.preventDefault();
                              setShowTimeOfAdministrationHoursErrorMessage(
                                "Only Numerics are Acceptable"
                              );
                              setErrorFields(
                                Object.assign({}, errorFields, {
                                  time_of_administration_of_antiobiotic_hours: true,
                                })
                              );
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                className="InputAdornment"
                              >
                                Hrs
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="TimeOfAdministrationMins"
                          name="time_of_administration_of_antiobiotic_minute"
                          className={
                            "dataentry_input " +
                            (errorFields.time_of_administration_of_antiobiotic_minute
                              ? "errorField"
                              : "")
                          }
                          variant="outlined"
                          size="small"
                          value={
                            addBabyAntibioticData?.time_of_administration_of_antiobiotic_minute
                          }
                          onChange={(e) => {
                            handleAddFormChange(e);
                            verifyTimeOfAdministrationMins(e);
                          }}
                          error={
                            errorFields.time_of_administration_of_antiobiotic_minute &&
                            showTimeOfAdministrationMinsErrorMessage
                          }
                          errorText={showTimeOfAdministrationMinsErrorMessage}
                          helperText={
                            errorFields.time_of_administration_of_antiobiotic_minute &&
                            showTimeOfAdministrationMinsErrorMessage
                              ? showTimeOfAdministrationMinsErrorMessage
                              : ""
                          }
                          onKeyDown={(event) => {
                            if (
                              !Numerics.test(event.key) &&
                              event.key != "Backspace"
                            ) {
                              event.preventDefault();
                              setShowTimeOfAdministrationMinsErrorMessage(
                                "Only Numerics are Acceptable"
                              );
                              setErrorFields(
                                Object.assign({}, errorFields, {
                                  time_of_administration_of_antiobiotic_minute: true,
                                })
                              );
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                className="InputAdornment"
                              >
                                Mins
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Antibiotic Name"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      Antibiotic Name
                    </label>
                    {addBabyAntibioticData ? (
                      <Select
                        labelId="antibiotic_name"
                        id="antibiotic_name"
                        className="dataentry_select_edu"
                        name="antibiotic_name"
                        onChange={handleChange}
                        multiple
                        size="small"
                        variant="outlined"
                        value={
                          addBabyAntibioticData?.antibiotic_name
                            ? addBabyAntibioticData?.antibiotic_name
                            : []
                        }
                        /* renderValue={(selected) =>
                    selected.map((s) => s.itemName).join(",")
                  } */
                        renderValue={(selected) => {
                          return selected.map((s) => s).join(",");
                        }}
                      >
                        {ANTIBIOTIC_NAME.map((name) => (
                          <MenuItem key={name.id} value={name}>
                            <Checkbox
                              checked={
                                addBabyAntibioticData?.antibiotic_name.indexOf(
                                  name.itemName
                                ) > -1
                              }
                            />
                            <ListItemText primary={name?.itemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    ) : undefined}
                  </Grid>
                  {hasOthers(addBabyAntibioticData?.antibiotic_name) && (
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Antibiotic Name (Other)"
                        className="dataentry_label"
                        style={{ display: "block" }}
                      >
                        Antibiotic Name (Other)
                      </label>
                      <TextField
                        id="antibiotic_name_if_other"
                        name="antibiotic_name_if_other"
                        variant="outlined"
                        size="small"
                        className="dataentry_input"
                        value={addBabyAntibioticData?.antibiotic_name_if_other}
                        onChange={handleAddFormChange}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Blood Samples for Culture test"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Blood Sample Collection Date
                    </label>
                    <DatePicker
                      id="date_of_blood_samples_sent_for_culture_test"
                      name="date_of_blood_samples_sent_for_culture_test"
                      size="small"
                      className="dataentry_input"
                      inputFormat="DD/MM/YYYY"
                      maxDate={moment().format()}
                      minDate={baby_date_of_admission}
                      value={
                        addBabyAntibioticData?.date_of_blood_samples_sent_for_culture_test
                      }
                      onChange={(date) => {
                        setBloodSampleSentDate(date["$d"]);
                        handleAddFormChange({
                          target: {
                            name: "date_of_blood_samples_sent_for_culture_test",
                            value: date["$d"],
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          className="dataentry_input"
                          helperText={null}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Time of Blood Samples"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      Blood Sample Collection Time
                    </label>
                    <Grid container>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="BloodSamplesSentHrs"
                          name="time_of_blood_samples_sent_for_culture_test_hours"
                          className={
                            "dataentry_input " +
                            (errorFields.time_of_blood_samples_sent_for_culture_test_hours
                              ? "errorField"
                              : "")
                          }
                          variant="outlined"
                          size="small"
                          value={
                            addBabyAntibioticData?.time_of_blood_samples_sent_for_culture_test_hours
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                className="InputAdornment"
                              >
                                Hrs
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            handleAddFormChange(e);
                            verifyBloodSamplesSentHrs(e);
                          }}
                          error={
                            errorFields.time_of_blood_samples_sent_for_culture_test_hours &&
                            showBloodSamplesSentHrsErrorMessage
                          }
                          errorText={showBloodSamplesSentHrsErrorMessage}
                          helperText={
                            errorFields.time_of_blood_samples_sent_for_culture_test_hours &&
                            showBloodSamplesSentHrsErrorMessage
                              ? showBloodSamplesSentHrsErrorMessage
                              : ""
                          }
                          onKeyDown={(event) => {
                            if (
                              !Numerics.test(event.key) &&
                              event.key != "Backspace"
                            ) {
                              event.preventDefault();
                              setShowBloodSamplesSentHrsErrorMessage(
                                "Only Numerics are Acceptable"
                              );
                              setErrorFields(
                                Object.assign({}, errorFields, {
                                  time_of_blood_samples_sent_for_culture_test_hours: true,
                                })
                              );
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="BloodSamplesSentMins"
                          name="time_of_blood_samples_sent_for_culture_test_minute"
                          className={
                            "dataentry_input " +
                            (errorFields.time_of_blood_samples_sent_for_culture_test_minute
                              ? "errorField"
                              : "")
                          }
                          variant="outlined"
                          size="small"
                          value={
                            addBabyAntibioticData?.time_of_blood_samples_sent_for_culture_test_minute
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                className="InputAdornment"
                              >
                                Mins
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            handleAddFormChange(e);
                            verifyBloodSamplesSentMins(e);
                          }}
                          error={
                            errorFields.time_of_blood_samples_sent_for_culture_test_minute &&
                            showBloodSamplesSentMinsErrorMessage
                          }
                          errorText={showBloodSamplesSentMinsErrorMessage}
                          helperText={
                            errorFields.time_of_blood_samples_sent_for_culture_test_minute &&
                            showBloodSamplesSentMinsErrorMessage
                              ? showBloodSamplesSentMinsErrorMessage
                              : ""
                          }
                          onKeyDown={(event) => {
                            if (
                              !Numerics.test(event.key) &&
                              event.key != "Backspace"
                            ) {
                              event.preventDefault();
                              setShowBloodSamplesSentMinsErrorMessage(
                                "Only Numerics are Acceptable"
                              );
                              setErrorFields(
                                Object.assign({}, errorFields, {
                                  time_of_blood_samples_sent_for_culture_test_minute: true,
                                })
                              );
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Antibiotic Given"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Antibiotic Given Prior to Blood Sample Collection
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={
                        addBabyAntibioticData?.blood_sample_taken_prior_to_antiobiotic_administration
                      }
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                    >
                      <ToggleButton
                        name="blood_sample_taken_prior_to_antiobiotic_administration"
                        value="Yes"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="blood_sample_taken_prior_to_antiobiotic_administration"
                        value="No"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="blood_sample_taken_prior_to_antiobiotic_administration"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>

                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    aria-label="add"
                    className="buttonStyle sign-up-button"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </LocalizationProvider>
      )}
    </React.Fragment>
  );
};

export default BabyAntibiotic;
