import React, { useState } from "react";
import { Navigate, useLocation, useNavigate, Link } from "react-router-dom";
import { Box, Button, Card, CardContent, Grid, Dialog } from "@mui/material";
import {
  idverification,
  termsconditions,
  BacktoAdddiInfo,
} from "../reducers/signupDataThunk";
import { useDispatch, useSelector } from "react-redux";
import "../../login/styles/login.scss";
import "../../dataEntry/styles/DataEntry.scss";
import TermsAndConditions from "./terms&Conditions";

const steps = ["Set Up", "Additional Information", "Verification"];

const IdentityVerification = (props) => {
  const signup = useSelector((state) => state.signup);
  const [loginForm, setLoginForm] = React.useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const [defaultCheck, setDefaultCheck] = useState({
    acceprterms: false,
  });

  const openDialog = () => {
    navigate("/login");
    //props.nextStep();
    //setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const Backtoaddi = () => {
    dispatch(BacktoAdddiInfo());
  };

  const HandleAddFormSubmit = (event) => {
    event.preventDefault();
    //props.nextStep();
    dispatch(idverification());
    // navigate("/login");
    props.nextStep();
  };

  const termssubmit = (event) => {
    event.preventDefault();
    dispatch(termsconditions())
      .unwrap()
      .then((resdata) => {
        navigate("/login");
        const handleClick = () => {
          navigate("/login");
        };
      });
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Identity Verification
          </p>
          <p className="identity_content">
            Dear user, as a securty measure, the app requires verifying your
            identity and your place of work.
          </p>
          <p className="identity_content">
            The verification process may take upto 24 hours. We thank you for
            your patience.
          </p>
          <p className="identity_content">
            You'll be notified via your email once your profile has been
            verified.
          </p>
          {/*  <p className="identity_content">
            You can still take a look at how PresCo works- Explore our trial
            version!
          </p> */}
        </Grid>
      </Grid>
      <form id="acc-creation-form" onSubmit={HandleAddFormSubmit}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Button variant="contained" className="purple-add" type="submit">
              <span>Continue</span>
            </Button>
          </Grid>
        </Grid>
      </form>

      <Grid container>
        <Grid item xs={12} sm={12}>
          <div className="account_bottom">
            Have an account already?{" "}
            <Link to="/login" style={{ color: "#6572E4" }}>
              Login
            </Link>
          </div>
        </Grid>
      </Grid>
      {signup.showTermsConditions && <TermsAndConditions />}
    </React.Fragment>
  );
};

export default IdentityVerification;
