export const initialState = {
    formTab: null,
    users:{},
    deleteUser:{},
    profileDetail:{
        user_name:"",
        state:"",
        contact_number:"",
        email_address:"",
        institution_name:"",
        
    },
    NewPassword:{
        oldPassword:"",
        newPassword:"",
        confirm_password:"",
    }
};