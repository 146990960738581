import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";

import { DialogTitle } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import "../../dataEntry/styles/DataEntry.scss";
import { Box } from "@mui/system";
import Reports from "../../dataEntry/components/Reports";

const ViewReport = (props) => {
  const closeDialog = (e) => {
    e.preventDefault();
    props.onClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
        open={props.open}
        onClose={props.onClose}
        //style={{width:"60%"}}
        className="report-dialog"
      >
        <DialogTitle style={{ borderBottom: "0.8px solid #f0f1fc" }}>
          <Link
            to="/"
            className="FIle-left"
            onClick={closeDialog}
            style={{ marginRight: "20px" }}
          >
            <img
              src={Back}
              className="file-img-left"
              alt=""
              style={{ width: "12px" }}
            />
          </Link>
          <span>Reports</span>
          <Link to="/" className="FIle-right-extreme" onClick={closeDialog}>
            <img src={Close} className="file-img-extreme-right" alt="" />
          </Link>
        </DialogTitle>
        <DialogContent>
          <Reports selectedPatient={props.selectedPatient} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default ViewReport;
