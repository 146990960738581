import React, { useState, useEffect } from "react";
import AdminScreen from "./ward/AdminScreen";
import AdminListing from "./ward/AdminListing";
import { Grid, Button, ButtonGroup, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import "../../common/styles/header.scss";

import { useDispatch, useSelector } from "react-redux";

const AdminSteps = [
  { label: "Users", key: 1 },
  { label: "User Branch Map", key: 2 },
];

const UserManagement = (props) => {
  const dispatch = useDispatch();
  const [steps, setSteps] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState(null);
  useEffect(() => {
    let temp = AdminSteps;
    setSteps(temp);
  });
  const validateAndUpdateTab = (tabKey) => {
    if (tabKey < 3 || tabKey > 0) {
      setActiveTab(tabKey);
    }
  };

  return (
    <div className="dataoutlet">
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Grid item xs={12} sm={12}>
            <div className="formSection">
              <Card>
                <CardContent>
                  <div>
                    <Grid container>
                      <Grid className="branch_header_group">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            "& > *": {
                              m: 1,
                            },
                          }}
                        >
                          <ButtonGroup
                            variant="outlined"
                            aria-label="outlined button group"
                          >
                            {steps.map((step) => {
                              return (
                                <Button
                                  className="branch_header_button"
                                  //className={step.className}
                                  variant={
                                    activeTab === step.key
                                      ? "contained"
                                      : "outlined"
                                  }
                                  onClick={() => validateAndUpdateTab(step.key)}
                                >
                                  {step.label}
                                </Button>
                              );
                            })}
                          </ButtonGroup>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {activeTab < 3 && (
        <Grid item xs={12} sm={12}>
          {activeTab === 1 && <AdminListing changeTab={validateAndUpdateTab} />}
          {activeTab === 2 && <AdminScreen />}
        </Grid>
      )}
    </div>
  );
};

export default UserManagement;
