import { createSlice } from "@reduxjs/toolkit";
import * as BranchDataThunk from "./branchDataThunk";
import { initialState } from "./branchInitialState";

const branchSlice = createSlice({
  name: "branch",
  initialState,
  extraReducers: {
    [BranchDataThunk.setAddBranchFormData.fulfilled]: (state, action) => {
      state.branchdata = action.payload;
    },
    [BranchDataThunk.getBranchDetail.fulfilled]: (state, action) => {
      state.branchList = action.payload.response;
    },

    [BranchDataThunk.addbranchdetails.fulfilled]: (state, action) => {
      state.branchdata = action.payload.response;
      state.isBranch = true;
    },
    [BranchDataThunk.addbranchdetails.rejected]: (state, action) => {
      state.error = action.error;
      state.isBranch = true;
    },

    [BranchDataThunk.setBranchEdit.fulfilled]: (state, action) => {
      state.isBranch = action.payload;
    },

    [BranchDataThunk.setErrorMessage.fulfilled]: (state, action) => {
      state.errorMessage = action.payload;
    },
    [BranchDataThunk.setsuccessMessage.fulfilled]: (state, action) => {
      state.successMessage = action.payload;
    },

    [BranchDataThunk.deleteBranch.fulfilled]: (state, action) => {
      state.deleteBranch = action.payload.response;
    },
  },
});
export default branchSlice.reducer;
