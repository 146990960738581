import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import File from "../../common/assets/images/File.svg";
import Download from "../../common/assets/images/Download.svg";
import ReportFile from "../../common/assets/images/fileNew.svg";
import { save } from "save-file";

import {
  Grid,
  Card,
  CardContent,
  Button,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import "../../dataEntry/styles/DataEntry.scss";
import { Box } from "@mui/system";

import {
  getdropinion,
  getRefDoctorsByBmrn,
  getOpinionByRefDr,
  getReadingIdByRefDoctor,
} from "../reducers/Dashboardthunk";
import moment from "moment";

import {
  downloadReport,
  deleteReport,
} from "../../dataEntry/reducers/patientDataThunk";

const ReadingSummary = (props) => {
  const patient = useSelector((state) => state.patient);
  const entity_type = patient.globalRecord?.entity_type;
  const dbbranchdata = useSelector((state) => state.dashboard);

  const studyId =
    props && props.selectedPatient
      ? props.selectedPatient.study_id
      : patient.bmrndata?.id;

  const reading =
    props && props.selectedPatient ? props.selectedPatient.reading : "";
  const baby_medical_record_number =
    props && props.selectedPatient
      ? props.selectedPatient.baby_medical_record_number
      : patient.bmrnList?.baby_medical_record_number;
  const motherName =
    props && props.selectedPatient
      ? props.selectedPatient.mother_name
      : patient.bmrnList?.mother_name;
  const pscore = patient.score;

  const [listImagings, setListImagings] = useState([]);
  const [drsByopinion, setDrsByopinion] = useState([]);
  const [selectedRefDR, setSelectedRefDR] = React.useState("-1");
  const [OpinionByDrs, setOpinionByDrs] = React.useState();
  const [selectedDrName, setSelectedDrName] = React.useState();
  const [ptReadings, setPtReadings] = useState([]);
  const [selectedReadingId, setSelectedReadingId] = useState(0);

  const dispatch = useDispatch();
  const closeDialog = (e) => {
    e.preventDefault();
    props.onClose();
  };
  /*  useEffect(() => {
    dispatch(getdropinion({ studyId, reading }))
      .unwrap()
      .then((resdata) => {
        const imagings = resdata.response;
        // console.log("imagings:", imagings);
        //setListImagings(imagings);
        setListImagings(imagings);
      });
  }, []); */

  const onRefDrSelected = (e) => {
    const refDrID = e.target.value;
    setSelectedRefDR(e.target.value);
    setSelectedDrName(e.target.name);
    const req = { refDrID, studyId };
    dispatch(getReadingIdByRefDoctor(req))
      .unwrap()
      .then((resdata) => {
        const readings = resdata?.response;
      setPtReadings(readings);
      });
  };
  
  const onRefReadingSelected = (e) => {
    const reading = e.target.value;

    setSelectedReadingId(reading);
    const req = { selectedRefDR, studyId, reading };
    dispatch(getOpinionByRefDr(req))
      .unwrap()
      .then((resdata) => {
        setOpinionByDrs(resdata.response[0]);
        setListImagings(resdata.response);
      });
  };

  useEffect(() => {
    dispatch(getRefDoctorsByBmrn({ studyId}))
      .unwrap()
      .then((resdata) => {
        const result = resdata.response;
        setDrsByopinion(result);
      });
  }, []);
  const downloadRep = (e, fileName) => {
    e.preventDefault();
    // console.log("The file that we are getting from function is, ", fileName);

    dispatch(downloadReport(fileName))
      .unwrap()
      .then(async (resdata) => {
        // console.log("The referal download single report response data is",resdata);
        await save(resdata.Body, fileName);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
        open={props.open}
        onClose={props.onClose}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "0.8px solid",
            borderColor: "rgba(101, 114, 228, 0.4)",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Link
                to="/"
                className="FIle-left"
                onClick={closeDialog}
                style={{ marginRight: "20px" }}
              >
                <img
                  src={Back}
                  className="file-img-left"
                  alt=""
                  style={{ width: "12px" }}
                />
              </Link>
              <span>Reading Summary</span>
            </Grid>
            <Grid item>
              <Link className="FIle-right-extreme" to="/" onClick={closeDialog}>
                <img
                  src={Close}
                  style={{ marginRight: "20px", marginLeft: "500px" }}
                  alt=""
                />
              </Link>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box>
            <form id="bmrn-form">
              <Card style={{ marginTop: 20 }} variant="none">
                <CardContent>
                  <div className="round-read-bordered">
                  <Grid container>
                    <Grid item xs={12} sm={8}>
                      <p className="readingsummary_name">
                        Referral Doctor:
                        <Select
                          labelId="Ward"
                          id="Ref_Dr"
                          name="Ref_Dr"
                          label=""
                          size="small"
                          className="select_usertype2"
                          value={selectedRefDR}
                          onChange={onRefDrSelected}
                          style={{ margin: "5px 0px" }}
                        >
                          <MenuItem value="-1">Select Doctor</MenuItem>
                          {drsByopinion && drsByopinion.length
                            ? drsByopinion.map((num) => (
                                <MenuItem value={num.referral_id}>
                                  {num.doctor_name}
                                </MenuItem>
                              ))
                            : undefined}
                        </Select>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <p className="readingsummary_name">
                        Reading ID:
                                  <Select
                                    labelId="Reading_id"
                                    id="reading"
                                    name="reading"
                                    label=""
                                    size="small"
                                    className="select_usertype2"
                                    style={{ margin: "5px 0px" }}
                                    value={selectedReadingId}
                                    onChange={onRefReadingSelected}
                                  >
                                    <MenuItem value="Select Readings" disabled>
                                      Select Readings
                                    </MenuItem>
                                    {ptReadings && ptReadings.length
                                      ? ptReadings.map((num) => (
                                          <MenuItem value={num.reading}>
                                            R{num.reading}
                                          </MenuItem>
                                        ))
                                      : undefined}
                                  </Select>
                                  </p>
                              </Grid>
                              </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <p className="readingsummary_name">
                          BMRN
                          <span className="summary_data">
                            {baby_medical_record_number}
                          </span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p className="readingsummary_name">
                          Mother's name
                          <span className="summary_data">{motherName}</span>
                        </p>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={4}>
                        <p className="readingsummary_name">
                          Date
                          <span className="summary_data">
                            {OpinionByDrs?.createdAt
                              ? moment(OpinionByDrs?.createdAt).format(
                                  "DD - MM - YY"
                                )
                              : "-"}
                          </span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <p className="readingsummary_name">
                          Time
                          <span className="summary_data">
                            {OpinionByDrs?.createdAt
                              ? moment(OpinionByDrs?.createdAt).format(
                                  "HH:MM:SS"
                                )
                              : "-"}
                          </span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <p className="readingsummary_name">
                          Score
                          <span className="summary_data">
                            {OpinionByDrs?.score
                              ? OpinionByDrs?.score.toFixed(1)
                              : "-"}
                          </span>
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="round-bordered">
                    <div className="report-header">
                      <Grid container className="CaseSheets">
                        <Grid item xs={12} sm={12}>
                          Referral Doctor Opinion
                        </Grid>
                      </Grid>
                    </div>
                    <div className="report-content">
                      <Grid container>
                        <Grid item xs={12} sm={12} className="No_reports_Found">
                          <div>
                            <p>{OpinionByDrs?.opinion}</p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="report-header">
                      <Grid container className="CaseSheets">
                        <Grid item xs={12} sm={12}>
                          Referral Doctor Prescription
                        </Grid>
                      </Grid>
                    </div>
                    <div className="report-content">
                      <Grid container>
                        <Grid item xs={12} sm={12} className="No_reports_Found">
                          <div>
                            <p>{OpinionByDrs?.prescription_data}</p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="report-header">
                      <Grid container className="CaseSheets">
                        <Grid item xs={12} sm={12}>
                          Prescription Document
                        </Grid>
                      </Grid>
                    </div>
                    <div className="report-content">
                      <Grid container>
                        <Grid item xs={12} sm={12} className="No_reports_Found">
                          <div>
                            <List>
                              {listImagings.map((sheet) => (
                                <ListItem>
                                  {sheet.prescription && (
                                    <Grid container>
                                      <Grid item xs={12} sm={10}>
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon>
                                            <Link to="/" className="FIle-ref">
                                              <img
                                                src={ReportFile}
                                                className="file-img"
                                              />
                                            </Link>
                                          </ListItemIcon>
                                          <div
                                            className={"dashboard-file-name"}
                                          >
                                            {sheet.prescription}
                                          </div>
                                        </div>
                                      </Grid>

                                      <Grid item xs={12} sm={2}>
                                        <ListItemIcon className="file-align">
                                          <IconButton
                                            className="acrossBtn"
                                            onClick={(e) =>
                                              downloadRep(e, sheet.prescription)
                                            }
                                            style={{
                                              backgroundColor:
                                                "rgba(101, 114, 228, 0.10)",
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                              borderRadius: "5px",
                                            }}
                                          >
                                            <img
                                              src={Download}
                                              //className="DownLoad-img"
                                            />
                                          </IconButton>
                                        </ListItemIcon>
                                      </Grid>
                                    </Grid>
                                  )}
                                </ListItem>
                              ))}
                            </List>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default ReadingSummary;
