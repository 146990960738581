import { createSlice } from "@reduxjs/toolkit";
import * as DashboardThunk from "../reducers/Dashboardthunk";

const initialState = {
  branchList: {},
  scoreTrend: {},
  opinion: {},
  getbmrnscreen: {},
  deleterecord: {},
  wardListbyBranch: {},
  AdminListByBranch: {},
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: {
    [DashboardThunk.getBranchDetail.fulfilled]: (state, action) => {
      state.branchList = action.payload.response;
    },
    [DashboardThunk.getBranchDetailByUser.fulfilled]: (state, action) => {
      state.branchList = action.payload.response;
    },
    [DashboardThunk.scoreTrend.fulfilled]: (state, action) => {
      state.scoreTrend = action.payload.response;
    },
    [DashboardThunk.getdropinion.fulfilled]: (state, action) => {
      state.opinion = action.payload.response;
    },
/* 
    [DashboardThunk.getRefDoctorsByOpinion.fulfilled]: (state, action) => {
      state.refDrsByOpinion = action.payload.response;
    }, */
    [DashboardThunk.getdataentryscreen.fulfilled]: (state, action) => {
      state.getbmrnscreen = {};
    },

    [DashboardThunk.getAddBMRNScreen.fulfilled]: (state, action) => {
      state.getbmrnscreen = {};
    },
    [DashboardThunk.deleterecord.fulfilled]: (state, action) => {
      state.deleterecord = action.payload.response;
    },
    [DashboardThunk.getWardDetailbyBranch.fulfilled]: (state, action) => {
      state.wardListbyBranch = action.payload.response;
    },
    [DashboardThunk.getAdminDetailByBranch.fulfilled]: (state, action) => {
      state.AdminListByBranch = action.payload.response;
    },

    [DashboardThunk.updateDashboardBranch.fulfilled]: (state, action) => {
      state.dashboardBranch = action.payload;
    },

    [DashboardThunk.updateDashboardWard.fulfilled]: (state, action) => {
      state.dashboardWard = action.payload;
    },
  },
});
export default dashboardSlice.reducer;
