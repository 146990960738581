import { createSlice } from "@reduxjs/toolkit";
import * as profileDataThunk from "./profileDataThunk";

import { initialState } from "./profileInitialState";

const profileSlice = createSlice({
  name: "Profile",
  initialState,
  extraReducers: {
    [profileDataThunk.getStaffDetails.fulfilled]: (state, action) => {
      state.users = action.payload.response;
    },
    [profileDataThunk.setFormTab.fulfilled]: (state, action) => {
      state.formTab = action.payload;
    },
    [profileDataThunk.getUserDetails.fulfilled]: (state, action) => {
      state.profileDetail = action.payload.response[0];
    },
    [profileDataThunk.updateProfileData.fulfilled]: (state, action) => {
      state.profileDetail = action.payload.response;
    },
    [profileDataThunk.ProfileFileUpload.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [profileDataThunk.deleteUser.fulfilled]: (state, action) => {
      state.deleteUser = action.payload.response;
    },
    [profileDataThunk.updateStaffPermission.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [profileDataThunk.changePassword.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [profileDataThunk.setErrorMessage.fulfilled]: (state, action) => {
      state.errorMessage = action.payload;
    },
    [profileDataThunk.getSubscriptions.fulfilled]: (state, action) => {
      state.subscriptions = action.payload.response;
    },
  },
});
export default profileSlice.reducer;
