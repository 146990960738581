import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  TextField,
  IconButton,
} from "@mui/material";
import File from "../../common/assets/images/File.svg";
import Download from "../../common/assets/images/Download.svg";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";

import "../../login/styles/login.scss";
import "../../dataEntry/styles/DataEntry.scss";
import { save } from "save-file";

import logo from "../../common/assets/images/avyantra_logo.png";
import prelogo from "../../common/assets/images/presco_logo.png";
import theme from "../../theme";
import {
  getUserDetailsByVerificationKey,
  approveUser,
  rejectUser,
} from "../reducers/signupDataThunk";

import { ToastContainer, toast } from "react-toastify";
import { downloadReport } from "../../dataEntry/reducers/patientDataThunk";

const VerifyAccount = (props) => {
  const userByVK = useSelector((state) => state.signup.userByVerificationKey);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const verifyKey = useParams();
  const verificationKey = verifyKey.id;

  const downloadRep = (e, fileName) => {
    e.preventDefault();

    dispatch(downloadReport(fileName))
      .unwrap()
      .then(async (resdata) => {
        await save(resdata.Body, fileName);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //const userid = userByVK.user_id ? userByVK.user_id : undefined;

  useEffect(() => {
    dispatch(getUserDetailsByVerificationKey(verificationKey));
  }, []);

  const ApproveUser = () => {
    const user_id = userByVK?.user_id;
    const user_name = userByVK?.user_name;
    const contact_number = userByVK?.contact_number;
    const status = "Approved";
    const req = { user_id,user_name,contact_number, status };
    dispatch(approveUser(req))
      .unwrap()
      .then(async (resdata) => {
        toast.success("User Approved Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };
  const RejectUser = () => {
    const user_id = userByVK?.user_id;
    const status = "Rejected";
    const user_name = userByVK?.user_name;
    const contact_number = userByVK?.contact_number;
    const req = { user_id,user_name,contact_number, status };
    dispatch(rejectUser(req))
      .unwrap()
      .then(async (resdata) => {
        toast.success("User Rejected Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  /* const handleAddFormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    // console.log("Field Name is ");
    // console.log(fieldname);
    const fieldvalue = event.target.value;
    // console.log("Field Value is ");
    // console.log(fieldvalue);
  };
  const HandleAddFormSubmit = (event) => {
    event.preventDefault();
  }; */

  return (
    <React.Fragment>
      <ToastContainer />
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className="avy_logo">
                <a
                  href="https://avyantra.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={logo} className="avy-img" />
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="login_contact">
                <a
                  href="https://avyantra.com/#contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contactUs"
                >
                  <Button
                    style={{ color: "#6572E4" }}
                    className="login_contact_content"
                    color="primary"
                  >
                    Contact us
                  </Button>
                </a>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <img src={prelogo} className="pre_img" />
              <div className="pre_caption">
                Neonatal Sepsis Prediction Platform
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className="signUpBg1" style={{ height: "10%", top: "90%" }}></div>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{ maxWidth: "95%" }}
            className="signup-container1"
            style={{ height: "100%", marginTop: "0", maxWidth: "100%" }}
          >
            <Card variant="outlined">
              <CardContent>
                <p className="cre_acc">New Signup Approval</p>
                <p className="identity_content">
                  Given below are the new user's detail and documents. Kindly
                  review them and either approve or reject their account..
                </p>

                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="User Type"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      User Type
                    </label>
                    <TextField
                      id="user_type"
                      name="user_type"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      value={userByVK?.user_type}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="User Name"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      User Name
                    </label>
                    <TextField
                      id="user_name"
                      name="user_name"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      value={userByVK?.user_name}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="User Type"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      User Mobile Number
                    </label>
                    <TextField
                      id="contact_number"
                      name="contact_number"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      value={userByVK?.contact_number}
                      disabled
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Email ID"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      User Email ID
                    </label>
                    <TextField
                      id="email_address"
                      name="email_address"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      value={userByVK?.email_address}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Profession"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Profession
                    </label>
                    <TextField
                      id="Profession"
                      name="Profession"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      value={userByVK?.profession}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Institution"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Institution Name
                    </label>
                    <TextField
                      id="Institution_name"
                      name="Institution_name"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      value={userByVK?.institution_name}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={8}>
                    <label
                      htmlFor="address"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Address
                    </label>
                    <TextField
                      id="address"
                      name="address"
                      label=""
                      variant="outlined"
                      className="dataentry_input_opinion"
                      size="small"
                      value={userByVK?.address}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="City"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      City
                    </label>
                    <TextField
                      id="city"
                      name="city"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      value={userByVK?.city}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="state"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      State
                    </label>
                    <TextField
                      id="state"
                      name="state"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      value={userByVK?.state}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Pincode"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Pin Code
                    </label>
                    <TextField
                      id="pincode"
                      name="pincode"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      value={userByVK?.pincode}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Pincode"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Selected Plan
                    </label>
                    <TextField
                      id="plan_name"
                      name="plan_name"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      value={userByVK?.plan_name}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Pincode"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Total Amount
                    </label>
                    <TextField
                      id="price"
                      name="price"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      value={userByVK?.price}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Pincode"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Paid Amount
                    </label>
                    <TextField
                      id="paid"
                      name="paid"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      value={userByVK?.paid}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Pincode"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Coupon
                    </label>
                    <TextField
                      id="Coupon2"
                      name="Coupon2"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      value={userByVK?.Coupon2}
                      disabled
                    />
                  </Grid>
                  {userByVK?.s3_filename ? (
                    <Grid item xs={12} sm={8}>
                      <label
                        htmlFor="state"
                        className="dataentry_label"
                        style={{ display: "block" }}
                      >
                        Download Document
                      </label>
                      <div className="verifyAccount-img">
                        <List className="verifyAccount-list">
                          <ListItem className="verifyAccount-listitem">
                            <Grid container>
                              <Grid item xs={12} sm={10}>
                                {userByVK?.s3_filename}
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <ListItemIcon className="file-align">
                                  <IconButton
                                    aria-label="download"
                                    onClick={(e) =>
                                      downloadRep(e, userByVK?.s3_filename)
                                    }
                                  >
                                    <img
                                      src={Download}
                                      className="DownLoad-img"
                                    />
                                  </IconButton>
                                </ListItemIcon>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </List>
                      </div>
                    </Grid>
                  ) : null}
                </Grid>

                <div className="formSection">
                  <Grid container>
                    <Grid className="continue">
                      <Button
                        variant="contained"
                        className="purple-add"
                        type="submit"
                        onClick={ApproveUser}
                        color="primary"
                      >
                        <span>Approve</span>
                      </Button>
                    </Grid>
                    <Grid className="continue">
                      <Button
                        variant="contained"
                        className="purple-add"
                        type="submit"
                        onClick={RejectUser}
                      >
                        <span>Reject</span>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card>
          </Box>
          <Grid className="copy-posi">
            <p className="Copyright">
              Copyright © 2012-2024 Avyantra Health Technologies. All Rights
              Reserved
            </p>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default VerifyAccount;
