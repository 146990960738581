import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Grid } from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDownCircleOutlined";
import "../../styles/header.scss";
import Popover from "@mui/material/Popover";
import Logo from "../../assets/images/logo.png";
import { TextField, InputAdornment, SvgIcon } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { downloadReport } from "../../../dataEntry/reducers/patientDataThunk";
import { Buffer } from "buffer";

import {
  logindetails,
  getbranchdetailsbyuserid,
  updateGlobalSearch,
  loginDetailsByAdmin,
  getSubscriptionStatus,
} from "../../reducers/userThunk";
import { getUserDetails } from "../../../Profile/reducers/profileDataThunk";

//import { loginDetailsByAdmin } from "../../../login/reducers/loginThunk";

const Search = styled("div")({
  position: "relative",
  marginLeft: 0,
  width: "50%",
});

const SearchIconWrapper = styled("div")({
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledInputBase = styled(InputBase)({
  color: "inherit",
  width: "100%",
});

export default function PrimarySearchAppBar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const branch = useSelector((state) => state.branch);
  const Profile = useSelector((state) => state.Profile);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [currentRoute, setCurrentRoute] = React.useState();
  const [searchString, setSearchString] = React.useState();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const username =
    user.userProfile.institution_alias_name || user.userProfile.hospital_name;
  const userId = user?.userProfile?.user_id;
  const [profilePic, setProfilePic] = useState("/broken-image.jpg");
  const [profileData, setProfileData] = useState(Profile.profileDetail);
  const subScription = user.subScriptionStatus?.isSubscribed;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const Adminprofile = (event) => {
    navigate("/AdminProfile");
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const [userHeader, setUserHeader] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setUserHeader(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setUserHeader(null);
  };

  const open = Boolean(userHeader);

  useEffect(() => {
    if (userId) {
      const req = { userId };
      dispatch(getUserDetails(req)).then((resdata) => {});
    }
  }, [userId]);

  useEffect(() => {
    dispatch(getSubscriptionStatus());
  }, [userId]);

  useEffect(() => {
    if (Profile.profileDetail) setProfileData(Profile.profileDetail);
  }, [Profile]);

  const encode = async (data) => {
    let buf = Buffer.from(data);
    let base64 = buf.toString("base64");
    return base64;
  };

  useEffect(async () => {
    if (profileData.profile_pic) {
      dispatch(downloadReport(profileData.profile_pic)).then(
        async (resdata) => {
          // console.log("resdata", resdata);
          setProfilePic(
            `data:image/jpeg;base64,${await encode(resdata.payload.Body)}`
          );
        }
      );
    }
  }, [profileData.profile_pic]);

  useEffect(() => {
    if (location && location.pathname) {
      switch (location.pathname) {
        case "/Dashboard":
          setCurrentRoute("Dashboard");
          break;
        case "/admin":
          setCurrentRoute("Hospital Management");
          break;
        case "/AdminListing":
          setCurrentRoute("User Management");
          break;
        case "/ReferralDoctor":
          setCurrentRoute("Referral Management");
          break;
        case "/NewBMRN":
          setCurrentRoute("Data Entry");
          break;
        case "/AddBMRN":
          setCurrentRoute("Data Entry");
          break;
        case "/dataentry":
          setCurrentRoute("Data Entry");
          break;
        case "/DoctorOpinion":
          setCurrentRoute("Data Entry");
          break;
        case "/Reports":
          setCurrentRoute("Reports");
          break;
        case "/AdminProfile":
          setCurrentRoute("User Profile");
          break;
        default:
          setCurrentRoute("Dashboard");
      }
    }

    if (location && location.pathname !== "/Dashboard") {
      dispatch(updateGlobalSearch(""));
      setSearchString("");
    }
  }, [location.pathname]);

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const updateSearchString = (e) => {
    setSearchString(e.target.value);
  };

  const performSearch = (e) => {
    e.preventDefault();
    dispatch(updateGlobalSearch(searchString));
    setTimeout(() => {
      navigate("/Dashboard");
    });
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const access_token = sessionStorage.getItem("access_token");
    const token = access_token ? access_token : "";
    const user = sessionStorage.getItem("user");
    const username = user ? JSON.parse(user).username : "";
    const user_id = user ? JSON.parse(user).user_id : "";
    const is_admin = user ? JSON.parse(user).is_admin : false;

    if (token) {
      // console.log('userdetails:',user_id)

      if (is_admin) {
        const req = { user_id };
        dispatch(loginDetailsByAdmin(req))
          .unwrap()
          .then((resdata) => {});
      } else {
        const req = { user_id };
        dispatch(logindetails(req))
          .unwrap()
          .then((resdata) => {
            const hospital_id = resdata.response.hospital_id;
            const req = { user_id };
            dispatch(getbranchdetailsbyuserid(req))
              .unwrap()
              .then((resdata) => {
                const userDetails = JSON.parse(user);
                userDetails.branch_id = resdata.response.map_user_branch_id;
                sessionStorage.setItem("user", JSON.stringify(userDetails));
              });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      window.location = "/login";
    }
  }, []);

  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar elevation={0} position="static" id="header" className="colored">
        <Toolbar>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="Data_Entry">{currentRoute}</div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="Data_Entry">{username}</div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={7}>
                      <Box sx={{ maxWidth: 600 }}>
                        <form onSubmit={performSearch}>
                          <TextField
                            fullWidth
                            size="small"
                            className="dataentry_input"
                            onChange={updateSearchString}
                            value={searchString}
                            disabled={!subScription}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SvgIcon fontSize="small" color="action">
                                    <SearchIcon />
                                  </SvgIcon>
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Search BMR number or Mother's name"
                            style={{
                              border: "1px solid #6572E4",
                              borderRadius: "5px",
                              opacity: "50%",
                            }}
                          />
                        </form>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={2} id="profilename">
                      <Box sx={{ display: { xs: "none", md: "flex" } }}>
                        <IconButton
                          size="large"
                          edge="end"
                          aria-label="account of current user"
                          aria-controls={menuId}
                          aria-haspopup="true"
                          onClick={handleProfileMenuOpen}
                          color="inherit"
                        >
                          {/* <Avatar /> */}
                          <Avatar
                            src={profilePic}
                            sx={{ width: 30, height: 30 }}
                          />
                          {/* <p
                            className="username header-userName"
                            onClick={Adminprofile}
                          >
                            {profileData?.user_name}
                          </p> */}
                          <p
                            className="username header-userName"
                            aria-owns={open ? "mouse-over-popover" : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                            onClick={Adminprofile}
                          >
                            {profileData?.user_name}
                          </p>
                          {/* <Popover
                            id="mouse-over-popover"
                            sx={{
                              pointerEvents: "none",
                            }}
                            open={open}
                            anchorEl={userHeader}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: 'right',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                          >
                            <p sx={{ p: 1 }}>
                              {profileData?.user_name}
                            </p>
                          </Popover> */}
                        </IconButton>
                      </Box>
                      <Box sx={{ display: { xs: "flex", md: "none" } }}>
                        <IconButton
                          size="large"
                          aria-label="show more"
                          aria-controls={mobileMenuId}
                          aria-haspopup="true"
                          onClick={handleMobileMenuOpen}
                          color="inherit"
                        >
                          <MoreIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
