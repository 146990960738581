import { createSlice } from "@reduxjs/toolkit";
import * as SignupThunk from "./signupDataThunk";
import { initialState } from "./signupinitialstate";

const signupSlice = createSlice({
  name: "signup",
  initialState,
  extraReducers: {
    [SignupThunk.setFormTab.fulfilled]: (state, action) => {
      state.formTab = action.payload;
    },
    [SignupThunk.accountcreation.fulfilled]: (state, action) => {
      state.accountcreation = action.payload.response;

      if (action.payload.status == "200") {
        state.showSubScription = true;
        state.isacccreate = true;
      } else {
        state.showSubScription = false;
        state.isacccreate = false;
      }
    },
    [SignupThunk.subscriptionPopup.fulfilled]: (state, action) => {
      state.formTab = 2;
      state.showSubScription = false;
      state.isacccreate = true;
    },
    [SignupThunk.multiPartFileUpload.fulfilled]: (state, action) => {
      state = action.payload.response;
    },

    [SignupThunk.approveUser.fulfilled]: (state, action) => {
      state = action.payload.response;
    },

    [SignupThunk.rejectUser.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [SignupThunk.VerifyRefDr.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [SignupThunk.getUserDetailsByVerificationKey.fulfilled]: (
      state,
      action
    ) => {
      state.userByVerificationKey = action.payload.response;
    },
    [SignupThunk.adminVerificationKey.fulfilled]: (state, action) => {
      state.adminVerification = action.payload.response;
    },

    [SignupThunk.AddAdditionalinformation.fulfilled]: (state, action) => {
      state.addiinformation = action.payload.response;
      state.isaddinfo = true;
      state.formTab = 3;
    },

    [SignupThunk.getusertypeDetails.fulfilled]: (state, action) => {
      state.userTypeList = action.payload.response;
    },

    [SignupThunk.getSubscriptionPlans.fulfilled]: (state, action) => {
      state.subscriptionPlans = action.payload.response;
    },

    [SignupThunk.getActivePlans.fulfilled]: (state, action) => {
      state.ActivePlans = action.payload.response;
    },
    [SignupThunk.idverification.fulfilled]: (state, action) => {
      state.formTab = 3;
    },
    [SignupThunk.termsconditions.fulfilled]: (state, action) => {
      state.formTab = action.payload;
    },

    [SignupThunk.BacktoAccCreation.fulfilled]: (state, action) => {
      state.formTab = 1;
    },
    [SignupThunk.BacktoAdddiInfo.fulfilled]: (state, action) => {
      state.formTab = 2;
    },

    [SignupThunk.isacccreate.fulfilled]: (state, action) => {
      state.isacccreate = action.payload;
    },

    [SignupThunk.isaddinfo.fulfilled]: (state, action) => {
      state.isaddinfo = action.payload;
    },

    [SignupThunk.applyCoupon.fulfilled]: (state, action) => {
      state.applyCoupon = action.payload.response;
    },

    [SignupThunk.initiatePayment.fulfilled]: (state, action) => {
      state.initiatePayment = action.payload.response;
    },
  },
});

export default signupSlice.reducer;
