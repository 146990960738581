import { createAsyncThunk } from "@reduxjs/toolkit";
import PatientDataService from "../../api/apiCommonService";

/* export const login = createAsyncThunk(
  "user/login",
  async (formData) => {
    // console.log("inside patient thunk");
    // console.log(formData)
    return formData;
  }
); */

export const logout = createAsyncThunk("user/logout", async (res) => {
  sessionStorage.removeItem(
    "access_token",
    JSON.stringify(res.data.response.access_token)
  );
});

export const login = createAsyncThunk("user/login", async (formData) => {
  const res = await PatientDataService.login(formData);
  return res.data;
});

export const loginResendOTP = createAsyncThunk(
  "user/loginResendOTP",
  async (req) => {
    const res = await PatientDataService.LoginResendOTP(req);
    return res.data;
  }
);
export const verifyLoginOtp = createAsyncThunk(
  "user/verifyLoginOtp",
  async (req) => {
    const res = await PatientDataService.verifyLoginOtp(req);
    if (
      res &&
      res.data &&
      res.data.response &&
      res.data.response.access_token
    ) {
      sessionStorage.setItem("access_token", res.data.response.access_token);
      sessionStorage.setItem("user", JSON.stringify(res.data.response));
    }
    return res.data;
  }
);

export const logindetailsget = createAsyncThunk(
  "user/logindetail",
  async (req) => {
    const res = await PatientDataService.logindetailsget(req);
    return res.data;
  }
);

export const loginDetailsByAdmin = createAsyncThunk(
  "user/loginDetailsByAdmin",
  async (req) => {
    const res = await PatientDataService.loginDetailsByAdmin(req);
    return res.data;
  }
);
export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (ForgotPwdForm) => {
    const res = await PatientDataService.forgotPassword(ForgotPwdForm);
    return res.data;
  }
);
export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (newFormData) => {
    const res = await PatientDataService.resetPassword(newFormData);
    return res.data;
  }
);

export const setErrorMessage = createAsyncThunk(
  "errorMessage",
  async (data) => data
);

export const setSuccessMessage = createAsyncThunk(
  "successMessage",
  async (data) => data
);
