import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from "@mui/material";

import AdminMap from "./AdminMap";
import AddAdmin from "./AddAdmin";
import "../../../dataEntry/styles/DataEntry.scss";

import { admindata } from "../../reducers/admininitialstate";

import {
  addAdminDetails,
  getmapedAdminDetail,
  getAdminDetail,
  getBranchDetail,
  updateAdminDetails,
  setEditAdminData,
} from "../../reducers/adminThunk";

const AdminScreen = (props) => {
  const admin = useSelector((state) => state.admin);
  const login = useSelector((state) => state.login);
  const user = useSelector((state) => state.user);

  const [addAdminData, setAddAdminData] = useState(admin.admindata);
  const [adminMap, setAdminMap] = useState([]);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const hospital_id = user.userProfile.hospital_id;
  useEffect(() => {
    if (props.open) setAddAdminData(admin.admindata);
  }, [admin.admindata, props.open]);

  const handleClose = () => {
    setOpen(false);
    dispatch(setEditAdminData({}));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    dispatch(getAdminDetail(hospital_id));
    dispatch(getBranchDetail(hospital_id));
    dispatch(getmapedAdminDetail(hospital_id));
  }, []);

  useEffect(() => {
    const { branchList, adminMaping, adminList } = admin;
    const mapping = [];
    if (adminList && adminList.length > 0) {
      for (const admin of adminList) {
        mapping.push({ ...admin, branch: [], selectedBranches: [] });
      }
    }

    if (branchList && branchList.length > 0) {
      for (const map of mapping) {
        map["branches"] = branchList;
      }
    }
    if (adminMaping && adminMaping.length > 0) {
      for (const map of mapping) {
        map["selectedBranches"] = adminMaping
          .filter((bm) => bm.user_id === map.id)
          .map((bm) => bm.branch_id);
      }
    }
    setAdminMap(mapping);
  }, [admin.branchList, admin.adminMaping, admin.adminList]);

  const editAdmin = (record) => {
    setOpen(true);
    dispatch(setEditAdminData(record));
  };

  return (
    <React.Fragment>
      <div className="branchcontent">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Card variant="none">
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={10}>
                    <h4 className="branch-header">Admins</h4>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {adminMap.map((bm) => (
                    <AdminMap mapping={bm} editAdmin={editAdmin} />
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default AdminScreen;
