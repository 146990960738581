import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import moment from "moment";

import {
  Grid,
  Card,
  CardContent,
  Button,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
} from "@mui/material";

import { Bar } from "react-chartjs-2";
import { Chart } from "react-google-charts";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import "../../dataEntry/styles/DataEntry.scss";
import { Box } from "@mui/system";

import { scoreTrend } from "../../Dashboard/reducers/Dashboardthunk";

const chartOptions = {
  bar: { groupWidth: "30%" },
  hAxis: {
    title: "Date",
  },
  series: {
    0: {
      visibleInLegend: false,
    },
  },
  vAxis: {
    title: "Score",
    viewWindow: {
      min: 0,
    },
    gridlines: {
      color: "none",
    },
  },
};

const TrendView = (props) => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const dbbranchdata = useSelector((state) => state.dashboard);

  const hospitalId = user.userProfile.hospital_id;
  const hospitalBranchId = user.branchdata.branch_id;
  // console.log("gggggggggggggggggggggg", props.selectedPatient);
  const baby_medical_record_number =
    props.selectedPatient?.baby_medical_record_number;
  const mother_name = props.selectedPatient?.mother_name;
  const studyId =
    props && props.selectedPatient?.studyId
      ? props.selectedPatient?.studyId
      : patient.bmrndata?.study_id;

  const [TrendView, setTrendView] = useState([]);
  const [chartsData, setChartsData] = useState([]);

  const [bmrnOptions, setBmrnOptions] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dbbranchdata.scoreTrend && dbbranchdata.scoreTrend.length) {
      const data = dbbranchdata.scoreTrend.map((reading) => {
        return { ...reading };
      });
      setBmrnOptions(data.concat([]));
    }
  }, [dbbranchdata.scoreTrend]);

  useEffect(() => {
    dispatch(scoreTrend(studyId))
      .unwrap()
      .then((resdata1) => {
        const trendscore = resdata1.response;
        setTrendView(trendscore);
      });
  }, []);

  React.useEffect(() => {
    if (bmrnOptions && bmrnOptions.length) {
      const data = [
        ["Date", "Score", { role: "style" }, { role: "annotation" }],
      ];
      bmrnOptions.map((reading) => {
        data.push([
          moment(reading.createdAt).format("DD-MM-YYYY"),
          //"R" + reading.reading,
          reading.score,
          getColorCode(reading.score),
          `${reading.score} (R${reading.reading})`,
        ]);
      });

      setChartsData(data);
    }
  }, [bmrnOptions]);

  const closeDialog = (e) => {
    e.preventDefault();
    props.onClose(e);
  };

  const getColorCode = (score) => {
    // console.log("inside function get color code ");
    // console.log("The score is", score);
    if (score >= 0 && score <= 5) {
      return "#74D976";
    }
    if (score > 5 && score <= 8) {
      return "#F9D372";
    }
    if (score > 8 && score <= 10) {
      return "#F56D6D";
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
        open={props.open}
        onClose={props.onClose}
      >
        <DialogTitle style={{ borderBottom: "1px solid #000" }}>
          <Link
            to="/"
            className="FIle-left"
            onClick={closeDialog}
            style={{ marginRight: "20px" }}
          >
            <img
              src={Back}
              className="file-img-left"
              alt=""
              style={{ width: "12px" }}
            />
          </Link>
          <span>Score Trend</span>
          <Link to="/" className="FIle-right-extreme" onClick={closeDialog}>
            <img src={Close} className="file-img-right-extreme" alt="" />
          </Link>
        </DialogTitle>
        <DialogContent>
          <Box>
            <form id="bmrn-form">
              <Card variant="none">
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <p className="readingsummary_name">
                        BMRN
                        <span className="summary_data">
                          {baby_medical_record_number}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p className="readingsummary_name">
                        Mother's name
                        <span className="summary_data">{mother_name}</span>
                      </p>
                    </Grid>
                  </Grid>
                  <TableContainer>
                    <Table aria-label="simple table" className="Table">
                      <TableHead className="TableHead">
                        <TableRow className="TableRow">
                          <TableCell align="center" id="table-cell">
                            Reading
                          </TableCell>
                          <TableCell align="center" id="table-cell">
                            Date
                          </TableCell>
                          <TableCell align="center" id="table-cell">
                            Time
                          </TableCell>
                          <TableCell align="center" id="table-cell">
                            Score
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bmrnOptions.map((sheet) => (
                          <TableRow>
                            <TableCell align="center" id="table-cell">
                              R{sheet.reading}
                            </TableCell>
                            <TableCell align="center" id="table-cell">
                              {moment(sheet.createdAt).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell align="center" id="table-cell">
                              {moment(sheet.createdAt).format("hh:mm a")}
                            </TableCell>
                            <TableCell align="center" id="table-cell">
                              {sheet.score ? sheet.score : "-"}
                              {/*  {bmrnOptions.score} */}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Chart
                    chartType="ColumnChart"
                    options={chartOptions}
                    width="100%"
                    height="300px"
                    data={chartsData}
                  />
                </CardContent>
              </Card>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default TrendView;
