import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import patientReducer from "../dataEntry/reducers/patientSlice";
import userSlice from "../common/reducers/userSlice";
import branch from "../Admin/reducers/branchSlice";
import ward from "../Admin/reducers/wardSlice";
import loginSlice from "../login/reducers/loginSlice";
import logger from "redux-logger";
import admin from "../Admin/reducers/adminSlice";
import signupSlice from "../signUp/reducers/signupSlice";
import Dashboardslice from "../Dashboard/reducers/Dashboardslice";
import profileSlice from "../Profile/reducers/profileSlice";
import ReportsSlice from "../ReportsDashboard/reducers/ReportsSlice";
/*const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

con*/

export const store = configureStore({
  reducer: {
    patient: patientReducer,
    user: userSlice,
    branch: branch,
    ward: ward,
    login: loginSlice,
    admin: admin,
    signup: signupSlice,
    dashboard: Dashboardslice,
    Profile: profileSlice,
    Reports: ReportsSlice,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
