import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  Snackbar,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Download from "../../common/assets/images/Download.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import html2canvas from "html2canvas";
const pdfConverter = require("jspdf");
import { jsPDF } from "jspdf";
import "../../poppins/Poppins-Regular-normal";
import "../../poppins/Poppins-Bold-bold";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  registerables,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import { Buffer } from "buffer";
import { GetFinalVsPreliminaryData } from "../reducers/ReportsDataThunk";

import "../../dataEntry/styles/DataEntry.scss";
import NotificationPdf from "./NotificationPdf";
import ChartLegends from "./ChartLegends";

const FinalVsPreliminary = () => {
  ChartJS.register(...registerables);
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const hospital_id = user.userProfile.hospital_id;
  const hospital_name = user.userProfile.hospital_name;
  const branch = useSelector((state) => state.Reports?.SelectedBranchdata);
  const [rangeType, setRangeType] = React.useState("Select Dates");
  const [BarGraph, setBarGraphData] = React.useState();
  const [chartData, setChartData] = React.useState();
  const [ParameterType, setParameterType] = React.useState("sepsis");
  const [fromDate, setFromDate] = React.useState(
    moment().subtract(1, "month").format()
  );
  const [toDate, setToDate] = React.useState(moment().format());
  const [fromYear, setFromYear] = React.useState(
    moment().subtract(1, "year").format()
  );
  const [toYear, setToYear] = React.useState(moment().format());
  const [month, setMonth] = React.useState(moment().format());
  const [notificationData, setNotificationData] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (chartData && chartData.length) {
      const labels = [],
        finalDiagnosisYes = [],
        finalDiagnosisNo = [],
        preliminaryDiagnosisYes = [],
        preliminaryDiagnosisNo = [];

      chartData.map((record) => {
        labels.push(record.label);
        finalDiagnosisYes.push(record.finalDiagnosisYes);
        finalDiagnosisNo.push(record.finalDiagnosisNo);
        preliminaryDiagnosisYes.push(record.preliminaryDiagnosisYes);
        preliminaryDiagnosisNo.push(record.preliminaryDiagnosisNo);
      });

      const BarGraphData = {
        labels,
        datasets: [
          {
            label: "Final Diagnosis Yes",
            backgroundColor: "#E9704A",
            data: finalDiagnosisYes,
            showLegend: true,
            role: "annotation",
          },
          {
            label: "Final Diagnosis NO",
            backgroundColor: "#40803B",
            data: finalDiagnosisNo,
            showLegend: true,
            role: "annotation",
          },
          {
            label: "Preliminary Diagnosis Yes",
            backgroundColor: "#FFB342",
            data: preliminaryDiagnosisYes,
            showLegend: true,
            role: "annotation",
          },
          {
            label: "Preliminary Diagnosis No",
            backgroundColor: "#74D976",
            data: preliminaryDiagnosisNo,
            showLegend: true,
            role: "annotation",
          },
        ],
      };
      setBarGraphData(BarGraphData);
    }
  }, [chartData]);

  useEffect(() => {
    getReports();
  }, [
    rangeType,
    fromDate,
    ParameterType,
    toDate,
    month,
    fromYear,
    toYear,
    branch?.hospital_branch_id,
  ]);

  const getReports = () => {
    setIsLoading(true);
    let params;

    switch (rangeType) {
      case "Select Dates":
        params = {
          range: "custom",
          start: moment(fromDate).format("MM-DD-YYYY"),
          end: moment(toDate).format("MM-DD-YYYY"),
        };
        break;
      case "Monthly":
        params = {
          range: "monthly",
          start: moment(month).format("MM-DD-YYYY"),
        };
        break;
      case "Quarterly":
        params = {
          range: "quarterly",
        };
        break;
      case "Yearly":
        params = {
          range: "yearly",
          start: moment(fromYear).format("MM-DD-YYYY"),
          end: moment(toYear).format("MM-DD-YYYY"),
        };
        break;
    }

    const req = {
      hospital_id,
      branch_id: branch?.hospital_branch_id,
      params,
      ParameterType,
    };
    dispatch(GetFinalVsPreliminaryData(req))
      .unwrap()
      .then((resdata) => {
        setChartData(resdata.response.graphData);
        setIsLoading(false);
      });
  };

  const div2PDF = async (e) => {
    e.preventDefault();

    let lineChart =
      window.document.getElementsByClassName("genderLineChart")[0];
    const lineChartCanvas = await html2canvas(lineChart);
    const lineChartImage = lineChartCanvas.toDataURL("image/png");

    const pdf = new jsPDF("l", "pt", "a4");
    pdf.setFont("Poppins", "normal");
    pdf.setFillColor(244, 131, 81);
    pdf.rect(50, 90, 740, 35, "F");

    pdf.setFont("Poppins-Bold", "bold");
    pdf.setFontSize(20);
    const heading = "Final Vs Preliminary Diagnosis Report";
    pdf.setTextColor(255, 255, 255);
    pdf.text(heading, 255, 115);

    var avyantraImage = new Image();
    avyantraImage.src = "./assets/avyantra.jpg";
    pdf.addImage(avyantraImage, "jpg", 10, 10, 60, 13);

    pdf.setFontSize(10);
    pdf.setTextColor(135, 135, 135);
    pdf.text(`Generated On: ${moment().format("DD-MM-YYYY")}`, 700, 20);

    var prescoImage = new Image();
    prescoImage.src = "./assets/presco.png";
    pdf.addImage(prescoImage, "png", 380, 30, 53.5, 14.12);
    pdf.text("Neonatal Sepsis Prediction Platform", 330, 60);

    pdf.setFontSize(15);
    pdf.setTextColor(135, 135, 135);

    let rangeValue;
    switch (rangeType) {
      case "Select Dates":
        rangeValue =
          moment(fromDate).format("DD-MM-YY") +
          " To " +
          moment(toDate).format("DD-MM-YY");
        break;
      case "Yearly":
        rangeValue =
          moment(fromYear).format("YYYY") +
          " To " +
          moment(toYear).format("YYYY");
        break;
      case "Monthly":
        rangeValue =
          moment(month).startOf("month").format("DD-MM-YY") +
          " To " +
          moment(month).endOf("month").format("DD-MM-YY");
        break;
      case "Quarterly":
        rangeValue =
          moment().startOf("quarter").format("DD-MM-YY") +
          " To " +
          moment().endOf("quarter").format("DD-MM-YY");
        break;
    }

    // Calculate the width of each label text
    const labelWidths = {
      "Hospital: ":
        (pdf.getStringUnitWidth("Hospital: ") * pdf.internal.getFontSize()) /
        pdf.internal.scaleFactor,
      "Branch: ":
        (pdf.getStringUnitWidth("Branch: ") * pdf.internal.getFontSize()) /
        pdf.internal.scaleFactor,
      "Range: ":
        (pdf.getStringUnitWidth("Range: ") * pdf.internal.getFontSize()) /
        pdf.internal.scaleFactor,
    };

    const totalWidth =
      labelWidths["Hospital: "] +
      labelWidths["Branch: "] +
      labelWidths["Range: "];
    const availableSpace = pdf.internal.pageSize.getWidth() - 110 - totalWidth; // Total width - x-coordinate of first label

    const spaceBetweenLabels = availableSpace / 2;

    const labelX = {
      "Hospital: ": 55,
      "Branch: ": 10 + labelWidths["Hospital: "] + spaceBetweenLabels,
      "Range: ":
        pdf.internal.pageSize.getWidth() -
        labelWidths["Range: "] -
        `${rangeType !== "Yearly" ? 230 : 135}`,
    };

    pdf.setFontSize(15);
    pdf.setFont("Poppins", "normal");
    pdf.text(`Hospital :`, labelX["Hospital: "], 140);
    pdf.text(`Branch :`, labelX["Branch: "], 140);
    pdf.text(`Range :`, labelX["Range: "], 140);

    pdf.setFont("Poppins-Bold", "bold");
    pdf.text(`${hospital_name}`, labelX["Hospital: "] + 75, 140);
    pdf.text(`${branch.branch_name}`, labelX["Branch: "] + 70, 140);
    pdf.text(`${rangeValue}`, labelX["Range: "] + 70, 140);

    pdf.addImage(lineChartImage, "png", 40, 150, 700, 300);
    const blobData = pdf.output("arraybuffer");
    const buffer = Buffer.from(blobData);
    setNotificationData(buffer);
    setDialogOpen(true);
  };

  const lineOptions = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: "end",
        align: "end",
        color: "black",
        font: {
          weight: "bold",
          color: "#000000",
        },
        formatter: function (value) {
          return value; // Display the value on top of the bar
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: `${rangeType !== "Select Dates" ? "Year" : "Date"}`,
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: "Count",
        },
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <React.Fragment>
      <NotificationPdf
        data={notificationData}
        open={dialogOpen}
        action={setDialogOpen}
        title="Final Vs Preliminary"
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div>
          <Card variant="none">
            <CardContent>
              <div>
                <div className="reports_chart_title">
                  {/* <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        margin: "0px auto",
                        textAlign: "center",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Final Vs Preliminary
                    </Grid>
                  </Grid>
                  <hr /> */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      style={{
                        marginTop: 30,
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Final Vs Preliminary
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Grid container>
                        <Grid item xs={12} sm={2}>
                          <label
                            htmlFor="Range"
                            className="dataentry_label1"
                            style={{ display: "block" }}
                          >
                            Reading Type
                          </label>
                          <Select
                            labelId="ParameterType"
                            id="ParameterType"
                            name="ParameterType"
                            label=""
                            size="small"
                            className="dataentry_input"
                            value={ParameterType}
                            onChange={(e) => {
                              setParameterType(e.target.value);
                            }}
                            style={{
                              fontSize: "14px",
                              padding: "2px 0px",
                              marginTop: 0,
                            }}
                          >
                            <MenuItem value="sepsis">Sepsis</MenuItem>
                            <MenuItem value="meningitis">Meningitis</MenuItem>
                            <MenuItem value="seizures">Seizures</MenuItem>
                            <MenuItem value="rds">RDS</MenuItem>
                            <MenuItem value="ttnb">TTNB</MenuItem>
                            <MenuItem value="jaundice">Jaundice</MenuItem>
                            <MenuItem value="lbw">LBW</MenuItem>
                            <MenuItem value="lga_sga_aga">LGA SGA AGA</MenuItem>
                            <MenuItem value="anemia">Anemia</MenuItem>
                            <MenuItem value="congenital_heart_disease">
                              Congenital Heart Disease
                            </MenuItem>
                            <MenuItem value="hypoglycemia">
                              Hypoglycemia
                            </MenuItem>
                            <MenuItem value="hypocalcemia">
                              Hypocalcemia
                            </MenuItem>
                            <MenuItem value="gastroenteritis">
                              Gastroenteritis
                            </MenuItem>
                            <MenuItem value="shock">Shock</MenuItem>
                            <MenuItem value="feeding_intolerence">
                              Feeding Intolerence
                            </MenuItem>
                            <MenuItem value="septic_arthritis">
                              Septic Arthritis
                            </MenuItem>
                            <MenuItem value="endocarditis">
                              Endocarditis
                            </MenuItem>
                            <MenuItem value="peritonitis">Peritonitis</MenuItem>
                            <MenuItem value="soft_tissue_abscess">
                              Soft Tissue Abscess
                            </MenuItem>
                            <MenuItem value="coagulopathy">
                              Coagulopathy
                            </MenuItem>
                            <MenuItem value="skin_pustules">
                              Skin Pustules
                            </MenuItem>
                            <MenuItem value="uti">UTI</MenuItem>
                            <MenuItem value="umblical_sepsis">
                              Umblical Sepsis
                            </MenuItem>
                            <MenuItem value="bleeding_manifestation">
                              Bleeding Manifestation
                            </MenuItem>
                            <MenuItem value="asphyxia">Asphyxia</MenuItem>
                            <MenuItem value="pneumonia">Pneumonia</MenuItem>
                            <MenuItem value="central_peripheral_temperature_difference">
                              Central Peripheral Temperature Difference
                            </MenuItem>
                            <MenuItem value="hypoxia">Hypoxia</MenuItem>
                            <MenuItem value="metabolic_acidosis">
                              Metabolic Acidosis
                            </MenuItem>
                            <MenuItem value="eos_los_na">EOS LOS NA</MenuItem>
                            <MenuItem value="pulmonary_hemorrhage">
                              Pulmonary Hemorrhage
                            </MenuItem>
                            <MenuItem value="thrombocytopenia">
                              Thrombocytopenia
                            </MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <label
                            htmlFor="Range"
                            className="dataentry_label1"
                            style={{ display: "block" }}
                          >
                            Range
                          </label>
                          <Select
                            labelId="range"
                            id="range"
                            name="range"
                            label=""
                            size="small"
                            className="dataentry_input"
                            value={rangeType}
                            onChange={(e) => {
                              setRangeType(e.target.value);
                            }}
                            style={{
                              fontSize: "14px",
                              padding: "2px 0px",
                              marginTop: 0,
                            }}
                          >
                            <MenuItem value="Select Dates">
                              Select Dates
                            </MenuItem>
                            <MenuItem value="Yearly">Yearly</MenuItem>
                            <MenuItem value="Monthly">Monthly</MenuItem>
                            <MenuItem value="Quarterly">Quarterly</MenuItem>
                          </Select>
                        </Grid>

                        {rangeType === "Yearly" ? (
                          <>
                            <Grid item xs={12} sm={3}>
                              <label
                                htmlFor="From"
                                className="dataentry_label1"
                                style={{ display: "block" }}
                              >
                                From
                              </label>
                              <DatePicker
                                size="small"
                                id="from_year"
                                name="from_year"
                                views={["year"]}
                                minDate="01-01-2020"
                                maxDate={toYear}
                                value={fromYear}
                                className="dataentry_input"
                                onChange={(date) => {
                                  setFromYear(date["$d"]);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    className="dataentry_input"
                                    helperText={null}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <label
                                htmlFor="To"
                                className="dataentry_label1"
                                style={{ display: "block" }}
                              >
                                To
                              </label>
                              <DatePicker
                                size="small"
                                views={["year"]}
                                id="to_year"
                                name="to_year"
                                value={toYear}
                                minDate={fromYear}
                                maxDate={moment().format()}
                                className="dataentry_input"
                                onChange={(date) => {
                                  setToYear(date["$d"]);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="dataentry_input"
                                    size="small"
                                    helperText={null}
                                  />
                                )}
                              />
                            </Grid>
                          </>
                        ) : null}

                        {rangeType === "Select Dates" ? (
                          <>
                            <Grid item xs={12} sm={3}>
                              <label
                                htmlFor="From"
                                className="dataentry_label1"
                                style={{ display: "block" }}
                              >
                                From
                              </label>
                              <DatePicker
                                id="from_month"
                                size="small"
                                name="from_month"
                                minDate="01-01-2020"
                                maxDate={toDate}
                                value={fromDate}
                                inputFormat="DD-MM-YYYY"
                                className="dataentry_input"
                                onChange={(date) => {
                                  setFromDate(date["$d"]);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    className="dataentry_input"
                                    helperText={null}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <label
                                htmlFor="To"
                                className="dataentry_label1"
                                style={{ display: "block" }}
                              >
                                To
                              </label>
                              <DatePicker
                                id="to_month"
                                name="to_month"
                                size="small"
                                inputFormat="DD-MM-YYYY"
                                minDate={fromDate}
                                maxDate={moment().format()}
                                value={toDate}
                                className="dataentry_input"
                                onChange={(date) => {
                                  setToDate(date["$d"]);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    className="dataentry_input"
                                    helperText={null}
                                  />
                                )}
                              />
                            </Grid>
                          </>
                        ) : null}

                        {rangeType === "Monthly" ? (
                          <>
                            <Grid item xs={12} sm={3}>
                              <label
                                htmlFor="From"
                                className="dataentry_label1"
                                style={{ display: "block" }}
                              >
                                Month
                              </label>
                              <DatePicker
                                id="from_month"
                                name="from_month"
                                size="small"
                                className="dataentry_input"
                                inputFormat="DD-MM-YYYY"
                                value={month}
                                minDate="01-01-2020"
                                maxDate={moment().format()}
                                views={["year", "month"]}
                                onChange={(date) => {
                                  setMonth(date["$d"]);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    className="dataentry_input"
                                    helperText={null}
                                  />
                                )}
                              />
                            </Grid>
                          </>
                        ) : null}
                        <Grid item xs={12} sm={2}>
                          <Button
                            variant="contained"
                            size="small"
                            className="report-download-button"
                            disabled={isLoading}
                            onClick={(e) => div2PDF(e)}
                          >
                            Download Report
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <hr className="tag-line-border" />
                {isLoading ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      height: "480px",
                    }}
                  >
                    <Grid sm={1}>
                      <CircularProgress color="success" />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid item xs={12} sm={8} className="genderLineChart">
                      <div className="chart_data">
                        <p className="chart_title">
                          Final Diagnosis vs Preliminary Diagnosis
                        </p>
                        {BarGraph ? (
                          <Bar
                            data={BarGraph}
                            options={lineOptions}
                            className="donut_class1"
                          />
                        ) : null}
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className="genderLineChart"
                      style={{ display: "flex", marginTop: "50px" }}
                    >
                      <Grid container>
                        <Grid sm={12}>
                          <ChartLegends
                            labelOne={"Final Diagnosis Jaundice Positive"}
                            colorOne={"#E9704A"}
                          />
                        </Grid>
                        <Grid sm={12}>
                          <ChartLegends
                            labelTwo={"Final Diagnosis Jaundice Negative"}
                            colorTwo={"#40803B"}
                          />
                        </Grid>
                        <Grid sm={12}>
                          <ChartLegends
                            labelOne={"Preliminary Diagnosis Jaundice Positive"}
                            colorOne={"#FFB342"}
                          />
                        </Grid>
                        <Grid sm={12}>
                          <ChartLegends
                            labelTwo={"Preliminary Diagnosis Jaundice Negative"}
                            colorTwo={"#74D976"}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default FinalVsPreliminary;
