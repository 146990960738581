import React, { useState, useEffect } from "react";
import Branch from "./Branch";
import Ward from "./ward/ward";
import AddWard from "./ward/AddWard";
import RolesList from "./ward/RolesListing";
import ReferralDoctor from "./ReferralDoctor";
import AdminScreen from "./ward/AdminScreen";
import AdminListing from "./ward/AdminListing";
import { Grid, Button, ButtonGroup, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import "../../common/styles/header.scss";

import { useDispatch, useSelector } from "react-redux";

const AdminSteps = [
  { label: "Branches", key: 1 },
  { label: "Wards", key: 2 },
  { label: "Roles", key: 3 },
];

const AdminHeader = (props) => {
  const dispatch = useDispatch();
  const [steps, setSteps] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState(1); // Initialize with the key of "Branches" tab

  useEffect(() => {
    let temp = AdminSteps;
    setSteps(temp);
  }, []); // Empty dependency array to run the effect only once, similar to componentDidMount

  const validateAndUpdateTab = (tabKey) => {
    if (tabKey < 4 && tabKey > 0) {
      setActiveTab(tabKey);
    }
  };

  return (
    <div className="dataoutlet">
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Grid item xs={12} sm={12}>
            <div className="formSection">
              <Card style={{ padding: "0px" }} variant="none">
                <CardContent style={{ padding: "0px" }}>
                  <div style={{ paddingLeft: "0.4em" }}>
                    <Grid container>
                      <Grid className="branch_header_group">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            "& > *": {
                              m: 1,
                            },
                          }}
                        >
                          <ButtonGroup
                            variant="outlined"
                            aria-label="outlined button group"
                          >
                            {steps.map((step) => {
                              return (
                                <Button
                                  className="branch_header_button"
                                  variant={
                                    activeTab === step.key
                                      ? "contained"
                                      : "outlined"
                                  }
                                  onClick={() => validateAndUpdateTab(step.key)}
                                >
                                  {step.label}
                                </Button>
                              );
                            })}
                          </ButtonGroup>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {activeTab < 4 && (
        <Grid item xs={12} sm={12}>
          {activeTab === 1 && <Branch changeTab={validateAndUpdateTab} />}
          {activeTab === 2 && <AddWard />}
          {activeTab === 3 && <RolesList />}
        </Grid>
      )}
    </div>
  );
};

export default AdminHeader;
