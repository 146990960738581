import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import "../../login/styles/login.scss";
import "../../dataEntry/styles/DataEntry.scss";
import {
  subscriptionPopup,
  getActivePlans,
  applyCoupon,
  initiatePayment,
} from "../../signUp/reducers/signupDataThunk";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const ActivePlan = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const [activePlans, setActivePlans] = React.useState([]);
  const [selectedPlan, setSelectPlan] = React.useState();
  const [couponCode, setCouponCode] = React.useState();
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;

  const [paymentPlan, setOpen] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);

  const [discountValue, setDiscountValue] = React.useState(0);
  const tAmount = selectedPlan?.price - discountValue;

  const handleAddFormChange = (event) => {
    const fieldvalue = event.target.value;
    setCouponCode(fieldvalue);
  };

  const submitform = (newState) => () => {
    navigate("/login");
  };
  const handleClose = (newState) => () => {
    setState({ open: true, ...newState });
  };
  const selectPlan = (e, plan) => {
    setSelectPlan(plan);
    setIsActive(!isActive);
  };
  useEffect(() => {
    dispatch(getActivePlans())
      .unwrap()
      .then((resdata) => {
        setActivePlans(resdata.response.plans);
      });
  }, []);

  const HandleAddFormSubmit = (event) => {
    event.preventDefault();

    navigate("/login");

    dispatch(subscriptionPopup());
  };

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setDiscountValue(0);
    setOpen(false);
  };
  const continuePayment = () => {
    setOpen(true);
  };

  const couponApply = () => {
    const req = { couponCode };
    dispatch(applyCoupon(req))
      .unwrap()
      .then(async (resdata) => {
        if (resdata.status == "200") {
          const discount_in_percentage =
            resdata.response.discount_in_percentage;
          const discValue =
            (selectedPlan?.price * discount_in_percentage) / 100;
          setDiscountValue(discValue);

          toast.success("Coupon Applied Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else if (resdata.status == "500") {
          setDiscountValue("0");

          toast.error("Invalid Coupon Code", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const initiatePaymentProcess = () => {
    const req = {
      user_id: user?.userProfile?.user_id,
      plan_id: selectedPlan?.plan_id,
      coupon: couponCode,
    };
    dispatch(initiatePayment(req))
      .unwrap()
      .then(async (resdata) => {
        if (resdata.status == "200") {
          if (resdata.response.redirectUrl) {
            window.location.replace(resdata.response.redirectUrl);
          } else {
            toast.success("Your Subscription completed Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          }
        }
      });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Grid container>
        <form id="acc-creation-form" onSubmit={initiatePayment}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <p className="identity_verify" style={{ textAlign: "center" }}>
                Our Subscription Plans
              </p>
              <hr />
            </Grid>
            <Grid>
              <p>
                Subscribe to record entries and make use of the full platform
              </p>
            </Grid>
            <Grid container spacing={2} style={{ justifyContent: "center" }}>
              {activePlans.map((plan) => (
                <>
                  {plan.price != 0 && (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      onClick={(e) => selectPlan(e, plan)}
                      style={{ cursor: "pointer" }}
                    >
                      <Card
                        variant="outlined"
                        style={{ borderRadius: "10px", border: "none" }}
                      >
                        <CardContent
                          className={`${
                            selectedPlan &&
                            selectedPlan?.plan_id == plan.plan_id
                              ? "activePlan"
                              : "inactivePlan"
                          }`}
                        >
                          {plan?.price == 150 ? (
                            <p className="StandardPlan"></p>
                          ) : (
                            ""
                          )}
                          {plan?.price == 500 ? (
                            <p className="PremiumPlan"></p>
                          ) : (
                            ""
                          )}
                          <div className="sub_align">
                            {plan?.price == 150 ? (
                              <p className="standardPlanPrice">
                                Rs. {plan.price}
                                <br /> For {plan.duration_in_days} Days
                              </p>
                            ) : (
                              ""
                            )}
                            {plan?.price == 500 ? (
                              <p className="premiumPlanPrice">
                                Rs. {plan.price}
                                <br /> For {plan.duration_in_days} Days
                              </p>
                            ) : (
                              ""
                            )}
                            {plan?.price == 150 ? (
                              <p className="standard_plan">{plan.name}</p>
                            ) : (
                              ""
                            )}
                            {plan?.price == 500 ? (
                              <p className="premium_plan">{plan.name}</p>
                            ) : (
                              ""
                            )}
                            {plan?.price != 0 && (
                              <ul>
                                <li className="services-list">Dashboard</li>
                                <li className="services-list">
                                  Hospital Management
                                </li>
                                <li className="services-list">
                                  User Management
                                </li>
                                <li className="services-list">
                                  Referral Management
                                </li>
                                <li className="services-list">Data Entry</li>
                                <li className="services-list">
                                  Reports Access
                                </li>
                              </ul>
                            )}
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </Grid>
          <div
            className="formSection"
            style={{ marginBottom: "20px", marginTop: "10px" }}
          >
            <Grid container style={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={3} className="continue">
                <Button
                  variant="contained"
                  className="purple-add"
                  onClick={continuePayment}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>

        <Dialog open={paymentPlan} onClose={closeDialog}>
          <DialogTitle>
            <Link
              className="FIle-left"
              onClick={closeDialog}
              style={{ marginRight: "20px" }}
            >
              <img
                src={Back}
                className="file-img-left"
                alt=""
                style={{ width: "12px" }}
              />
            </Link>
            Payment Initiation
            <Link className="FIle-right-extreme" onClick={closeDialog}>
              <img src={Close} style={{ paddingLeft: "200px" }} alt="" />
            </Link>
          </DialogTitle>
          <DialogContent>
            <Grid item xs={12} sm={12}>
              <Card variant="outlined">
                <CardContent className="abcd">
                  <div className="sub_align1">
                    <p className="price">
                      Plan Name :
                      <span style={{ float: "right" }}>
                        {selectedPlan?.name}
                      </span>
                    </p>
                    <p className="price">
                      Price :
                      <span style={{ float: "right" }}>
                        Rs. {selectedPlan?.price}
                      </span>
                    </p>

                    <p className="price">
                      Duration :
                      <span style={{ float: "right" }}>
                        {selectedPlan?.duration_in_days} Days
                      </span>
                    </p>
                    <p className="price" style={{ display: "flex" }}>
                      <span style={{ marginTop: "10px" }}>Coupon :</span>
                      <span>
                        <TextField
                          id="couponCode"
                          name="couponCode"
                          className={"dataentry_input"}
                          size="small"
                          onChange={(e) => {
                            handleAddFormChange(e);
                          }}
                          style={{ width: "70%", float: "right" }}
                        />
                      </span>
                      <span style={{ marginTop: "5px", paddingLeft: "25px" }}>
                        <Button
                          variant="contained"
                          className="purple-add"
                          onClick={couponApply}
                        >
                          Apply
                        </Button>
                      </span>
                    </p>
                    <p className="price">
                      Discount :
                      <span style={{ float: "right" }}>{discountValue}</span>
                    </p>

                    <p className="price">
                      Total Amount :
                      <span style={{ float: "right" }}>{tAmount}</span>
                    </p>
                  </div>

                  <div className="formSection" style={{ marginBottom: "20px" }}>
                    <Grid container>
                      <Grid className="continue">
                        <Button
                          variant="contained"
                          type="submit"
                          onClick={closeDialog}
                          color="primary"
                        >
                          <span>Back</span>
                        </Button>
                      </Grid>
                      <Grid className="continue">
                        <Button
                          variant="contained"
                          className="purple-add"
                          onClick={initiatePaymentProcess}
                        >
                          Continue
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
};

export default ActivePlan;
