import React from "react";
import { Grid } from "@mui/material";
import { connect, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useIsMobile from "useismobile";
import Header from "./header/Header";
import Footer from "./Footer";
import Layout from "./Layout";
import "../styles/header.scss";

const Home = (props) => {
  const isMobile = useIsMobile();
  const location = useLocation();

  const user = useSelector((state) => state.user);
  const username = user.userProfile.username;

  const access_token = sessionStorage.getItem("access_token");
  const token = access_token ? access_token : "";

  if (location.pathname.includes("login") && username) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else if (location.pathname.includes("login") && !username) {
    return (
      <React.Fragment>
        <Outlet />
      </React.Fragment>
    );
  } else if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={1}
                style={{ maxWidth: "105px", background: "#ffffff" }}
              >
                <div className="Navigation">
                  <Layout />
                </div>
              </Grid>
              <Grid item xs={12} sm={11}>
                <Header />
                <div className="outletContainer">
                  <Outlet />
                </div>
                {/* <Footer /> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
