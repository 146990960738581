import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import File from "../../common/assets/images/File.svg";
import ImageView from "../../Dashboard/components/ImageView";
import NotificationView from "../../Dashboard/components/NotificationView";

import {
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert,
  AlertTitle,
} from "@mui/material";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import ReactSpeedometer from "react-d3-speedometer";
import { ToastContainer, toast } from "react-toastify";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import moment from "moment";
import Download from "../../common/assets/images/Download.svg";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import { Box } from "@mui/system";
import TrendView from "./TrendView";
import PatientDetails from "./PtDetails";
import logo from "../../login/assets/images/avyantra_logo.png";
import prelogo from "../../login/assets/images/presco_logo.png";

import "../styles/DataEntry.scss";
import { multiPartFileUpload } from "../reducers/patientDataThunk";
import GauzeChart from "./GauzeChart";
import { save } from "save-file";

import {
  setErrorMessage,
  setsuccessMessage,
  getFilesDetailsFromTable,
  getFilesPrescriptionsFromTable,
  sendForRefDrOpinion,
  downloadReport,
  getSendOpinion,
} from "../reducers/patientDataThunk";

const AddOpinion = () => {
  const textColor = "#000000";
  const dispatch = useDispatch();
  const verifyKey = useParams();
  const shareKey = verifyKey.id;
  const patient = useSelector((state) => state.patient);
  const study_id = patient.sendOpinion?.study_id;
  const studyId = patient.sendOpinion?.study_id;
  const reading = patient.sendOpinion?.reading;
  const baby_medical_record_number =
    patient.sendOpinion?.baby_medical_record_number;

  const [selectedFiles, setSelectedFiles] = useState("");
  const [listCaseSheets, setListCaseSheets] = useState([]);
  const [listDiagReports, setlistDiagReports] = useState([]);
  const [listImagings, setListImagings] = useState([]);
  const [DrOpinion, setDrOpinion] = React.useState("");
  const [addScoreData, setAddScoreData] = useState({});
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [SelectedPatientDetails, setSelectedPatientDetails] = useState([]);
  const [trend, setTrend] = React.useState(false);
  const [PtDetailsView, setPtDetailsView] = React.useState(false);
  const [listPrescription, setListPrescription] = useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [pdfData, setPdfData] = React.useState(null);
  const [fileType, setFileType] = React.useState(null);
  const [showSelectedFile, setSelectedFile] = useState([]);

  useEffect(() => {
    dispatch(getSendOpinion(shareKey)).then((resdata) => {});
  }, [shareKey]);

  React.useEffect(() => {
    const req = { baby_medical_record_number, reading };
    dispatch(getFilesPrescriptionsFromTable(req))
      .unwrap()
      .then((resdata) => {
        const Prescription = resdata.response[0].filter(
          (d) => d.document_type === "Prescription"
        );
        setListPrescription(Prescription);
      });
  }, [baby_medical_record_number, reading]);

  useEffect(() => {
    setDrOpinion(DrOpinion);
  }, [DrOpinion]);

  const TrendViewValue = () => {
    setSelectedPatient({ studyId, baby_medical_record_number });
    setTrend(true);
  };

  const PtdetailsView = () => {
    setSelectedPatientDetails({ studyId, reading });
    setPtDetailsView(true);
  };

  React.useEffect(() => {
    const generateScore = {
      scoreData: patient.sendOpinion?.score,
      color: getColorCode(patient.sendOpinion?.score),
      risk: riskAssess(getColorCode(patient.sendOpinion?.score)),
    };
    setAddScoreData(generateScore);
  }, [patient.sendOpinion?.score]);

  const riskAssess = (colorCode) => {
    // console.log("inside function Risk assess ");
    // console.log("the color code is", colorCode);
    if (colorCode === "#74D976") {
      return "Low Risk";
    }
    if (colorCode === "#F9D372") {
      return "Medium Risk";
    }
    if (colorCode === "#F56D6D") {
      return "High Risk";
    }
  };

  const getColorCode = (score) => {
    // console.log("inside function get color code ");
    // console.log("The score is", score);
    if (score >= 0 && score <= 5) {
      return "#74D976";
    }
    if (score > 5 && score <= 8) {
      return "#F9D372";
    }
    if (score > 8 && score <= 10) {
      return "#F56D6D";
    }
  };
  const handleAddFormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    // console.log("Field Name is ");
    // console.log(fieldname);
    const fieldvalue = event.target.value;
    // console.log("Field Value is ");
    // console.log(fieldvalue);
    const newFormData = { ...DrOpinion };
    newFormData[fieldname] = fieldvalue;
    // console.log("Field Value is ");
    // console.log(newFormData);
    setDrOpinion(newFormData);
  };

  const HandleAddFormSubmit = (event) => {
    event.preventDefault();
    let currentFile = selectedFiles[0];
    const newFormData = new FormData();
    const document_type = "Prescription";
    newFormData.append("document_type", document_type);
    newFormData.append("file", currentFile);
    newFormData.append("opinion", DrOpinion?.opinion);
    newFormData.append("prescription_data", DrOpinion?.prescription);
    // console.log("THe file in request is ", newFormData);
    // console.log("About to call Axios API");
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    const req = { shareKey, newFormData };
    dispatch(sendForRefDrOpinion(req))
      .unwrap()
      .then((resdata) => {
        toast.success("Opinion Submitted Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        const req = { baby_medical_record_number, reading };
        getFilesPrescriptionsFromTable(req);
      });
  };
  React.useEffect(() => {
    const req = { baby_medical_record_number, reading };
    dispatch(getFilesDetailsFromTable(req))
      .unwrap()
      .then((resdata) => {
        // console.log(resdata);
        const caseSheets = resdata.response[0].filter(
          (d) => d.document_type === "CaseSheets"
        );
        // console.log("Case sheets", caseSheets);
        setListCaseSheets(caseSheets);

        const diagReports = resdata.response[0].filter(
          (d) => d.document_type === "DiagReports"
        );
        setlistDiagReports(diagReports);

        const imagings = resdata.response[0].filter(
          (d) => d.document_type === "Imagings"
        );
        setListImagings(imagings);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [baby_medical_record_number, reading]);

  const uploadRefImages = (event) => {
    setSelectedFiles(event.target.files);
    // console.log("The selected file is, ", event.target.files);
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
  };

  const downloadRep = (e, fileName) => {
    e.preventDefault();
    // console.log("The file that we are getting from function is, ", fileName);

    dispatch(downloadReport(fileName))
      .unwrap()
      .then(async (resdata) => {
        // console.log("The referral download single report response data is", resdata);
        await save(resdata.Body, fileName);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [addReferralData, setaddReferralData] = useState(
    patient.referralDoctorData
  );

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [setOpen] = React.useState(false);

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = (newState) => () => {
    setState({ open: true, ...newState });
  };

  React.useEffect(() => {
    setaddReferralData(patient.referralDoctorData);
  }, [patient.referralDoctorData]);

  useEffect(() => {
    if (patient.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 10000);
    }
  }, [patient.errorMessage]);

  useEffect(() => {
    if (patient.successMessage) {
      setTimeout(() => {
        dispatch(setsuccessMessage(""));
      }, 10000);
    }
  }, [patient.successMessage]);

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };

  const closeDialog = (e) => {
    e.preventDefault();
    setTrend(false);
    setPtDetailsView(false);
  };
  const closeDialog1 = () => {
    setPtDetailsView(false);
  };

  const viewFile = async (e, fileName) => {
    e.preventDefault();
    setSelectedFile(fileName);
    dispatch(downloadReport(fileName))
      .unwrap()
      .then(async (resdata) => {
        setDialogOpen(true);
        setPdfData(resdata.Body.data);
        if (fileName.endsWith(".pdf")) {
          setFileType("pdf");
        } else if (fileName.endsWith(".jpg") || fileName.endsWith(".jpeg")) {
          setFileType("jpg");
        } else if (fileName.endsWith(".png")) {
          setFileType("png");
        } else {
          // console.log("Unknown file type");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        style={{ width: "auto" }}
        className="report-dialog"
      >
        <DialogTitle style={{ borderBottom: "0.8px solid #f0f1fc" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{showSelectedFile}</div>
            <div>
              <Link
                className="FIle-right-extreme"
                onClick={() => setDialogOpen(false)}
              >
                <img
                  src={Close}
                  //className="file-img-right-extreme"
                  alt=""
                />
              </Link>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {fileType && fileType === "pdf" ? (
            <NotificationView data={pdfData} action={setDialogOpen} />
          ) : fileType === "jpg" || "png" ? (
            <ImageView data={pdfData} action={setDialogOpen} />
          ) : null}
        </DialogContent>
      </Dialog>
      <div className="datacontent">
        <Card variant="none">
          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Grid container>
                  <Grid item xs={12} sm={10}>
                    <div>
                      <img src={logo} style={{ width: "12%" }} />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <img
                      src={prelogo}
                      className="pre_img"
                      style={{
                        width: "25%",
                        margin: "0px auto",
                        height: "auto",
                      }}
                    />
                    <div className="generatedon1">
                      Neonatal Sepsis Prediction Platform
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={9}>
            <form onSubmit={HandleAddFormSubmit}>
              <Card variant="none">
                <CardContent>
                  <p className="pt_info">Doctor Opinion</p>
                  <hr />
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <label
                        htmlFor="Dr Opinion"
                        className="dataentry_label"
                        style={{ display: "block" }}
                      >
                        Type your opinion below and upload prescriptions if any-
                      </label>
                      <TextField
                        id="opinion"
                        name="opinion"
                        variant="outlined"
                        className="dataentry_input_opinion"
                        size="small"
                        multiline
                        rows={5}
                        placeholder="Type your opinion here"
                        onChange={handleAddFormChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <label
                        htmlFor="Pprescription"
                        className="dataentry_label"
                        style={{ display: "block" }}
                      >
                        Prescription
                      </label>
                      <TextField
                        id="prescription"
                        name="prescription"
                        variant="outlined"
                        className="dataentry_input_opinion"
                        size="small"
                        multiline
                        rows={5}
                        placeholder="Enter your prescription here"
                        onChange={handleAddFormChange}
                      />
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <div className="report-content">
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="No_reports_Found"
                          >
                            <div>
                              <List>
                                {listPrescription.map((sheet) => (
                                  <ListItem>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-icon">
                                            <Link to="/" className="FIle-ref">
                                              {" "}
                                              <img
                                                src={File}
                                                className="file-img"
                                              />
                                            </Link>
                                          </ListItemIcon>
                                          <div
                                            className="file-name"
                                            title={sheet.s3_filename}
                                          >
                                            {sheet.s3_filename}
                                          </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-align  file-action-icons">
                                            <IconButton
                                              className="acrossBtn"
                                              onClick={(e) =>
                                                viewFile(e, sheet.s3_filename)
                                              }
                                            >
                                              <ImageSearchIcon
                                                className="DownLoad-img"
                                                color="primary"
                                                style={{
                                                  width: "0.8em",
                                                  height: "0.8em",
                                                }}
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                          <ListItemIcon className="file-align  file-action-icons">
                                            <IconButton
                                              aria-label="download"
                                              onClick={(e) =>
                                                downloadRep(
                                                  e,
                                                  sheet.s3_filename
                                                )
                                              }
                                            >
                                              <img
                                                src={Download}
                                                className="DownLoad-img"
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </ListItem>
                                ))}
                              </List>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} sm={4} className="uploadClassdevice1">
                      <label htmlFor="btn-upload-referral-diag">
                        <input
                          id="btn-upload-referral-diag"
                          name="btn-upload-diag"
                          style={{ display: "none" }}
                          type="file"
                          onChange={uploadRefImages}
                        />
                        <Button
                          className="upload-reports"
                          color="primary"
                          variant="contained"
                          component="span"
                          size="small"
                        >
                          Upload
                        </Button>
                      </label>
                      {selectedFiles.length !== 0 ? (
                        <div>{selectedFiles[0].name}</div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4} className="uploadClassdevice1">
                      <Button
                        variant="contained"
                        className="purple"
                        type="submit"
                        onClick={handleClick({
                          vertical: "top",
                          horizontal: "center",
                        })}
                        color="secondary"
                      >
                        <span>Submit Opinion</span>
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Card variant="none">
              <CardContent>
                <p className="pt_info">Patient Information</p>
                <hr />
                <div className="formSection">
                  <Grid container>
                    <Grid item xs={12} sm={7}>
                      <Grid container>
                        <Grid item xs={12} sm={6} className="pt_info_details">
                          BMRN
                          <br />
                          <span className="ptinfo_data">
                            {baby_medical_record_number}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={6} className="pt_info_details">
                          Date
                          <br />
                          <span className="ptinfo_data">
                            {/* {moment(createdAt).format("DD-MM-YYYY")} */}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={10} className="pt_info_details">
                          <Button
                            variant="contained"
                            size="small"
                            className="pt_info_viewtrend"
                            onClick={() => TrendViewValue()}
                          >
                            View Trend
                          </Button>
                          {trend && (
                            <TrendView
                              selectedPatient={selectedPatient}
                              open={trend}
                              onClose={closeDialog}
                            />
                          )}

                          <Button
                            variant="contained"
                            size="small"
                            className="pt_info_viewtrend"
                            onClick={() => PtdetailsView()}
                          >
                            View Patient Records
                          </Button>
                          {PtDetailsView && (
                            <PatientDetails
                              SelectedPatientDetails={SelectedPatientDetails}
                              open={PtDetailsView}
                              onClose={closeDialog1}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={5} className="pt_info_details">
                      Latest Score
                      <div className="GauzeChart_ref">
                        {/*  <ReactSpeedometer
                          width={160}
                          height={150}
                          forceRender={true}
                          maxSegmentLabels={1}
                          customSegmentStops={[0, 5, 8, 10]}
                          segmentColors={["#74d976", "#f9d372", "#f56d6d"]}
                          needleColor={addScoreData?.color}
                          currentValueText={addScoreData?.risk}
                          minValue={0}
                          maxValue={10}
                          value={addScoreData?.scoreData}
                          textColor={textColor}
                          ringWidth={20}
                          needleHeightRatio={0.7}
                        /> */}

                        <GauzeChart data={addScoreData} />
                      </div>
                    </Grid>
                  </Grid>

                  <p className="dr_opi_rpts">Uploaded Reports</p>

                  <form id="reports-generation">
                    <div className="round-bordered1">
                      <div className="report-header">
                        <Grid container className="CaseSheets">
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="file-name"
                            style={{ textAlign: "left" }}
                          >
                            Case Sheets
                          </Grid>
                        </Grid>
                      </div>
                      <div className="report-content">
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="No_reports_Found"
                          >
                            <div>
                              <List>
                                {listCaseSheets.map((sheet) => (
                                  <ListItem>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-icon">
                                            <Link to="/" className="FIle-ref">
                                              {" "}
                                              <img
                                                src={File}
                                                className="file-img"
                                              />
                                            </Link>
                                          </ListItemIcon>
                                          <div
                                            className="file-name"
                                            title={sheet.s3_filename}
                                          >
                                            {sheet.s3_filename}
                                          </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-align file-action-icons">
                                            <IconButton
                                              className="acrossBtn"
                                              onClick={(e) =>
                                                viewFile(e, sheet.s3_filename)
                                              }
                                            >
                                              <ImageSearchIcon
                                                className="DownLoad-img"
                                                color="primary"
                                                style={{
                                                  width: "0.8em",
                                                  height: "0.8em",
                                                }}
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                          <ListItemIcon className="file-align file-action-icons">
                                            <IconButton
                                              aria-label="acrossBtn"
                                              onClick={(e) =>
                                                downloadRep(
                                                  e,
                                                  sheet.s3_filename
                                                )
                                              }
                                            >
                                              <img
                                                src={Download}
                                                className="DownLoad-img"
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </ListItem>
                                ))}
                              </List>
                            </div>
                          </Grid>
                        </Grid>
                      </div>

                      <div className="report-header">
                        <Grid container className="CaseSheets">
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="file-name"
                            style={{ textAlign: "left" }}
                          >
                            Diagnostic Reports
                          </Grid>
                        </Grid>
                      </div>
                      <div className="report-content">
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="No_reports_Found"
                          >
                            <div>
                              <List>
                                {listDiagReports.map((sheet) => (
                                  <ListItem>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-icon">
                                            <Link to="/" className="FIle-ref">
                                              {" "}
                                              <img
                                                src={File}
                                                className="file-img"
                                              />
                                            </Link>
                                          </ListItemIcon>
                                          <div
                                            className="file-name"
                                            title={sheet.s3_filename}
                                          >
                                            {sheet.s3_filename}
                                          </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-align file-action-icons">
                                            <IconButton
                                              className="acrossBtn"
                                              onClick={(e) =>
                                                viewFile(e, sheet.s3_filename)
                                              }
                                            >
                                              <ImageSearchIcon
                                                className="DownLoad-img"
                                                color="primary"
                                                style={{
                                                  width: "0.8em",
                                                  height: "0.8em",
                                                }}
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                          <ListItemIcon className="file-align file-action-icons">
                                            <IconButton
                                              aria-label="acrossBtn"
                                              onClick={(e) =>
                                                downloadRep(
                                                  e,
                                                  sheet.s3_filename
                                                )
                                              }
                                            >
                                              <img
                                                src={Download}
                                                className="DownLoad-img"
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </ListItem>
                                ))}
                              </List>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="report-header">
                        <Grid container className="CaseSheets">
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="file-name"
                            style={{ textAlign: "left" }}
                          >
                            Imagings (X-rays,CT Scans)
                          </Grid>
                        </Grid>
                      </div>
                      <div className="report-content">
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="No_reports_Found"
                          >
                            <div>
                              <List>
                                {listImagings.map((sheet) => (
                                  <ListItem>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-icon">
                                            <Link to="/" className="FIle-ref">
                                              {" "}
                                              <img
                                                src={File}
                                                className="file-img"
                                              />
                                            </Link>
                                          </ListItemIcon>
                                          <div
                                            className="file-name"
                                            title={sheet.s3_filename}
                                          >
                                            {sheet.s3_filename}
                                          </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-align file-action-icons">
                                            <IconButton
                                              className="acrossBtn"
                                              onClick={(e) =>
                                                viewFile(e, sheet.s3_filename)
                                              }
                                            >
                                              <ImageSearchIcon
                                                className="DownLoad-img"
                                                color="primary"
                                                style={{
                                                  width: "0.8em",
                                                  height: "0.8em",
                                                }}
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                          <ListItemIcon className="file-align file-action-icons">
                                            <IconButton
                                              aria-label="acrossBtn"
                                              onClick={(e) =>
                                                downloadRep(
                                                  e,
                                                  sheet.s3_filename
                                                )
                                              }
                                            >
                                              <img
                                                src={Download}
                                                className="DownLoad-img"
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </ListItem>
                                ))}
                              </List>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </form>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
export default AddOpinion;
