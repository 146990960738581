import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MotherProfile from "./MotherProfile";
import BabyGeneral from "./BabyGeneral";
import {
  TextField,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Autocomplete,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Snackbar,
  Box,
  Typography,
  StepContext,
  InputAdornment,
} from "@mui/material";

import MuiAlert from "@mui/material/Alert";
import "../styles/DataEntry.scss";
import BabyBMRNo from "./babybmrno";
import {
  getAddBMRNScreen,
  getWardDetailbyBranch,
} from "../../Dashboard/reducers/Dashboardthunk";
import { ToastContainer, toast } from "react-toastify";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";

import {
  babyAddMedicalRecord,
  getPatientDetails,
  updateBabyMedicalRecord,
  setErrorMessage,
  setsuccessMessage,
  createPatientProfile,
  maternalAddition,
  getPatientBasicInfo,
  maternalProfileUpdate,
  babyProfileUpdate,
  addNewGlobalRecord,
  multiPartFileUpload,
  motherProfileFileUpload,
  downloadReport,
} from "../reducers/patientDataThunk";
import { Buffer } from "buffer";
import {
  AddBmrn_FORM_INCOMP,
  AddBmrn_FORM_Success,
  ENTITY_TYPES,
} from "../utils/mockData";
//import { transformBMRNModeToForm } from "../utils/transformer";
import * as _ from "lodash";
import { initialState } from "../reducers/InitialState";
import { object } from "prop-types";

const ALPHA_NUMERIC = /^[a-zA-Z0-9-]+$/;
const ALPHA = /^[a-zA-Z ]*$/;
const Numerics = /^[0-9 ]*$/;
const testEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem" }}
        style={{ backgroundColor: "#6572E4", color: "#fff", padding: "0.3rem" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  // flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    backgroundColor: "#6572E4",
    color: "#fff",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    backgroundColor: "#6572E4",
    color: "#fff",
    // transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const AddBMRN = (props) => {
  const [expanded, setExpanded] = React.useState("panel1");

  const [value, setValue] = useState("");
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);

  const dbbranchdata = useSelector((state) => state.dashboard);
  // console.log('dbbranchdata:',dbbranchdata)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bmrnOptions, setBmrnOptions] = React.useState([]);

  const loggedUserId = user.userProfile?.user_id;
  const hospital_id = user.userProfile.hospital_id;
  const hospitalId = user.userProfile.hospital_id;
  const hospitalBranchId = user.branchdata.branch_id;
  const branch_id = user.branchdata.branch_id;
  const [addBMRNData, setaddBMRNData] = useState(patient.bmrndata);
  const [addFormData, setAddFormData] = useState(patient.babyData);

  const [profileData, setProfileData] = useState(patient.bmrndata1);
  const [profilePic, setProfilePic] = useState("/broken-image.jpg");

  const [babyGeneralData, setBabyGeneralData] = useState({});
  const [babyMaternalData, setBabyMaternalData] = useState({});
  const ptbmrn = patient.bmrndata?.baby_medical_record_number;
  const [errorFields, setErrorFields] = useState({});
  const [showNameErrorMessage, setShowNameErrorMessage] = useState();
  const [showMotherNameErrorMessage, setShowMotherNameErrorMessage] =
    useState();
  const [showFatherNameErrorMessage, setShowFatherNameErrorMessage] =
    useState();
  const [showMobileErrorMessage, setShowMobileErrorMessage] = useState();
  const [showCityErrorMessage, setShowCityErrorMessage] = useState();
  const [showPincodeErrorMessage, setShowPincodeErrorMessage] = useState();
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState();
  const [showNationalityErrorMessage, setShowNationalityErrorMessage] =
    useState();
  const [selectedFile, setSelectedFile] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const studyId = addBMRNData?.study_id;
  const study_id = patient.bmrndata?.study_id;

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const handleClick = (newState) => async () => {
    setState({ open: true, ...newState });
    let bmrnData = Object.assign({}, addBMRNData);
    for (const key in bmrnData) {
      if (!bmrnData[key]) {
        bmrnData[key] = "NA";
      }
    }

    if (!bmrnData.patient_infos_id || bmrnData.patient_infos_id == "NA") {
      let req = { hospitalId, hospitalBranchId, loggedUserId, bmrnData };

      dispatch(babyAddMedicalRecord(req));
      let bdgBmrn = Object.assign({}, babyGeneralData, {
        study_id: study_id,
      });
      let mpBmrn = Object.assign({}, babyMaternalData, {
        study_id: study_id,
      });
      //babyGeneralData.baby_medical_record_number = ptbmrn;
      dispatch(createPatientProfile(bdgBmrn));
      dispatch(maternalAddition(mpBmrn));
      req = {
        hospitalId,
        hospitalBranchId,
        addBMRNData: { ...bmrnData, created_by: loggedUserId },
      };
      dispatch(addNewGlobalRecord(req)).then((resdata) => {
        sessionStorage.setItem(
          "dataEntry",
          JSON.stringify({
            baby_medical_record_number: addBMRNData.baby_medical_record_number,
            study_id: addBMRNData.study_id,
            reading: resdata.payload.response.reading_id,
            activeTab: 3,
          })
        );
        navigate("/dataentry");
      });
      toast.success("Profile Details Submitted Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (addBMRNData.patient_infos_id) {
      // console.log('123')
      const req = {
        studyId,
        hospitalId,
        hospitalBranchId,
        loggedUserId,
        bmrnData,
      };

      console.log('hhhhhhhhh',req)
      dispatch(updateBabyMedicalRecord(req))
        .then((resdata) => {
          let bdgBmrn = Object.assign({}, babyGeneralData, {
            study_id: study_id,
          });
          let mpBmrn = Object.assign({}, babyMaternalData, {
            study_id: study_id,
          });
          dispatch(
            babyProfileUpdate({ data: bdgBmrn, study_id, loggedUserId })
          );
          dispatch(
            maternalProfileUpdate({ data: mpBmrn, study_id, loggedUserId })
          );
          const req1 = { hospitalId, hospitalBranchId };

          dispatch(getPatientDetails(req));
          dispatch(setsuccessMessage(AddBmrn_FORM_Success));
          req = {
            hospitalId,
            hospitalBranchId,
            addBMRNData: { ...bmrnData, created_by: loggedUserId },
          };
          /* dispatch(addNewGlobalRecord(req)).then((resdata) => {
            sessionStorage.setItem(
              "dataEntry",
              JSON.stringify({
                baby_medical_record_number:
                  addBMRNData.baby_medical_record_number,
                study_id: addBMRNData.study_id,
                reading: resdata.payload.response.reading_id,
                activeTab: 3,
              })
            );
            navigate("/dataentry");
          }); */
          toast.success("Profile Details Submitted Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((e) => {
          toast.error("Profile Details Submission Failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  const handleClick1 = (newState) => async () => {
    console.log('hiiiiiiiiiiiii')
    setState({ open: true, ...newState });
    let bmrnData = Object.assign({}, addBMRNData);
    if (addBMRNData.patient_infos_id) {
      const req = {
        studyId,
        hospitalId,
        hospitalBranchId,
        loggedUserId,
        bmrnData,
      };
      dispatch(updateBabyMedicalRecord(req)).then((resdata) => {
        let bdgBmrn = Object.assign({}, babyGeneralData, {
          study_id: study_id,
        });
        let mpBmrn = Object.assign({}, babyMaternalData, {
          study_id: study_id,
        });
        dispatch(babyProfileUpdate({ data: bdgBmrn, study_id, loggedUserId }));
        dispatch(
          maternalProfileUpdate({ data: mpBmrn, study_id, loggedUserId })
        );
        const req1 = { hospitalId, hospitalBranchId };

        dispatch(getPatientDetails(req));
        toast.success("Profile Details Submitted Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        //dispatch(setsuccessMessage(AddBmrn_FORM_Success));
        navigate("/NewBMRN");
      });
    }
  };
  const onBmrnSelected = (e, value) => {
    if (value && !value.patient_infos_id) {
      dispatch(getAddBMRNScreen());
      navigate("/AddBMRN");
    } else {
      setaddBMRNData(value);
    }
  };

  const updateBabyGeneralData = async (data) => {
    setBabyGeneralData(data);
  };
  const updateBabyMaternalData = (data) => {
    setBabyMaternalData(data);
  };

  const uploadRefImages = (event) => {
    setSelectedFile(event.target.files[0]);
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    uploadFile(studyId);
  };

  const uploadFile = (studyId) => {
    let currentFile = selectedFile;
    const formData_img = new FormData();
    const document_type = "MotherImage";
    const userId = user?.userProfile?.user_id;
    formData_img.append("study_id", studyId);
    formData_img.append("document_type", document_type);
    formData_img.append("file", currentFile);
    dispatch(motherProfileFileUpload({ formData_img, studyId }))
      .unwrap()
      .then((resdata) => {
        // console.log("The referal response data is", resdata);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // For loading data while coming from Dashboard Screen.
  const [bmrn, setBmrn] = React.useState(sessionStorage.getItem("NewBmrn"));
  const bbmrn = sessionStorage.getItem("BmrnScreen");
  const ExistBmrn = sessionStorage.getItem("ExistBmrn");

  useEffect(() => {
    if (bmrn && bmrnOptions.length) {
      const index = _.findIndex(bmrnOptions, {
        baby_medical_record_number: bmrn,
      });
      if (index != -1) {
        onBmrnSelected(null, bmrnOptions[index]);
      }
    }
  }, [bmrn, bmrnOptions]);

  useEffect(() => {
    if (patient.babyData) setaddBMRNData(patient.babyData);
  }, [patient.babyData]);

  useEffect(() => {
    if (patient.bmrndata) setaddBMRNData(Object.assign({}, patient.bmrndata));
  }, [patient.bmrndata]);

  useEffect(async () => {
    dispatch(getPatientBasicInfo(patient?.bmrndata?.study_id)).then(
      (resdata) => {
        // setaddBMRNData(resdata.payload.response);
      }
    );
    // if (profileData.mothers_img) {
    //   setProfilePic(
    //     `data:image/jpeg;base64,${await encode(profileData.mothers_img)}`
    //   );
    // }
  }, [patient?.bmrndata?.study_id]);

  const encode = async (data) => {
    let buf = Buffer.from(data);
    let base64 = buf.toString("base64");
    return base64;
  };

  // useEffect(async () => {
  //   if (profileData.mothers_img) {
  //     setProfilePic(
  //       `data:image/jpeg;base64,${await encode(profileData.mothers_img)}`
  //     );
  //   }
  // }, [profileData]);

  useEffect(() => {
    if (hospitalBranchId) {
      setBmrnOptions([
        { baby_medical_record_number: "+ Add BMRN" },
        ...patient.bmrnList,
      ]);
    } else {
      setBmrnOptions([
        { baby_medical_record_number: "+ Add BMRN" },
        ...patient.bmrnList,
      ]);
    }
  }, [patient.bmrnList]);

  useEffect(() => {
    if (patient.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 10000);
    }
  }, [patient.errorMessage]);

  useEffect(() => {
    if (patient.successMessage) {
      setTimeout(() => {
        dispatch(setsuccessMessage(""));
      }, 10000);
    }
  }, [patient.successMessage]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const verifyContact = (event) => {
    const pincodeRegex = /^[6-9]\d{9}$/;
    if (!pincodeRegex.test(event.target.value)) {
      setShowMobileErrorMessage(
        "Mobile Number Should be 10 Digits & It Should Starts From 6 - 9"
      );
      setErrorFields(
        Object.assign({}, errorFields, { primary_contact_no: true })
      );
      handleAddFormChange(event);
    } else {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { primary_contact_no: false })
      );
      setShowMobileErrorMessage("");
    }
  };

  const verifyPincode = (event) => {
    const pincodeRegex1 = /^[1-9][0-9]{5}$/;
    if (!pincodeRegex1.test(event.target.value)) {
      setShowPincodeErrorMessage("Pincode Should be 6 Digits");
      setErrorFields(Object.assign({}, errorFields, { pincode: true }));
    } else {
      handleAddFormChange(event);
      setErrorFields(Object.assign({}, errorFields, { pincode: false }));
      setShowPincodeErrorMessage("");
    }
  };

  const verifyData = async () => {
    let validData = true;
    if (addBMRNData?.primary_contact_no.length != 10) {
      toast.error("Phone Number Should be 10 Digits", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setErrorFields(
        Object.assign({}, errorFields, {
          primary_contact_no: true,
        })
      );

      validData = false;
    }

    if (addBMRNData?.pincode.length != 6) {
      toast.error("Pincode Should be 6 Digits", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setErrorFields(
        Object.assign({}, errorFields, {
          pincode: true,
        })
      );

      validData = false;
    }

    if (
      babyGeneralData?.baby_gestational_age < 20 ||
      babyGeneralData?.baby_gestational_age >= 46
    ) {
      toast.error("Baby Gestational Age Value Should be Between 20 - 46", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      //document.getElementById("WeightAtBirth").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_gestational_age: true,
        })
      );

      validData = false;
    }

    if (babyGeneralData?.baby_place_of_birth_pin_code.length != 6) {
      toast.error("Place Of Birth Pincode should be 6 Digits", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      //document.getElementById("WeightAtBirth").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_place_of_birth_pin_code: true,
        })
      );

      validData = false;
    }

    return validData;
  };

  const handleAddFormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    const maxLength = event.target.maxLength;
    // console.log("Field Name is ");
    // console.log(fieldname);
    const fieldvalue = event.target.value;
    // console.log("Field Value is ");
    // console.log(fieldvalue);
    const newFormData = { ...addBMRNData };
    newFormData[fieldname] = fieldvalue;
    // console.log("Field Value is ");
    // console.log(newFormData);

    setaddBMRNData(newFormData);
    // setBMRNData(newFormData);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BabyBMRNo />

        <div className="datacontent">
          <Card variant="none">
            <CardContent>
              <form id="mother-medical-form">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                      style={{ borderRadius: "1rem 1rem 0 0" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{
                          background: "#F0F1FC",
                          borderRadius: "1rem 1rem 0 0",
                        }}
                      >
                        <Typography>Baby Medical Record Number</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {isLoading ? (
                          <div
                            style={{
                              height: "200px",
                              paddingTop: "230px",
                              textAlign: "center",
                            }}
                          >
                            <CircularProgress color="success" />
                          </div>
                        ) : (
                          <div id="bmrn-form">
                            <Grid container>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="Baby Medical Record No"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Baby Medical Record Number*
                                </label>
                                <TextField
                                  id="baby_medical_record_number"
                                  name="baby_medical_record_number"
                                  label=""
                                  variant="outlined"
                                  className="dataentry_input"
                                  size="small"
                                  value={
                                    addBMRNData?.baby_medical_record_number
                                  }
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="Mother Medical Record No"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Mother Medical Record Number
                                </label>
                                <TextField
                                  id="baby_mother_medical_record_number"
                                  name="baby_mother_medical_record_number"
                                  label=""
                                  variant="outlined"
                                  className="dataentry_input"
                                  size="small"
                                  value={
                                    addBMRNData?.baby_mother_medical_record_number &&
                                    addBMRNData?.baby_mother_medical_record_number
                                  }
                                  onChange={handleAddFormChange}
                                  InputProps={{
                                    inputProps: {
                                      maxLength: 50,
                                      minLength: 3,
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="Baby Name"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Baby Name
                                </label>
                                <TextField
                                  id="baby_name"
                                  name="baby_name"
                                  label=""
                                  variant="outlined"
                                  className="dataentry_input"
                                  size="small"
                                  value={
                                    addBMRNData?.baby_name &&
                                    addBMRNData?.baby_name
                                  }
                                  onChange={handleAddFormChange}
                                  error={
                                    errorFields.baby_name &&
                                    showNameErrorMessage
                                  }
                                  errorText={showNameErrorMessage}
                                  helperText={
                                    errorFields.baby_name &&
                                    showNameErrorMessage
                                      ? showNameErrorMessage
                                      : ""
                                  }
                                  onKeyDown={(event) => {
                                    if (!ALPHA.test(event.key)) {
                                      setShowNameErrorMessage(
                                        "Only Alphabets and Space are Acceptable"
                                      );
                                      setErrorFields(
                                        Object.assign({}, errorFields, {
                                          baby_name: true,
                                        })
                                      );
                                      event.preventDefault();
                                    } else {
                                      handleAddFormChange(event);
                                      setErrorFields(
                                        Object.assign({}, errorFields, {
                                          baby_name: false,
                                        })
                                      );
                                      setShowNameErrorMessage("");
                                    }
                                  }}
                                  /* InputProps={{
                                  inputProps: {
                                    maxLength: 50,
                                    minLength: 3,
                                  },
                                }} */
                                  /*  onKeyDown={(event) => {
                                  if (!ALPHA.test(event.key)) {
                                    setErrorFields(
                                      Object.assign({}, errorFields, {
                                        baby_name: true,
                                      })
                                    );
                                    toast.error(
                                      "Only Alphabets and Space are Acceptable",
                                      {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                      }
                                    );
                                    event.preventDefault();
                                  }
                                }} */
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="Identification Marks"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Identification Marks
                                </label>
                                <TextField
                                  id="Identification_marks"
                                  name="Identification_marks"
                                  label=""
                                  variant="outlined"
                                  className="dataentry_input"
                                  size="small"
                                  value={
                                    addBMRNData.Identification_marks &&
                                    addBMRNData.Identification_marks
                                  }
                                  onChange={handleAddFormChange}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="Mother's Name"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Mother's Name
                                </label>
                                <TextField
                                  id="mother_name"
                                  name="mother_name"
                                  label=""
                                  variant="outlined"
                                  className="dataentry_input"
                                  size="small"
                                  value={
                                    addBMRNData.Identification_marks &&
                                    addBMRNData.mother_name
                                  }
                                  onChange={handleAddFormChange}
                                  error={
                                    errorFields.mother_name &&
                                    showMotherNameErrorMessage
                                  }
                                  errorText={showMotherNameErrorMessage}
                                  helperText={
                                    errorFields.mother_name &&
                                    showMotherNameErrorMessage
                                      ? showMotherNameErrorMessage
                                      : ""
                                  }
                                  onKeyDown={(event) => {
                                    if (!ALPHA.test(event.key)) {
                                      setShowMotherNameErrorMessage(
                                        "Only Alphabets and Space are Acceptable"
                                      );
                                      setErrorFields(
                                        Object.assign({}, errorFields, {
                                          mother_name: true,
                                        })
                                      );
                                      event.preventDefault();
                                    } else {
                                      handleAddFormChange(event);
                                      setErrorFields(
                                        Object.assign({}, errorFields, {
                                          mother_name: false,
                                        })
                                      );
                                      setShowMotherNameErrorMessage("");
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="Mother's Image"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Mother's Image
                                </label>
                                <Grid container>
                                  <Grid item xs={8} sm={8}>
                                    <Button
                                      variant="contained"
                                      component="label"
                                      id="uploadfile"
                                      className="sign-up-button"
                                    >
                                      Upload
                                      <input
                                        hidden
                                        accept="image/*"
                                        type="file"
                                        onChange={uploadRefImages}
                                      />
                                    </Button>
                                    {selectedFile.length !== 0 ? (
                                      <>
                                        <div>
                                          <Button onClick={() => window.open(URL.createObjectURL(selectedFile))}>
                                            View 
                                          </Button>
                                          {selectedFile.name}
                                        </div>
                                      </>
                                    ) : (
                                      <div></div>
                                    )}
                                    {!profilePic ? (
                                      <Avatar
                                        src={profilePic}
                                        sx={{ width: 30, height: 30 }}
                                      />
                                    ) : (
                                      <div></div>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="Father's Name"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Father's Name
                                </label>
                                <TextField
                                  id="father_name"
                                  name="father_name"
                                  label=""
                                  variant="outlined"
                                  className="dataentry_input"
                                  size="small"
                                  InputProps={{
                                    inputProps: {
                                      maxLength: 50,
                                      minLength: 3,
                                    },
                                  }}
                                  value={
                                    addBMRNData.father_name
                                      ? addBMRNData.father_name.trimStart()
                                      : ""
                                  }
                                  onChange={handleAddFormChange}
                                  error={
                                    errorFields.father_name &&
                                    showFatherNameErrorMessage
                                  }
                                  errorText={showFatherNameErrorMessage}
                                  helperText={
                                    errorFields.father_name &&
                                    showFatherNameErrorMessage
                                      ? showFatherNameErrorMessage
                                      : ""
                                  }
                                  onKeyDown={(event) => {
                                    if (!ALPHA.test(event.key)) {
                                      setShowFatherNameErrorMessage(
                                        "Only Alphabets and Space are Acceptable"
                                      );
                                      setErrorFields(
                                        Object.assign({}, errorFields, {
                                          father_name: true,
                                        })
                                      );
                                      event.preventDefault();
                                    } else {
                                      handleAddFormChange(event);
                                      setErrorFields(
                                        Object.assign({}, errorFields, {
                                          father_name: false,
                                        })
                                      );
                                      setShowFatherNameErrorMessage("");
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="Phone Number"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Phone Number
                                </label>
                                <TextField
                                  id="PrimaryContactNo"
                                  name="primary_contact_no"
                                  label=""
                                  variant="outlined"
                                  className="dataentry_input"
                                  size="small"
                                  value={
                                    addBMRNData.primary_contact_no &&
                                    addBMRNData.primary_contact_no
                                  }
                                  //onChange={handleAddFormChange}

                                  onChange={(e) => {
                                    handleAddFormChange(e);
                                    verifyContact(e);
                                  }}
                                  error={
                                    errorFields.primary_contact_no &&
                                    showMobileErrorMessage
                                  }
                                  errorText={showMobileErrorMessage}
                                  helperText={
                                    errorFields.primary_contact_no &&
                                    showMobileErrorMessage
                                      ? showMobileErrorMessage
                                      : ""
                                  }
                                  onKeyDown={(event) => {
                                    if (
                                      !Numerics.test(event.key) &&
                                      event.key !== "Backspace" &&
                                      event.key !== "Enter" &&
                                      event.key !== "Tab"
                                    ) {
                                      event.preventDefault();
                                      setShowMobileErrorMessage("Only Numerics are acceptable");
                                      setErrorFields(Object.assign({}, errorFields, { primary_contact_no: true }));
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="Educational Level"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Educational Level
                                </label>
                                <Select
                                  labelId="Educational"
                                  id="educational_level"
                                  name="educational_level"
                                  value={addBMRNData.educational_level}
                                  label=""
                                  onChange={handleAddFormChange}
                                  size="small"
                                  className="dataentry_select_edu"
                                >
                                  <MenuItem value="Primary Education">
                                    Primary Education
                                  </MenuItem>
                                  <MenuItem value="Secondary Education/ High School">
                                    Secondary Education/ High School
                                  </MenuItem>
                                  <MenuItem value="Bachelor's Degree">
                                    Bachelor's Degree
                                  </MenuItem>
                                  <MenuItem value="Master's Degree">
                                    Master's Degree
                                  </MenuItem>
                                  <MenuItem value="Doctorate or Higher">
                                    Doctorate or Higher
                                  </MenuItem>
                                  <MenuItem value="No formal education">
                                    No formal education
                                  </MenuItem>
                                </Select>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <label
                                  htmlFor="Address"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Address
                                </label>
                                <TextField
                                  id="address"
                                  name="address"
                                  label=""
                                  variant="outlined"
                                  className="dataentry_input_add"
                                  size="small"
                                  value={
                                    addBMRNData?.address && addBMRNData?.address
                                  }
                                  onChange={handleAddFormChange}
                                  InputProps={{
                                    inputProps: {
                                      maxLength: 250,
                                      minLength: 3,
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="City"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  City
                                </label>
                                <TextField
                                  id="city"
                                  name="city"
                                  label=""
                                  variant="outlined"
                                  className="dataentry_input"
                                  size="small"
                                  value={addBMRNData.city && addBMRNData.city}
                                  onChange={handleAddFormChange}
                                  error={
                                    errorFields.city && showCityErrorMessage
                                  }
                                  errorText={showCityErrorMessage}
                                  helperText={
                                    errorFields.city && showCityErrorMessage
                                      ? showCityErrorMessage
                                      : ""
                                  }
                                  onKeyDown={(event) => {
                                    if (!ALPHA.test(event.key)) {
                                      setShowCityErrorMessage(
                                        "Only Alphabets and Space are Acceptable"
                                      );
                                      setErrorFields(
                                        Object.assign({}, errorFields, {
                                          city: true,
                                        })
                                      );
                                      event.preventDefault();
                                    } else {
                                      handleAddFormChange(event);
                                      setErrorFields(
                                        Object.assign({}, errorFields, {
                                          city: false,
                                        })
                                      );
                                      setShowCityErrorMessage("");
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="State"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  State
                                </label>
                                <Select
                                  labelId="state"
                                  id="state"
                                  name="state"
                                  value={addBMRNData.state}
                                  label=""
                                  onChange={handleAddFormChange}
                                  size="small"
                                  className="dataentry_select_edu"
                                >
                                  <MenuItem value="Andhra Pradesh">
                                    Andhra Pradesh
                                  </MenuItem>
                                  <MenuItem value="Andaman and Nicobar Islands">
                                    Andaman and Nicobar Islands
                                  </MenuItem>
                                  <MenuItem value="Arunachal Pradesh">
                                    Arunachal Pradesh
                                  </MenuItem>
                                  <MenuItem value="Assam">Assam</MenuItem>
                                  <MenuItem value="Bihar">Bihar</MenuItem>
                                  <MenuItem value="Chandigarh">
                                    Chandigarh
                                  </MenuItem>
                                  <MenuItem value="Chhattisgarh">
                                    Chhattisgarh
                                  </MenuItem>
                                  <MenuItem value="Dadar and Nagar Haveli">
                                    Dadar and Nagar Haveli
                                  </MenuItem>
                                  <MenuItem value="Daman and Diu">
                                    Daman and Diu
                                  </MenuItem>
                                  <MenuItem value="Delhi">Delhi</MenuItem>
                                  <MenuItem value="Lakshadweep">
                                    Lakshadweep
                                  </MenuItem>
                                  <MenuItem value="Puducherry">
                                    Puducherry
                                  </MenuItem>
                                  <MenuItem value="Goa">Goa</MenuItem>
                                  <MenuItem value="Gujarat">Gujarat</MenuItem>
                                  <MenuItem value="Haryana">Haryana</MenuItem>
                                  <MenuItem value="Himachal Pradesh">
                                    Himachal Pradesh
                                  </MenuItem>
                                  <MenuItem value="Jammu and Kashmir">
                                    Jammu and Kashmir
                                  </MenuItem>
                                  <MenuItem value="Jharkhand">
                                    Jharkhand
                                  </MenuItem>
                                  <MenuItem value="Karnataka">
                                    Karnataka
                                  </MenuItem>
                                  <MenuItem value="Kerala">Kerala</MenuItem>
                                  <MenuItem value="Madhya Pradesh">
                                    Madhya Pradesh
                                  </MenuItem>
                                  <MenuItem value="Maharashtra">
                                    Maharashtra
                                  </MenuItem>
                                  <MenuItem value="Manipur">Manipur</MenuItem>
                                  <MenuItem value="Meghalaya">
                                    Meghalaya
                                  </MenuItem>
                                  <MenuItem value="Mizoram">Mizoram</MenuItem>
                                  <MenuItem value="Nagaland">Nagaland</MenuItem>
                                  <MenuItem value="Odisha">Odisha</MenuItem>
                                  <MenuItem value="Punjab">Punjab</MenuItem>
                                  <MenuItem value="Rajasthan">
                                    Rajasthan
                                  </MenuItem>
                                  <MenuItem value="Sikkim">Sikkim</MenuItem>
                                  <MenuItem value="Tamil Nadu">
                                    Tamil Nadu
                                  </MenuItem>
                                  <MenuItem value="Telangana">
                                    Telangana
                                  </MenuItem>
                                  <MenuItem value="Tripura">Tripura</MenuItem>
                                  <MenuItem value="Uttar Pradesh">
                                    Uttar Pradesh
                                  </MenuItem>
                                  <MenuItem value="Uttarakhand">
                                    Uttarakhand
                                  </MenuItem>
                                  <MenuItem value="West Bengal">
                                    West Bengal
                                  </MenuItem>
                                </Select>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="Pincode"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Pincode
                                </label>
                                <TextField
                                  id="Pincode"
                                  name="pincode"
                                  label=""
                                  variant="outlined"
                                  className="dataentry_input"
                                  size="small"
                                  value={addBMRNData?.pincode}
                                  onChange={(e) => {
                                    handleAddFormChange(e);
                                    verifyPincode(e);
                                  }}
                                  error={
                                    errorFields.pincode &&
                                    showPincodeErrorMessage
                                  }
                                  //helperText={showErrorMessage}
                                  errorText={showPincodeErrorMessage}
                                  helperText={
                                    errorFields.pincode &&
                                    showPincodeErrorMessage
                                      ? showPincodeErrorMessage
                                      : ""
                                  }
                                  onKeyDown={(event) => {
                                    if (
                                      !Numerics.test(event.key) &&
                                      event.key != "Backspace"
                                    ) {
                                      setShowPincodeErrorMessage(
                                        "Only Numerics are acceptable"
                                      );
                                      setErrorFields(
                                        Object.assign({}, errorFields, {
                                          pincode: true,
                                        })
                                      );
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="Nationality"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Nationality
                                </label>
                                <TextField
                                  id="nationality"
                                  name="nationality"
                                  label=""
                                  variant="outlined"
                                  className="dataentry_input"
                                  size="small"
                                  value={
                                    addBMRNData.nationality &&
                                    addBMRNData.nationality
                                  }
                                  onChange={handleAddFormChange}
                                  error={
                                    errorFields.nationality &&
                                    showNationalityErrorMessage
                                  }
                                  errorText={showNationalityErrorMessage}
                                  helperText={
                                    errorFields.nationality &&
                                    showNationalityErrorMessage
                                      ? showNationalityErrorMessage
                                      : ""
                                  }
                                  onKeyDown={(event) => {
                                    if (!ALPHA.test(event.key)) {
                                      setShowNationalityErrorMessage("");
                                      ("Only Alphabets and Space are Acceptable");
                                      setErrorFields(
                                        Object.assign({}, errorFields, {
                                          nationality: true,
                                        })
                                      );
                                      event.preventDefault();
                                    } else {
                                      handleAddFormChange(event);
                                      setErrorFields(
                                        Object.assign({}, errorFields, {
                                          nationality: false,
                                        })
                                      );
                                      setShowNationalityErrorMessage("");
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="Email Id"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Email Id
                                </label>
                                <TextField
                                  id="email_id"
                                  name="email_id"
                                  label=""
                                  variant="outlined"
                                  className="dataentry_input"
                                  size="small"
                                  value={
                                    addBMRNData.email_id && addBMRNData.email_id
                                  }
                                  error={
                                    errorFields.email_id &&
                                    showEmailErrorMessage
                                  }
                                  errorText={showEmailErrorMessage}
                                  helperText={
                                    errorFields.email_id &&
                                    showEmailErrorMessage
                                      ? showEmailErrorMessage
                                      : ""
                                  }
                                  onChange={handleAddFormChange}
                                  onBlur={(event) => {
                                    if (
                                      addBMRNData.email_id &&
                                      addBMRNData.email_id.length != 0
                                    ) {
                                      if (!testEmail.test(event.target.value)) {
                                        setShowEmailErrorMessage(
                                          "Please Enter Valid Email Id"
                                        );
                                        setErrorFields(
                                          Object.assign({}, errorFields, {
                                            email_id: true,
                                          })
                                        );
                                        event.currentTarget.focus();
                                      } else {
                                        setShowEmailErrorMessage("");
                                      }
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="Status"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Status
                                </label>
                                <ToggleButtonGroup
                                  color="primary"
                                  exclusive
                                  className="Toggle_input"
                                  value={
                                    addBMRNData.status
                                      ? typeof addBMRNData.status == "number"
                                        ? addBMRNData.status.toString()
                                        : addBMRNData.status
                                      : "0"
                                  }
                                  onChange={handleAddFormChange}
                                >
                                  <ToggleButton
                                    value="1"
                                    id="status"
                                    name="status"
                                    size="small"
                                    className="dataentry_input sign-up-button"
                                  >
                                    Active
                                  </ToggleButton>
                                  <ToggleButton
                                    value="0"
                                    id="status"
                                    name="status"
                                    size="small"
                                    className="dataentry_input sign-up-button"
                                  >
                                    Inactive
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="Medico Legal Case"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Medico Legal Case
                                </label>
                                <ToggleButtonGroup
                                  color="primary"
                                  value={addBMRNData.medico_legal_case}
                                  onChange={handleAddFormChange}
                                  className="Toggle_input"
                                >
                                  <ToggleButton
                                    value="Yes"
                                    id="medico_legal_case"
                                    name="medico_legal_case"
                                    size="small"
                                    className="dataentry_input sign-up-button"
                                  >
                                    Yes
                                  </ToggleButton>
                                  <ToggleButton
                                    value="No"
                                    id="medico_legal_case"
                                    name="medico_legal_case"
                                    size="small"
                                    className="dataentry_input sign-up-button"
                                  >
                                    No
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                        style={{ background: "#F0F1FC" }}
                      >
                        <Typography>Baby Profile</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <BabyGeneral
                          updateBabyGeneralData={updateBabyGeneralData}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                        style={{ background: "#F0F1FC" }}
                      >
                        <Typography>Mother Profile</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <MotherProfile
                          updateBabyMaternalData={updateBabyMaternalData}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <div className="formSection">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}></Grid>
                        {addBMRNData.patient_infos_id ? (
                          <Grid item xs={12} sm={2}>
                            <Button
                              variant="outlined"
                              className="purple"
                              onClick={handleClick1({
                                vertical: "top",
                                horizontal: "center",
                                width: "50% !important",
                              })}
                              style={{ width: '50% !important' }}
                            >
                              Update Data
                            </Button>
                          </Grid>
                        ) : null}
                        {!addBMRNData.patient_infos_id ? (
                          <Grid item xs={12} sm={2}>
                            <Button
                              variant="outlined"
                              className="purple"
                              onClick={handleClick({
                                vertical: "top",
                                horizontal: "center",
                              })}
                            >
                              Save Data
                            </Button>
                          </Grid>
                        ) : null}
                        <Grid item xs={12} sm={5}></Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </div>
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default AddBMRN;
