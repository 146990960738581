import { axiosInstance } from "./http-common";
import axios from "axios";

const scoreUrl = "https://score-api.presco.avyantra.com";
//const scoreUrl = "http://127.0.0.1:5000";

const patientSignUp = (data) => {
  return axiosInstance.post(`patient/signup`, data, {
    title: "Patient SignUp",
  });
};

const getPatients = (req) => {
  return axiosInstance.get(
    `patient/medicalRecord/${req.hospitalId}/${req.hospitalBranchId}/1/1/100/false`,
    {
      title: "GetPatients",
    }
  );
};
const getPatientBasicInfo = (studyId) => {
  return axiosInstance.get(
    `patient/medicalRecord/patient/basic-info/${studyId}`,
    {
      title: "getPatientBasicInfo",
    }
  );
};

const getIndividualBabyMedicalRecord = (req) => {
  return axiosInstance.get(`patient/individualMedicalRecord/${req.study_id}`, {
    title: "getIndividualBabyMedicalRecord",
  });
};

const getListingByBranch = (req) => {
  const adminFilter = req.admin_id ? `&admin_id=${req.admin_id}` : "";
  return axiosInstance.get(
    `patient/medicalRecord/listing/${req.hospital_id}/1/100?branch_id=${req.branch_id}&ward_id=${req.ward_id}${adminFilter}`,
    {
      title: "getListingByBranch",
    }
  );
};

const getDashboardListing = (req) => {
  return axiosInstance.get(
    `patient/medicalRecord/listing/${req.hospitalId}/1/100?sortType=${
      req.sortType
    }&sortOrder=${req.sortOrder}${
      req.branch_id ? "&branch_id=" + req.branch_id : ""
    }${req.ward_id ? "&ward_id=" + req.ward_id : ""}${
      req.admin_id ? "&admin_id=" + req.admin_id : ""
    }`,
    {
      title: "GetDashboardListing",
    }
  );
};

const getDashboardListingBySearch = (req) => {
  return axiosInstance.get(
    `patient/medicalRecord/search/${req.hospitalId}/${
      req.searchString
    }/1/100?sortType=${req.sortType}&sortOrder=${req.sortOrder}${
      req.branch_id ? "&branch_id=" + req.branch_id : ""
    }${req.ward_id ? "&ward_id=" + req.ward_id : ""}${
      req.admin_id ? "&admin_id=" + req.admin_id : ""
    }`,
    {
      title: "GetDashboardListingBySearch",
    }
  );
};

const getPatientReadings = (req) => {
  return axiosInstance.get(`patient/readings/${req.studyId}`, {
    title: "getPatientReadings",
  });
};

const getLevel = (patient_id) => {
  return axiosInstance.post(`patient/get_level/${patient_id}`, {
    title: "Patient Level",
  });
};

const patientBasicInfoUpdatedpost = (data) => {
  return axiosInstance.post(`patient/basic/add`, data, {
    title: "Patient Basic info Updated",
  });
};

const patientCreate = (data) => {
  // console.log("Inside axios instance function");
  // console.log(data);
  return axiosInstance.post(`patient/general/add/1`, data, {
    title: "Create Patient",
  });
};

const patientBasicInfoDupUpdated = (data, hospital_id) => {
  return axiosInstance.post(`patient/basic/add_dup/${hospital_id} `, data, {
    title: "Patient Basic Dup Info Updated",
  });
};

const patientGeneralInfoUpdated = (data, loggedIdUserId) => {
  return axiosInstance.post(`patient/general/add/${loggedIdUserId}`, data);
};

const maternalAdd = (data) => {
  return axiosInstance.post(`patient/maternal/add`, data);
};

const createBabyHealthParameters = (req) => {
  return axiosInstance.post(
    `patient/add/babyHealthParameters/${req.study_id}/${req.loggedIdUserId}/${req.readingId}`,
    req.data
  );
};

const babyGitAdd = (data) => {
  return axiosInstance.post(`patient/baby_git/add`, data);
};

const babyAppearAdd = (data) => {
  return axiosInstance.post(`patient/baby_appears/add`, data);
};

const babyRespAdd = (data) => {
  return axiosInstance.post(`patient/baby_resp/add`, data);
};

const babyCnsAdd = (data) => {
  return axiosInstance.post(`patient/baby_cns/add`, data);
};

const babyCvAdd = (data) => {
  return axiosInstance.post(`patient/baby_cv/add`, data);
};

const antibioticAdd = (data) => {
  return axiosInstance.post(`patient/baby_antibiotic/add`, data);
};

const babyInvestigationAdd = (data) => {
  return axiosInstance.post(`patient/baby_investigation/add`, data);
};

const finalAdd = (data) => {
  return axiosInstance.post(`patient/baby_final/add`, data);
};

const searchPatient = (obj) => {
  return axiosInstance.post(`patient/like`, obj);
};

const createNewReading = (data, loggedInUserId) => {
  return axiosInstance.post(`patient/models/save/${loggedInUserId}`, data);
};

const getNewReading = (studyId) => {
  return axiosInstance.get(`patient/readingId/${studyId}`);
};

const getReadingid = (studyId) => {
  return axiosInstance.get(`patient/getReadingid/${studyId}`);
};

const updateFormData = (req) => {
  // console.log("Inside axios for baby cns update");
  // console.log(req.url);
  // console.log(req.study_id);
  // console.log(req.reading);
  // console.log(req.data);
  // console.log(req.loggedUserId);
  return axiosInstance.put(
    `${req.url}/${req.study_id}/${req.reading}/${req.loggedUserId}`,
    req.data
  );
};

const getLastReadingData = (study_id) => {
  return axiosInstance.get(`patient/models/${study_id}`);
};

const updateBabyProfile = (req) => {
  return axiosInstance.put(
    `patient/update/babyProfile/${req.study_id}/${req.loggedIdUserId}`,
    req.data
  );
};

const updateMaternalProfile = (req) => {
  // console.log("motherProfile:", req.data);
  return axiosInstance.put(
    `patient/update/motherProfile/${req.study_id}/${req.loggedIdUserId}`,
    req.data
  );
};

const updateBabyHealthParameters = (req) => {
  return axiosInstance.put(
    `patient/update/babyHealthParameters/${req.study_id}/${req.loggedIdUserId}/${req.reading}`,
    req.data
  );
};

const getScore = (req) => {
  return axios.post(`${scoreUrl}/invasive/getscore`, req);
};

const NonInvasiveScore = (req) => {
  return axios.post(`${scoreUrl}/noninvasive/getscore`, req);
};

const QuickSepsisScore = (req) => {
  return axios.post(`${scoreUrl}/quicksepsis/getscore`, req);
};

const SemiInvasiveScore = (req) => {
  return axios.post(`${scoreUrl}/semiinvasive/getscore`, req);
};

const updateScore = (req) => {
  // console.log("req.formData", req);
  return axiosInstance.put(`/patient/score/${req.study_id}/${req.reading}`, {
    score: req.score,
    explanation: req.explanation,
  });
};

const downloadReport = (filename) => {
  // console.log("In Axios the file name is", filename);
  return axiosInstance.get(`file/download/${filename}`);
};

const listReports = () => {
  return axiosInstance.get(`file/list`);
};
const addMedicalRecord = (req) => {
  return axiosInstance.post(
    `patient/medicalRecord/${req.hospitalId}/${req.hospitalBranchId}`,
    req.bmrnData
  );
};
const babyMedicalRecordAdd = (req) => {
  return axiosInstance.post(
    `patient/add-medical-record/${req.hospitalId}/${req.hospitalBranchId}`,
    req.addBMRNData
  );
};
const addNewGlobalRecord = (req) => {
  return axiosInstance.post(
    `patient/add-global-record/${req.hospitalId}/${req.hospitalBranchId}`,
    req.addBMRNData
  );
};

const getPatientBasicDetails = (req) => {
  return axiosInstance.get(
    `patient/get-baby-details/${req.baby_medical_record_number}/${req.reading}`
  );
};

const saveglobalrecordid = (req) => {
  return axiosInstance.post(
    `patient/saveglobalrecordid/${req.studyId}/${req.recordId}/${req.entityTypeSel}`,
    {
      title: "Save global record",
    }
  );
};

const sendForOpinion = (req) => {
  return axiosInstance.post(
    `hospitalStaff/staffReferral/${req.staffId}/${req.branchId}`,
    req.data
  );
};

const sendForRefDrOpinion = (req) => {
  return axiosInstance.post(`sendOpinion/${req.shareKey}`, req.newFormData);
};

const submitDrOpinion = (req) => {
  return axiosInstance.post(
    `hospitalStaff/sendOpinion/${req.study_id}/${req.reading}/${req.loggedUserId}`,
    req.newFormData
  );
};

const updateBabyMedicalRecord = (req) => {
  return axiosInstance.put(
    `patient/medicalRecord/${req.studyId}/${req.hospitalId}/${req.hospitalBranchId}/${req.loggedUserId}`,
    req.bmrnData
  );
};

const multiPartFileUpload = (req) => {
  return axiosInstance.post(`/file/upload/`, req);
};

const ProfileFileUpload = (req) => {
  // console.log("req", req);
  return axiosInstance.post(`/user/profilePic/${req.userId}`, req.formData);
};

const motherProfileFileUpload = (req) => {
  // console.log("req", req);
  return axiosInstance.post(
    `/user/motherProfilePic/${req.studyId}`,
    req.formData_img
  );
};

const getBranchDetail = (req) => {
  return axiosInstance.get(`hospitalBranch/branches/${req.hospital_id}`);
};
const getBranchDetailByUser = (req) => {
  return axiosInstance.get(`hospitalBranch/admin/${req.userId}`);
};

const addbranchdetails = (req) => {
  return axiosInstance.post(
    `hospitalBranch/register/${req.hospital_id}`,
    req.newFormData
  );
};
const addWardDetails = (req) => {
  return axiosInstance.post(
    `hospitalBranch/wardregister/${req.hospital_id}`,
    req.newFormData
  );
};

const updateWardDetails = (req) => {
  return axiosInstance.put(
    `hospitalBranch/wards/${req.hospital_id}/${req.branch_id}`,
    req.newFormData
  );
};
const updateRoleDetails = (req) => {
  return axiosInstance.put(`hospital-role-name`, req);
};
const getWardDetail = (hospital_id) => {
  return axiosInstance.get(`hospitalBranch/getWardDetail/${hospital_id}`);
};

const getHospitalRoles = (hospital_id) => {
  return axiosInstance.get(`hospital-roles/${hospital_id}`);
};

const getWardDetailbyBranch = (req) => {
  return axiosInstance.get(
    `hospitalBranch/wards/${req.hospital_id}/${req.branch_id}`
  );
};
const getAdminDetailByBranch = (req1) => {
  return axiosInstance.get(
    `hospitalBranch/admins/${req1.hospital_id}/${req1.branch_id}`
  );
};

const getFilesDetailsFromTable = (req) => {
  return axiosInstance.get(
    `patient/getFileDetails/${req.baby_medical_record_number}/${req.reading}`,
    {
      title: "GetFileDetails",
    }
  );
};

const getFilesPrescriptionsFromTable = (req) => {
  return axiosInstance.get(
    `patient/getFilePrescriptionDetails/${req.baby_medical_record_number}/${req.reading}`,
    {
      title: "GetFileDetails",
    }
  );
};

const deleteReport = (req) => {
  // console.log("In Axios the file name is", req.filename);
  return axiosInstance.delete(`file/delete/${req.filename}`);
};
const getmapedBranchDetail = (hospital_id) => {
  return axiosInstance.get(
    `hospitalBranch/getmapedBranchDetail/${hospital_id}`
  );
};

const getMappedWardsOfBranch = (branchId) => {
  return axiosInstance.get(`hospitalBranch/getMappedWardsOfBranch/${branchId}`);
};

const addMapWithBranch = (req) => {
  return axiosInstance.post(
    `hospitalBranch/addMapWithBranch/${req.branch_id}/${req.hospital_id}`,
    req
  );
};
const addReferralDoctorToTable = (req) => {
  return axiosInstance.post(`hospital/addReferralDoctorToTable`, req);
};

const updateReferalDoctorDetails = (req) => {
  return axiosInstance.put(
    `hospital/referral-doctor/${req.hospital_id}/${req.referral_id}`,
    req.newFormData
  );
};

/* const getRefDoctorsFromTable = (req) => {
  return axiosInstance.get(`hospital/getRefDoctorsFromTable/${req.staff_id}`);
}; */

const addAdminDetails = (req) => {
  return axiosInstance.post(
    `hospitalBranch/addAdmin/${req.hospital_id}`,
    req.newFormData
  );
};

const updateAdminDetails = (req) => {
  return axiosInstance.put(
    `hospitalBranch/updateAdminDetails/${req.admin_id}/${req.hospital_id}`,
    req.newFormData
  );
};
const getAdminDetail = (req) => {
  return axiosInstance.get(
    `hospitalBranch/getAdminDetail/${req.hospital_id}?${
      req.role_id ? "role_id=" + req.role_id + "&" : ""
    }${req.searchText ? "searchText=" + req.searchText + "&" : ""}`
  );
};
const getAdminsByRole = (req) => {
  return axiosInstance.get(
    `hospitalBranch/admins/${req.hospital_id}/${req.role_id}`
  );
};
const getmapedAdminDetail = (hospital_id) => {
  return axiosInstance.get(`hospitalBranch/getmapedAdminDetail/${hospital_id}`);
};

const getMappedAdminDetailsByUser = (id) => {
  return axiosInstance.get(`hospitalBranch/mapped-branch/${id}`);
};

const getUserHierarchyDetail = (admin_id) => {
  return axiosInstance.get(`hospitalBranch/getUserHierarchyDetail/${admin_id}`);
};
const mapAdminWithBranch = (req) => {
  return axiosInstance.post(
    `hospitalBranch/mapAdminWithBranch/${req.admin_id}`,
    req
  );
};
const mapUserHierarchy = (req) => {
  return axiosInstance.post(
    `hospitalBranch/mapUserHierarchy/${req.admin_id}`,
    req
  );
};
const addOtpToTable = (req) => {
  return axiosInstance.post(`hospital/addOtpToTable`, req);
};
const verifyOTP = (req) => {
  return axiosInstance.get(
    `hospital/verifyOTP/${req.otpEntered}/${req.referralId}/${req.mapUserUniqueId}`
  );
};
const ResendOTP = (req) => {
  return axiosInstance.get(
    `hospital/ResendOTP/${req.referralId}/${req.mapUserUniqueId}`
  );
};
const LoginResendOTP = (req) => {
  return axiosInstance.post(`resend-otp`, req);
};

const accountcreation = (req) => {
  return axiosInstance.post(`hospitalBranch/accountcreation`, req.addACCData);
};
const AddAdditionalinformation = (req) => {
  return axiosInstance.post(
    `hospitalBranch/Additionalinfo/${req.user_id}/${req.user_type_id}/${req.username}/${req.password}/${req.email}`,
    req.addADDInfData
  );
};

const getusertypeDetails = () => {
  return axiosInstance.get(`hospitalBranch/getusertypeDetails`);
};

const getUserRoles = () => {
  return axiosInstance.get(`hospitalBranch/getUserRoles`);
};

const verifyLoginOtp = (req) => {
  return axiosInstance.post(`/verify-login-otp`, req, {
    title: "PatientLogin",
  });
};

const login = (data) => {
  return axiosInstance.post(`/loginscreen`, data, {
    title: "PatientLogin",
  });
};

const logindetailsget = (req) => {
  return axiosInstance.post(`/logindetailsget`, req, {
    title: "PatientLoginget",
  });
};
const loginDetailsByAdmin = (req) => {
  return axiosInstance.get(`hospitalBranch/admin/${req.user_id}`, {
    title: "loginDetailsByAdmin",
  });
};

const getbranchdetailsbyuserid = (req) => {
  return axiosInstance.post(`/branchdetailsget`, req);
};

const scoreTrend = (studyId) => {
  return axiosInstance.get(`patient/scoreTrend/${studyId}`);
};
const getdropinion = (req) => {
  return axiosInstance.get(`patient/opinion/${req.studyId}/${req.reading}`);
};
const getOpinionByRefDr = (req) => {
  return axiosInstance.get(
    `patient/opinionByRefDR/${req.selectedRefDR}/${req.studyId}/${req.reading}`
  );
};

const getReadingIdByRefDoctor = (req) => {
  return axiosInstance.get(
    `patient/readingByRefDR/${req.refDrID}/${req.studyId}`
  );
};
const deleterecord = (studyId) => {
  return axiosInstance.delete(`patient/${studyId}`);
};

const deletePatientReading = (req) => {
  return axiosInstance.delete(
    `patient/readings/${req.studyId}/${req.readingId}`
  );
};
const getbabyAppearbyreadingid = (req) => {
  return axiosInstance.get(
    `patient/baby-appear/${req.studyid}/${req.readingId}`
  );
};

const getBabyProfileByReadingId = (req) => {
  return axiosInstance.get(`patient/general/${req.study_id}`);
};
const getMotherProfileByReadingId = (req) => {
  return axiosInstance.get(`patient/material/${req.study_id}`);
};
const getBabyHealthParametersByReadingId = (req) => {
  return axiosInstance.get(
    `patient/get/babyHealthParameters/${req.studyid}/${req.readingId}`
  );
};

const getBabyRespiratoryByReadingId = (req) => {
  return axiosInstance.get(`patient/baby-resp/${req.studyid}/${req.readingId}`);
};
const getBabyCNSDataByReadingId = (req) => {
  return axiosInstance.get(`patient/baby-cns/${req.studyid}/${req.readingId}`);
};
const getBabyCVFByReadingId = (req) => {
  return axiosInstance.get(`patient/baby-cv/${req.studyid}/${req.readingId}`);
};
const getBabyGITByReadingId = (req) => {
  return axiosInstance.get(`patient/baby-git/${req.studyid}/${req.readingId}`);
};
const getBabyAntibioticDataByReadingId = (req) => {
  return axiosInstance.get(
    `patient/baby-antibiotic/${req.studyid}/${req.readingId}`
  );
};
const getBabyInvDataByReadingId = (req) => {
  return axiosInstance.get(
    `patient/baby-investigation/${req.studyid}/${req.readingId}`
  );
};

const getBabyFinalDiagByReadingId = (req) => {
  return axiosInstance.get(
    `patient/baby-final/${req.studyid}/${req.readingId}`
  );
};

const getUserDetails = (req) => {
  return axiosInstance.get(`/user/userInfo/${req.userId}`);
};
const updateProfileData = (req) => {
  return axiosInstance.put(`/user/userInfo/${req.userId}`, req.newFormData);
};
const getStaffDetails = (req) => {
  return axiosInstance.get(`user/userInfo/staff/${req.hospital_id}/1/100`);
};
const deleteUser = (userId) => {
  return axiosInstance.delete(`/user/userInfo/staff/${userId}`);
};
const updateStaffPermission = (req) => {
  return axiosInstance.put(`/user/userInfo/staff/${req.userId}`, req.data);
};

const changePassword = (req) => {
  return axiosInstance.put(
    `/user/changePassword/${req.userId}`,
    req.newFormData
  );
};

const linkReferralDoctor = (req) => {
  return axiosInstance.post(
    `/hospitalStaff/linkReferralDoctor/${req.study_id}/${req.reading}`,
    { referral_doctor_id: req.referral_doctor_id }
  );
};

const GetSepsisStatusData = (req) => {
  return axiosInstance.get(
    `reports/sepsisStatus/${req.hospital_id}/${req.branch_id}?range=${req.params.range}&start=${req.params.start}&end=${req.params.end}`,
    {
      title: "GetSepsisStatusData",
    }
  );
};
const getReadingVsSepsisData = (req) => {
  return axiosInstance.get(
    `reports/readingVsSepsis/${req.hospitalId}/${req.hospitalBranchId}/${req.bmrn}`
  );
};
const getReadingVsSepsisDataByReadingId = (req) => {
  return axiosInstance.get(
    `reports/readingVsSepsis/${req.hospitalId}/${req.hospitalBranchId}/${req.bmrn}/${req.readingid}`
  );
};
const GetBabyHealthRecordsData = (req) => {
  return axiosInstance.get(
    `reports/babyHealthRecords/${req.hospital_id}/${req.branch_id}?start=${req.params.start}&end=${req.params.end}&page=1&limit=10`
  );
};
const GetGenderDistributionData = (req) => {
  return axiosInstance.get(
    `reports/genderDistribution/${req.hospital_id}/${req.branch_id}?sepsisStatus=${req.params.sepsisStatus}&range=${req.params.range}&start=${req.params.start}&end=${req.params.end}`
  );
};

const GetPreTermCheckData = (req) => {
  return axiosInstance.get(
    `reports/preTermCheck/${req.hospital_id}/${req.branch_id}?sepsisStatus=${req.params.sepsisStatus}&range=${req.params.range}&start=${req.params.start}&end=${req.params.end}`
  );
};
const GetTypeOFDeliveryData = (req) => {
  return axiosInstance.get(
    `reports/deliveryType/${req.hospital_id}/${req.branch_id}?range=${req.params.range}&start=${req.params.start}&end=${req.params.end}`
  );
};

const GetEosVsLosData = (req) => {
  return axiosInstance.get(
    `reports/eosvslos/${req.hospital_id}/${req.branch_id}?range=${req.params.range}&start=${req.params.start}&end=${req.params.end}`
  );
};

const GetFinalVsPreliminaryData = (req) => {
  return axiosInstance.get(
    `reports/finalDiagnosis/${req.hospital_id}/${req.branch_id}?parameter=${req.ParameterType}&range=${req.params.range}&start=${req.params.start}&end=${req.params.end}`
  );
};
const GetCrpVSBloodCultureData = (req) => {
  return axiosInstance.get(
    `reports/crp/${req.hospital_id}/${req.branch_id}?bmrn=${req.params.bmrn}&sepsisStatus=${req.params.sepsisStatus}&start=${req.params.start}&end=${req.params.end}&page=1&limit=10`
  );
};

const getDashBoardStatistics = (req) => {
  return axiosInstance.get(
    `reports/statistics/${req.hospitalId}/${req.branch_id}`
  );
};
const approveUser = (req) => {
  return axiosInstance.put(`/hospitalBranch/user/status`, req);
};
const VerifyRefDr = (req) => {
  return axiosInstance.put(`/referral-doctor/verify`, req);
};
const forgotPassword = (data) => {
  return axiosInstance.post(`/forgotPassword`, data);
};
const resetPassword = (data) => {
  return axiosInstance.post(`/resetPassword`, data);
};
const rejectUser = (req) => {
  return axiosInstance.put(`/hospitalBranch/user/status`, req);
};
const getUserDetailsByVerificationKey = (verificationKey) => {
  return axiosInstance.get(
    `/hospitalBranch/getUserDetailsByVerificationKey/${verificationKey}`
  );
};
const adminVerificationKey = (req) => {
  return axiosInstance.put(`/hospitalBranch/admin/verify`, req);
};
const getRefDoctorsFromTable = (hospital_id) => {
  return axiosInstance.get(`/hospital/getRefDoctorsFromTable/${hospital_id}`);
};

const getRefDoctorsByOpinion = (req) => {
  return axiosInstance.get(
    `/hospital/getRefDoctorsByOpinion/${req.studyId}/${req.reading}`
  );
};

const getRefDoctorsByBmrn = (req) => {
  return axiosInstance.get(
    `/hospital/getRefDoctorsByBmrn/${req.studyId}`
  );
};
const getVerifiedReferralDoctors = (hospital_id) => {
  return axiosInstance.get(
    `/hospital/verified-referral-doctors/${hospital_id}`
  );
};
const deleteReferralDoctor = (referralId) => {
  return axiosInstance.delete(`hospital/referral-doctor/${referralId}`);
};
const deleteAdmin = (referralDrId) => {
  return axiosInstance.delete(`user/userInfo/staff/${referralDrId}`);
};
const activateAdmin = (referralDrId) => {
  return axiosInstance.put(`hospitalBranch/activateAdmin/${referralDrId}`, {});
};
const deleteBranch = (branch_id) => {
  return axiosInstance.delete(`hospitalBranch/branch/${branch_id}`);
};
const activateBranch = (referralDrId) => {
  return axiosInstance.put(`hospitalBranch/activateBranch/${referralDrId}`, {});
};
const activateWard = (referralDrId) => {
  return axiosInstance.put(`hospitalBranch/activateWard/${referralDrId}`, {});
};

const activateReferalDr = (referralId) => {
  return axiosInstance.put(`hospital/activateReferalDr/${referralId}`, {});
};

const deleteWard = (ward_id) => {
  return axiosInstance.delete(`hospitalBranch/ward/${ward_id}`);
};

const getDataEntryScreenByStudyID = (req) => {
  return axiosInstance.get(
    `/patient/global-record/${req.studyid}/${req.reading}`
  );
};

const getBabyBranch = (req) => {
  return axiosInstance.get(`/patient/baby-branch/${req.studyid}`);
};

const resendVerification = (req) => {
  return axiosInstance.post(
    `hospital/referral-doctor/resend/verification-mail`,
    req
  );
};

const getSendOpinion = (shareKey) => {
  return axiosInstance.get(`patient-details/${shareKey}`);
};
const getDrOpinionByReading = (req) => {
  return axiosInstance.get(
    `hospitalStaff/get-opinion/${req.studyId}/${req.reading}`
  );
};
const getPatientDetailsByStudyId = (req) => {
  return axiosInstance.get(
    `patient/medicalRecord/patient/details/${req.studyId}/${req.readingId}`
  );
};
const getPatientDetailsView = (req) => {
  return axiosInstance.get(`patient-details/${req.studyId}/${req.readingId}`);
};

const getWardsByBranch = (req) => {
  return axiosInstance.get(
    `/hospitalBranch/wards/${req.hospital_id}/${req.branch_id}`
  );
};

const createBabyQuickSepsis = (req) => {
  return axiosInstance.post(
    `patient/add/babyQuickSepsis/${req.study_id}/${req.loggedIdUserId}/${req.readingId}`,
    req.data
  );
};

const updateBabyQuickSepsis = (req) => {
  return axiosInstance.put(
    `patient/update/babyQuickSepsis/${req.study_id}/${req.loggedIdUserId}/${req.reading}`,
    req.data
  );
};

const getBabyQuickSepsis = (req) => {
  return axiosInstance.get(
    `patient/get/babyQuickSepsis/${req.studyid}/${req.readingId}`
  );
};

const createBabySemiInvasive = (req) => {
  return axiosInstance.post(
    `patient/add/babySemiInvasive/${req.study_id}/${req.loggedIdUserId}/${req.readingId}`,
    req.data
  );
};

const updateBabySemiInvasive = (req) => {
  return axiosInstance.put(
    `patient/update/babySemiInvasive/${req.study_id}/${req.loggedIdUserId}/${req.reading}`,
    req.data
  );
};

const getBabySemiInvasive = (req) => {
  return axiosInstance.get(
    `patient/get/babySemiInvasive/${req.studyid}/${req.readingId}`
  );
};

const getSubscriptionPlans = () => {
  return axiosInstance.get(`payments/plans`);
};

const getActivePlans = () => {
  return axiosInstance.get(`payments/plans/active`);
};

const getSubscriptions = () => {
  return axiosInstance.get(`payments/subscriptions`);
};

const applyCoupon = (req) => {
  return axiosInstance.post(`coupons/apply-coupon/${req.couponCode}`);
};

const initiatePayment = (req) => {
  return axiosInstance.post(`payments/initiate-payment`, req);
};

const getSubscriptionStatus = () => {
  return axiosInstance.get(`payments/subscription-status`);
};

const CommonService = {
  getUserDetails,
  updateProfileData,
  mapAdminWithBranch,
  mapUserHierarchy,
  getAdminDetail,
  getmapedAdminDetail,
  getMappedAdminDetailsByUser,
  getUserHierarchyDetail,
  getmapedBranchDetail,
  getMappedWardsOfBranch,
  addMapWithBranch,
  getWardDetail,
  addWardDetails,
  updateWardDetails,
  getWardDetailbyBranch,
  getAdminDetailByBranch,
  getBranchDetail,
  getBranchDetailByUser,
  patientSignUp,
  getPatients,
  getPatientBasicInfo,
  getIndividualBabyMedicalRecord,
  getDashboardListing,
  getDashboardListingBySearch,
  getLevel,
  patientBasicInfoUpdatedpost,
  patientCreate,
  patientBasicInfoDupUpdated,
  patientGeneralInfoUpdated,
  maternalAdd,
  createBabyHealthParameters,
  babyGitAdd,
  babyAppearAdd,
  babyRespAdd,
  babyCnsAdd,
  babyCvAdd,
  antibioticAdd,
  babyInvestigationAdd,
  finalAdd,
  searchPatient,
  createNewReading,
  getNewReading,
  updateFormData,
  getLastReadingData,
  updateBabyProfile,
  updateMaternalProfile,
  updateBabyHealthParameters,
  getScore,
  QuickSepsisScore,
  NonInvasiveScore,
  updateScore,
  downloadReport,
  addMedicalRecord,
  saveglobalrecordid,
  sendForOpinion,
  getReadingid,
  updateBabyMedicalRecord,
  multiPartFileUpload,
  listReports,
  addbranchdetails,
  getFilesDetailsFromTable,
  deleteReport,
  addReferralDoctorToTable,
  updateReferalDoctorDetails,
  getRefDoctorsFromTable,
  getRefDoctorsByOpinion,
  getRefDoctorsByBmrn,
  addAdminDetails,
  updateAdminDetails,
  addOtpToTable,
  verifyOTP,
  ResendOTP,
  accountcreation,
  AddAdditionalinformation,
  getusertypeDetails,
  login,
  logindetailsget,
  getbranchdetailsbyuserid,
  scoreTrend,
  getdropinion,
  getOpinionByRefDr,
  getReadingIdByRefDoctor,
  deleterecord,
  deletePatientReading,
  getListingByBranch,
  getPatientReadings,
  getbabyAppearbyreadingid,
  getBabyProfileByReadingId,
  getMotherProfileByReadingId,
  getBabyHealthParametersByReadingId,
  getBabyRespiratoryByReadingId,
  getBabyCNSDataByReadingId,
  getBabyCVFByReadingId,
  getBabyGITByReadingId,
  getBabyAntibioticDataByReadingId,
  getBabyInvDataByReadingId,
  ProfileFileUpload,
  motherProfileFileUpload,
  getBabyFinalDiagByReadingId,
  getStaffDetails,
  deleteUser,
  updateStaffPermission,
  changePassword,
  linkReferralDoctor,
  sendForRefDrOpinion,
  submitDrOpinion,
  GetSepsisStatusData,
  getReadingVsSepsisData,
  getReadingVsSepsisDataByReadingId,
  GetBabyHealthRecordsData,
  GetGenderDistributionData,
  GetPreTermCheckData,
  GetTypeOFDeliveryData,
  GetEosVsLosData,
  GetFinalVsPreliminaryData,
  GetCrpVSBloodCultureData,
  getDashBoardStatistics,
  rejectUser,
  approveUser,
  getUserDetailsByVerificationKey,
  babyMedicalRecordAdd,
  forgotPassword,
  resetPassword,
  addNewGlobalRecord,
  getPatientBasicDetails,
  deleteReferralDoctor,
  VerifyRefDr,
  deleteAdmin,
  activateAdmin,
  deleteBranch,
  activateBranch,
  activateWard,
  activateReferalDr,
  deleteWard,
  adminVerificationKey,
  getDataEntryScreenByStudyID,
  getBabyBranch,
  resendVerification,
  getVerifiedReferralDoctors,
  getSendOpinion,
  getPatientDetailsByStudyId,
  getPatientDetailsView,
  verifyLoginOtp,
  LoginResendOTP,
  loginDetailsByAdmin,
  getDrOpinionByReading,
  getUserRoles,
  getAdminsByRole,
  getHospitalRoles,
  updateRoleDetails,
  getFilesPrescriptionsFromTable,
  getWardsByBranch,
  getBabyQuickSepsis,
  createBabyQuickSepsis,
  updateBabyQuickSepsis,
  getBabySemiInvasive,
  createBabySemiInvasive,
  updateBabySemiInvasive,
  SemiInvasiveScore,
  getSubscriptionPlans,
  getActivePlans,
  getSubscriptions,
  applyCoupon,
  initiatePayment,
  getSubscriptionStatus,
};

export default CommonService;
