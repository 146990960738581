export const ADMIN_FORM_MOCK = {
  admin_name: "",
  contact: "",
  email: "",
  role_id: "",
};
export const initialState = {
  adminList: [],
  branchList: [],
  adminMaping: [],
  admindata: { ...ADMIN_FORM_MOCK },
};
