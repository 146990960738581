import React, { useState, useEffect } from "react";
// import { Document, Page } from "@react-pdf/renderer";
const ImageView = (props) => {
  const data = props.data;
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    if (data !== null && data.length > 0) {
      const uint8Array = new Uint8Array(data);
      const blob = new Blob([uint8Array]);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(blob);
    } else {
      setImageSrc("");
    }
  }, [data]);
  return (
    <>
      <div style={{ height: "80vh" }}>      
        <div>      
          {/* <button onClick={() => props.action(false)}>Close</button> */}
          <div wrap style={{ marginTop: 30 }}>
            {imageSrc ? (
              <img
                src={imageSrc}
                alt="Image"
                style={{ width: "auto", height: "auto" }}
              />
            ) : (
              <p>No image available.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ImageView;
