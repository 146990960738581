import { createAsyncThunk } from "@reduxjs/toolkit";

import PatientDataService from "../../api/apiCommonService";

export const login = createAsyncThunk("user/login", async (formData) => {
  // console.log("inside patient thunk");
  // console.log(formData);
  return formData;
});

export const logout = createAsyncThunk("user/logout", async (formData) => {
  // console.log("inside patient thunk");
  // console.log(formData);
  return formData;
});

export const logindetails = createAsyncThunk(
  "user/logindetail",
  async (req) => {
    const res = await PatientDataService.logindetailsget(req);
    return res.data;
  }
);

export const loginDetailsByAdmin = createAsyncThunk(
  "user/loginDetailsByAdmin",
  async (req) => {
    const res = await PatientDataService.loginDetailsByAdmin(req);
    return res.data;
  }
);

export const getbranchdetailsbyuserid = createAsyncThunk(
  "user/getbranchdetailsbyuserid",
  async (req) => {
    const res = await PatientDataService.getbranchdetailsbyuserid(req);
    return res.data;
  }
);

export const updateGlobalSearch = createAsyncThunk("", async (req) => {
  return req;
});

export const getSubscriptionStatus = createAsyncThunk(
  "getSubscriptionStatus",
  async () => {
    const res = await PatientDataService.getSubscriptionStatus();
    return res.data;
  }
);
