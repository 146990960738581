import { createSlice } from "@reduxjs/toolkit";
import * as ReportsDataThunk from "./ReportsDataThunk"
const initialState = {
    SepsisStatusData:{},
    ReadingVsSepsisData:{},
    getReadingVsSepsisDataByReadingId:{},
    BabyHealthRecordsData:{},
    GenderDistribution:{},
    PreTermCheck:{},
    TypeOFDelivery:{},
    EosVsLos:{},
    FinalVsPreliminary:{},
    CrpVSBloodCulture:{},
    DashBoardStatistics:{},
}
const ReportsSlice = createSlice({
    name: "Reports",
    initialState,
    extraReducers: {
        [ReportsDataThunk.GetSepsisStatusData.fulfilled]: (state, action) => {
            state.SepsisStatusData = action.payload.response;
        },
        [ReportsDataThunk.getReadingVsSepsisData.fulfilled]: (state, action) => {
            state.ReadingVsSepsisData = action.payload.response;
        },
        [ReportsDataThunk.getReadingVsSepsisDataByReadingId.fulfilled]: (state, action) => {
            state.ReadingVsSepsisDataByReadingId = action.payload.response;
        },
        [ReportsDataThunk.GetBabyHealthRecordsData.fulfilled]: (state, action) => {
            state.BabyHealthRecordsData = action.payload.response;
        },
        [ReportsDataThunk.GetGenderDistributionData.fulfilled]: (state, action) => {
            state.GenderDistribution = action.payload.response;
        },
        [ReportsDataThunk.GetPreTermCheckData.fulfilled]: (state, action) => {
            state.PreTermCheck = action.payload.response;
        },
        [ReportsDataThunk.GetTypeOFDeliveryData.fulfilled]: (state, action) => {
            state.TypeOFDelivery = action.payload.response;
        },
        [ReportsDataThunk.GetEosVsLosData.fulfilled]: (state, action) => {
            state.EosVsLos = action.payload.response;
        },
        [ReportsDataThunk.GetFinalVsPreliminaryData.fulfilled]: (state, action) => {
            state.FinalVsPreliminary = action.payload.response;
        },
        [ReportsDataThunk.GetCrpVSBloodCultureData.fulfilled]: (state, action) => {
            state.CrpVSBloodCulture = action.payload.response;
        },
        [ReportsDataThunk.getDashBoardStatistics.fulfilled]: (state, action) => {
            state.DashBoardStatistics = action.payload.response;
        },
        [ReportsDataThunk.setSelectedBranch.fulfilled]: (state, action) => {
            state.SelectedBranchdata = action.payload;
        }

    }
});
export default ReportsSlice.reducer;