
export const BRANCH_FORM_MOCK = {
    branch_name:"",
    contact_person:"",
    contace_number:"",
    email_id:"", 
    city:"",
    state:"", 
    pincode:"",
    address:"",
};

export const initialState = {
    
    isBranch : false,
    branchList: [],
    branchdata:{...BRANCH_FORM_MOCK},
}
