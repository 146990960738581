import React from 'react';

const AddImg = () => {
    return (
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"  className="editicon"  style={{ paddingTop:1 }}>
<g filter="url(#filter0_d_1115_2891)">
<rect x="4" width="36" height="36" rx="4" fill="#6572E4"/>
</g>
<path d="M22.168 10.0002V25.9678" stroke="white" stroke-width="2.4" stroke-linecap="round"/>
<path d="M29.9688 17.9844L14.0012 17.9844" stroke="white" stroke-width="2.4" stroke-linecap="round"/>
<defs>
<filter id="filter0_d_1115_2891" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1115_2891"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1115_2891" result="shape"/>
</filter>
</defs>
</svg>
);
}
export default AddImg;