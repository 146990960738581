import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Grid, Button, Box } from "@mui/material";

import "../../dataEntry/styles/DataEntry.scss";
import AdminAccess from "./AdminAccess";
import AdminProfileScreen from "./AdminProfileScreen";
import SubscPopUp from "../../signUp/components/SubscriptionPopup";

import { setFormTab } from "../reducers/profileDataThunk";

const AdminSteps = [
  { label: "My Profile", key: 1, className: "deactivated" },
  { label: "My Subscription", key: 2, className: "deactivated" },
];

export default function AdminProfile() {
  const user = useSelector((state) => state.user);
  const Profile = useSelector((state) => state.Profile);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = React.useState("1");
  const [steps, setSteps] = React.useState(() => {
    return AdminSteps;
  });

  const userId = user?.userProfile?.user_id;

  useEffect(() => {
    if (Profile.formTab < 4 || Profile.formTab > 0) {
      setActiveTab(Profile.formTab);
    }
  }, [Profile.formTab]);

  useEffect(() => {
    let temp = AdminSteps;
    setSteps(temp);
  }, []);

  const validateAndUpdateTab = (tabKey) => {
    if (Profile.tabKey < 12 || Profile.tabKey > 0) {
      setActiveTab(Profile.tabKey);
    } else {
      dispatch(setFormTab(tabKey));
    }
  };

  // console.log({activeTab});
  return (
    <React.Fragment>
      <div className="dataoutlet">
        <Grid container spacing={2}>
          {activeTab < 1 && (
            <Grid item xs={12} sm={9}>
              <Card variant="none">
                <CardContent></CardContent>
              </Card>
            </Grid>
          )}
          {activeTab < 12 && activeTab >= 1 && (
            <Grid item xs={12} sm={9}>
              {activeTab === 1 && <AdminProfileScreen />}
              {activeTab === 2 && <AdminAccess />}
            </Grid>
          )}

          <Grid item xs={12} sm={3}>
            <div className="formSection">
              <Card variant="none">
                <CardContent>
                  <div style={{ textAlign: "center" }}>
                    <Grid container spacing={2}>
                      {steps.map((step) => {
                        return (
                          <Grid item xs={12}>
                            <Button
                              className={step.className}
                              variant={
                                activeTab === step.key
                                  ? "contained"
                                  : "outlined"
                              }
                              onClick={() => validateAndUpdateTab(step.key)}
                            >
                              {step.label}
                            </Button>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
