import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Card, CardContent } from "@mui/material";

import FinalVsPreliminary from "./FinalVsPreliminary";
import GenderDistribution from "./GenderDistribution";
import SepsisStatus from "./SepsisStatus";
import ReadingVsSepsis from "./ReadingVsSepsicScore";
import BabyHealthRecords from "./BabyHealthRecords";
import PreTermCheck from "./PreTermCheck";
import TypeOfDelivery from "./TypeOfDelivery";
import EosVsLos from "./EosVsLos";
import CrpVsBloodCulture from "./CRPVsBloodCulture";
import ReportsHeader from "./ReportsHeader";

import "../../dataEntry/styles/DataEntry.scss";

const ReportsSteps = [
  { label: "Sepsis Status", key: 1, className: "deactivated" },
  { label: "Reading Vs Sepsis Score", key: 2, className: "deactivated" },
  { label: "Baby Health Records", key: 3, className: "deactivated" },
  { label: "Gender Distribution", key: 4, className: "deactivated" },
  { label: "Pre-Term Check", key: 5, className: "deactivated" },
  { label: "Type Of Delivery", key: 6, className: "deactivated" },
  { label: "EOS Vs LOS", key: 7, className: "deactivated" },
  { label: "Final Diagnosis", key: 8, className: "deactivated" },
  { label: "CRP Vs Blood Culture", key: 9, className: "deactivated" },
];

import { setFormTab } from "../../Profile/reducers/profileDataThunk";

export default function Reports() {
  const dispatch = useDispatch();
  const Profile = useSelector((state) => state.Profile);
  const [activeTab, setActiveTab] = React.useState(null);
  const [steps, setSteps] = React.useState(() => {
    return ReportsSteps;
  });

  useEffect(() => {
    if (Profile.formTab < 4 || Profile.formTab > 0) {
      setActiveTab(Profile.formTab);
    }
  }, [Profile.formTab]);

  useEffect(() => {
    let temp = ReportsSteps;
    setSteps(temp);
  }, []);

  const validateAndUpdateTab = (tabKey) => {
    if (Profile.tabKey < 12 || Profile.tabKey > 0) {
      setActiveTab(Profile.tabKey);
    } else {
      dispatch(setFormTab(tabKey));
    }
  };

  const branch = useSelector((state) => state.Reports?.SelectedBranchdata);

  return (
    <React.Fragment>
      <ReportsHeader />
      <div className="datacontent">
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
              {activeTab < 1 && (
                <Card variant="none">
                  <CardContent></CardContent>
                </Card>
              )}
              {activeTab < 12 && activeTab >= 1 && (
                <>
                  {activeTab === 1 && <SepsisStatus />}
                  {activeTab === 2 && <ReadingVsSepsis />}
                  {activeTab === 3 && <BabyHealthRecords />}
                  {activeTab === 4 && <GenderDistribution />}
                  {activeTab === 5 && <PreTermCheck />}
                  {activeTab === 6 && <TypeOfDelivery />}
                  {activeTab === 7 && <EosVsLos />}
                  {activeTab === 8 && <FinalVsPreliminary />}
                  {activeTab === 9 && <CrpVsBloodCulture />}
                </>
              )}
            </Grid>

            <Grid item xs={12} sm={3}>
              <div className="formSection">
                <Card variant="none">
                  <CardContent>
                    <div style={{ textAlign: "center" }}>
                      <Grid container spacing={2}>
                        {steps.map((step) => {
                          return (
                            <Grid item xs={12}>
                              <Button
                                className={step.className}
                                disabled={!branch}
                                variant={
                                  activeTab === step.key
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => validateAndUpdateTab(step.key)}
                              >
                                {step.label}
                              </Button>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </React.Fragment>
  );
}
