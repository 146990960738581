import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import Download from "../../common/assets/images/Download.svg";
// import Chart from "chart.js/auto";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import "../../poppins/Poppins-Regular-normal";
import "../../poppins/Poppins-Bold-bold";
import { Line } from "react-chartjs-2";
import { Chart } from "react-google-charts";
import { Chart as ChartJS, registerables } from "chart.js";
import { Link } from "react-router-dom";
import moment from "moment";
import Close from "../../common/assets/images/Close.svg";
import { Buffer } from "buffer";
import {
  getPatientDetails,
  setSelectedreading,
  getPatientReadings,
} from "../../dataEntry/reducers/patientDataThunk";
import {
  getReadingVsSepsisData,
  getReadingVsSepsisDataByReadingId,
} from "../reducers/ReportsDataThunk";
import "../../dataEntry/styles/DataEntry.scss";
import GauzeChart from "../../dataEntry/components/GauzeChart";

import ReactSpeedometer from "react-d3-speedometer";
import NotificationView from "../../Dashboard/components/NotificationView";
import NotificationPdf from "./NotificationPdf";

const chartOptions = {
  // tooltip: { isHtml: true },
  legend: "none",
  bar: { groupWidth: "30%" },
  hAxis: {
    title: "Date",
  },
  series: {
    0: {
      visibleInLegend: false,
    },
  },
  vAxis: {
    title: "Score",
    viewWindow: {
      min: 0,
    },

    gridlines: {
      color: "none",
    },
  },
};

const ReadingVsSepsis = () => {
  ChartJS.register(...registerables);
  const textColor = "#000000";
  ChartJS.register(...registerables);
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const [addBMRNData, setaddBMRNData] = useState(
    patient.bmrndata?.baby_medical_record_number
  );
  const [lineChartData, setLineChartData] = React.useState();
  const [chartData, setChartData] = React.useState();
  const [addScoreData, setAddScoreData] = useState({});
  const [ptReadings, setPtReadings] = useState([]);
  const [rangeType, setRangeType] = React.useState("All Readings");
  const [studyId, setStudyid] = React.useState();
  const [bmrn, setbmrn] = React.useState();
  const [selectedReadingId, setSelectedReadingId] = useState(null);
  const hospitalId = user.userProfile.hospital_id;
  const hospital_name = user.userProfile.hospital_name;
  const branch = useSelector((state) => state.Reports?.SelectedBranchdata);
  const [showChartData, setShowChartData] = React.useState(false);
  const [notificationData, setNotificationData] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [enableDownload, setEnableDownload] = React.useState(false);

  /* const hospitalBranchId = useSelector(
    (state) => state.Reports?.SelectedBranchdata
  ); */
  //const hospitalBranchId = user.branchdata.branch_id;
  const dispatch = useDispatch();

  useEffect(() => {
    const req = { studyId };
    dispatch(getPatientReadings(req)).then((resdata) => {
      let ptReadings = resdata?.payload?.response;
      ptReadings = ptReadings.filter((reading) => reading.score != null);
      setPtReadings(ptReadings);
    });
  }, [studyId]);

  React.useEffect(() => {
    if (chartData && chartData.length) {
      const labels = [],
        score = [];
      // const options = {
      //   scales: {
      //     yAxes: [
      //       {
      //         ticks: {
      //           beginAtZero: true,
      //           min: 0,
      //         },
      //       },
      //     ],
      //   },
      // };
      // chartData.map((record) => {
      //   labels.push(`R${record.reading_id}`);
      //   score.push(record.score);
      // });
      // const LineChartResponse = {
      //   labels,
      //   options: options,
      //   datasets: [
      //     {
      //       label: "Sepsis Score",
      //       borderColor: "#6572E4",
      //       data: score,
      //       options: options,
      //     },
      //   ],
      // };

      const data = [
        [
          "Reading",
          "Score",
          { role: "style" },
          { role: "annotation" },
          { role: "tooltip", type: "string", p: { html: true } },
        ],
      ];
      chartData.map((record) => {
        data.push([
          moment(record.createdAt).format("DD-MM-YY"),
          record.score,
          getColorCode(record.score),
          `${record.score} (R${record.reading_id})`,
          //`${TrendOption.score} (R${TrendOption.reading})`,
          // `Score: ${record.score.toFixed(1)}`,
          `Date: ${moment(record.createdAt).format("DD-MM-YYYY hh:mm a")}
          Score: ${record.score.toFixed(1)}`,
          // `Date: ${moment(bmrnOption.createdAt).format("DD-MM-YYYY hh:mm a")}
          // Score: ${bmrnOption.score.toFixed(1)}`,
        ]);
      });

      // setChartsData(data);
      setLineChartData(data);
      setShowChartData(true);
    } else {
      setShowChartData(false);
    }
  }, [chartData]);

  const [bmrnOptions, setBmrnOptions] = React.useState([]);

  useEffect(() => {
    const req = { hospitalId, hospitalBranchId: branch?.hospital_branch_id };
    dispatch(getPatientDetails(req));
  }, []);

  useEffect(() => {
    setBmrnOptions([{ baby_medical_record_number: "" }, ...patient.bmrnList]);
  }, [patient.bmrnList]);

  const onBmrnSelected = (e, value) => {
    setIsLoading(true);
    const bmrn = value.baby_medical_record_number;
    const studyId = value.study_id;
    setStudyid(studyId);
    setbmrn(bmrn);
    setaddBMRNData(value);
    const req = {
      hospitalId,
      hospitalBranchId: branch?.hospital_branch_id,
      bmrn,
    };
    dispatch(getReadingVsSepsisData(req))
      .unwrap()
      .then((resdata) => {
        setEnableDownload(resdata.response.readings.length ? true : false);

        setChartData(resdata.response.readings);
        setIsLoading(false);
      });
  };

  const handleReadingHistory = (e) => {
    setSelectedReadingId(e.target.value);
    dispatch(setSelectedreading(e.target.value));
    const readingid = e.target.value;
    const req = {
      hospitalId,
      hospitalBranchId: branch?.hospital_branch_id,
      bmrn,
      readingid,
    };
    dispatch(getReadingVsSepsisDataByReadingId(req))
      .unwrap()
      .then((resdata) => {
        const scoreData = resdata.response.reading?.score;
        const generateScore = {
          scoreData: scoreData.toFixed(1),
          color: getColorCode(scoreData),
          risk: riskAssess(getColorCode(scoreData)),
        };
        setAddScoreData(generateScore);
      });
  };

  const getColorCode = (score) => {
    // console.log("inside function get color code ");
    // console.log("The score is", score);
    if (score >= 0 && score <= 5) {
      return "#74D976";
    }
    if (score > 5 && score <= 8) {
      return "#F9D372";
    }
    if (score > 8 && score <= 10) {
      return "#F56D6D";
    }
  };

  const riskAssess = (colorCode) => {
    // console.log("inside function Risk assess ");
    // console.log("the color code is", colorCode);
    if (colorCode === "#74D976") {
      return "LOW RISK";
    }
    if (colorCode === "#F9D372") {
      return "MEDIUM RISK";
    }
    if (colorCode === "#F56D6D") {
      return "HIGH RISK";
    }
  };

  const div2PDF = async (e) => {
    e.preventDefault();

    let lineChart =
      window.document.getElementsByClassName("genderLineChart")[0];
    const lineChartCanvas = await html2canvas(lineChart);
    const lineChartImage = lineChartCanvas.toDataURL("image/png");

    const pdf = new jsPDF("l", "pt", "a4");
    pdf.setFont("Poppins", "normal");
    pdf.setFillColor(244, 131, 81);
    pdf.rect(50, 90, 740, 40, "F");

    pdf.setFont("Poppins-Bold", "bold");
    pdf.setFontSize(20);
    const heading = "Reading Vs Sepsis Score Report";
    pdf.setTextColor(255, 255, 255);
    pdf.text(heading, 280, 115);

    var avyantraImage = new Image();
    avyantraImage.src = "./assets/avyantra.jpg";
    pdf.addImage(avyantraImage, "jpg", 10, 10, 60, 13);

    pdf.setFontSize(10);
    pdf.setTextColor(135, 135, 135);
    pdf.text(`Generated On: ${moment().format("DD-MM-YYYY")}`, 700, 20);

    var prescoImage = new Image();
    prescoImage.src = "./assets/presco.png";
    pdf.addImage(prescoImage, "png", 380, 30, 53.5, 14.12);
    pdf.text("Neonatal Sepsis Prediction Platform", 330, 60);

    pdf.setFontSize(15);
    pdf.setTextColor(135, 135, 135);

    const labelX = {
      "Hospital: ": 55,
      "Branch: ": 400,
    };

    pdf.setFont("Poppins", "normal");
    pdf.text(`Hospital :`, labelX["Hospital: "], 160);
    pdf.text(`Branch :`, labelX["Branch: "], 160);

    pdf.setFont("Poppins-Bold", "bold");
    pdf.text(`${hospital_name}`, labelX["Hospital: "] + 75, 160);
    pdf.text(`${branch.branch_name}`, labelX["Branch: "] + 70, 160);

    pdf.addImage(lineChartImage, "png", 50, 190, 700, 300);
    const blobData = pdf.output("arraybuffer");
    const buffer = Buffer.from(blobData);
    setNotificationData(buffer);
    setDialogOpen(true);
  };

  return (
    <React.Fragment>
      <NotificationPdf
        data={notificationData}
        open={dialogOpen}
        action={setDialogOpen}
        title="Reading Vs Sepsis Score"
      />
      <div>
        <Card variant="none">
          <CardContent>
            <div>
              <div>
                {/* <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      margin: "0px auto",
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    Reading Vs Sepsis Score
                  </Grid>
                </Grid>
                <hr /> */}
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    style={{
                      // margin: "0px auto",
                      marginTop: 30,
                      // textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    Reading Vs Sepsis Score
                  </Grid>
                  <Grid item md={8} sm={8} xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="SORT BY"
                          className="dataentry_label"
                          style={{ display: "block" }}
                        >
                          Select BMRN
                        </label>
                        <Autocomplete
                          className="dataentry_input"
                          id="baby_medical_record_number"
                          name="baby_medical_record_number"
                          options={bmrnOptions}
                          getOptionLabel={(option) =>
                            option.baby_medical_record_number || ""
                          }
                          onChange={onBmrnSelected}
                          value={
                            addBMRNData?.baby_medical_record_number
                              ? addBMRNData
                              : null
                          }
                          style={{ marginTop: 10, marginRight: 10 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              id="bmrn"
                              name="bmrn"
                              className="dataentry_input"
                              //onChange={handleAddFormChange}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="SORT BY"
                          className="dataentry_label"
                          style={{ display: "block" }}
                        >
                          Reading Type
                        </label>
                        <Select
                          labelId="Reading_type"
                          id="reading_type"
                          name="reading_type"
                          label=""
                          size="small"
                          className="dataentry_input"
                          value={rangeType}
                          onChange={(e) => {
                            setRangeType(e.target.value);
                          }}
                          style={{ fontSize: "14px", padding: "3px 0px" }}
                        >
                          <MenuItem value="All Readings">All Readings</MenuItem>
                          <MenuItem value="Single Reading">
                            Single Reading
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        {rangeType === "Single Reading" ? (
                          <>
                            <Grid container>
                              <Grid item xs={12} sm={12}>
                                <label
                                  htmlFor="SORT BY"
                                  className="dataentry_label"
                                  style={{ display: "block" }}
                                >
                                  Reading Id
                                </label>
                                <Select
                                  labelId="Reading_id"
                                  id="reading_id"
                                  name="reading_id"
                                  label=""
                                  size="small"
                                  className="dataentry_input"
                                  value={selectedReadingId}
                                  onChange={handleReadingHistory}
                                >
                                  {ptReadings && ptReadings.length
                                    ? ptReadings.map((num) => (
                                        <MenuItem value={num.reading_id}>
                                          R{num.reading_id}
                                        </MenuItem>
                                      ))
                                    : undefined}
                                </Select>
                              </Grid>
                            </Grid>
                          </>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Button
                          variant="contained"
                          size="small"
                          className="report-download-button"
                          disabled={isLoading || !enableDownload}
                          onClick={(e) => div2PDF(e)}
                        >
                          Download Report
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <hr className="tag-line-border" />
              {isLoading ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    height: "480px",
                  }}
                >
                  <Grid sm={1}>
                    <CircularProgress color="success" />
                  </Grid>
                </Grid>
              ) : (
                <>
                  {rangeType === "All Readings" ? (
                    <div className="genderLineChart">
                      <div className="chart_data">
                        {lineChartData && showChartData ? (
                          // <Line
                          //   data={lineChartData}
                          //   options={options}
                          //   className="donut_class"
                          // />
                          <Chart
                            chartType="ColumnChart"
                            options={chartOptions}
                            width="100%"
                            height="400px"
                            data={lineChartData}
                          />
                        ) : (
                          <>
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                height: "480px",
                              }}
                            >
                              <Grid sm={4}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    alignItems: "center",
                                    margin: "1rem 0",
                                  }}
                                >
                                  No data Found
                                </div>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </div>
                    </div>
                  ) : null}
                  {rangeType === "Single Reading" ? (
                    <div>
                      {selectedReadingId !== null ? (
                        <Grid container className="genderLineChart">
                          <Grid item xs={12} sm={6} className="score-grid">
                            <p
                              className="BMRNScoreGeneration"
                              style={{ marginTop: "0px" }}
                            >
                              Reading: <span>R{selectedReadingId}</span>
                            </p>
                            {selectedReadingId !== null ? (
                              <div
                                // className="GauzeChart"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                  marginTop: "1rem",
                                }}
                              >
                                {addScoreData && (
                                  <GauzeChart data={addScoreData} />
                                )}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                  alignItems: "center",
                                  marginTop: "1rem",
                                }}
                              >
                                Please Select Reading Id
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={6} className="table-grid">
                            <h4>Score Risk Range</h4>
                            <TableContainer
                              style={{
                                marginTop: "2rem",
                                borderRadius: "0.6rem",
                                border: "1px solid #C1C6F4",
                              }}
                            >
                              <Table aria-label="simple table">
                                <TableHead className="TableHead">
                                  <TableRow className="TableRow">
                                    <TableCell align="center" id="table-cell">
                                      Risk Type
                                    </TableCell>
                                    <TableCell align="center" id="table-cell">
                                      Score Range
                                    </TableCell>
                                    <TableCell align="center" id="table-cell">
                                      Color
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="center" id="table-cell">
                                      Low Risk
                                    </TableCell>
                                    <TableCell align="center" id="table-cell">
                                      0.1 - 5.0
                                    </TableCell>
                                    <TableCell align="center" id="table-cell">
                                      <span className="color-indicator bg-green"></span>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" id="table-cell">
                                      Medium Risk
                                    </TableCell>
                                    <TableCell align="center" id="table-cell">
                                      5.1 - 8.0
                                    </TableCell>
                                    <TableCell align="center" id="table-cell">
                                      <span className="color-indicator bg-orange"></span>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" id="table-cell">
                                      High Risk
                                    </TableCell>
                                    <TableCell align="center" id="table-cell">
                                      8.1 - 10.0
                                    </TableCell>
                                    <TableCell align="center" id="table-cell">
                                      <span className="color-indicator bg-red"></span>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            height: "480px",
                          }}
                        >
                          <Grid sm={4}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                alignItems: "center",
                                margin: "1rem 0",
                              }}
                            >
                              Please select reading
                            </div>
                          </Grid>
                        </Grid>
                      )}
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default ReadingVsSepsis;
