const PrivacyPolicy = (props) => {
  return (
    <div className="terms_box">
      <h4>GENERAL</h4>
      <p>
        This Application with the name of PreSco ("App/PreSco") is operated by
        Avyantra Health Technologies Pvt Ltd("/We/Our/Us"). We are committed to
        protecting and respecting your privacy. We do collect your personal
        information and process your personal data in accordance with the IT
        Act, 2000 (21 of 2000) and other national and state laws which relate to
        the processing of personal data. Please read the following carefully to
        understand our views and practices regarding your personal data..
      </p>
      <p>
        Downloading, accessing, or otherwise using the App indicates that you
        have read this Privacy Policy and consent to its terms. If you do not
        consent to the terms of this Privacy Policy, do not proceed to download,
        access, or otherwise use the App.
      </p>
      <p>
        We collect your personal information in order to provide and continually
        improve our products and services.
      </p>
      <p>
        Our privacy policy is subject to change at any time without notice. To
        make sure you are aware of any changes, please review this policy
        periodically. The last updated date can be found at the beginning of
        this policy.
      </p>
      <p>
        All partner firms and any third-party working with or for Us, and who
        have access to personal information, will be expected to read and comply
        with this policy.
      </p>
      <h4>HOW WE COLLECT THE INFORMATION</h4>
      <p>
        From you directly and through this App: We may collect information
        through the App when you visit. The data we collect depends on the
        context of your interactions with our App.
      </p>
      <p>
        Through business interaction: We may collect information through
        business interaction with you or your employees.
      </p>
      <p>
        From other sources: We may receive information from other sources, such
        as public databases; joint marketing partners; social media platforms;
        or other third parties such as:
      </p>

      <p>
        Updated delivery and address information from our carriers or other
        third parties, which we use to correct our records and deliver your next
        purchase or communication more easily.
      </p>
      <p>
        Information about your interactions with the products and services
        offered by our subsidiaries.
      </p>

      <h4>INFORMATION WE COLLECT</h4>
      <p>
        We collect information primarily to provide better services to all of
        our customers.
      </p>
      <p>
        We collect the following information from you when you use or signup on
        our App: Name, Email address, Phone no, Identify proof, Hospital Details
      </p>
      <p>
        We do collect the following sensitive information when you use or signup
        on our App: Health data of the Neonate and Mother related to Sepsis
      </p>
      <p>
        When you visit our App, some information is automatically collected.
        This may include information such as the type of mobile device,
        Operating System (OS) running on your device, Internet Protocol (IP)
        address, unique user ID, access times, device type, and language. We
        also collect information about how you use Our products or services.
      </p>
      <p>
        We automatically collect purchase or content use history, which we
        sometimes aggregate with similar information from other customers to
        create features such as Best Seller, Top Rated, etc...
      </p>
      <p>
        The information about your usage of the App, including crash logs and
        usage statistics.
      </p>
      <p>
        We will retain your information as long as we require this to provide
        you with the goods and services and for such period as mandated by the
        laws concerned.
      </p>
      <p>
        If you opt to receive marketing correspondence from us, subscribe to our
        mailing list or newsletters, enter into any of our competitions or
        provide us with your details at networking events, we may use your
        personal data for our legitimate interests in order to provide you with
        details about our goods, services, business updates and events.
      </p>

      <h4>HOW WE USE INFORMATION</h4>
      <p>
        We use the information we collect primarily to provide, maintain,
        protect, and improve our current products and services.
      </p>

      <p>
        We use the information collected through this App as described in this
        policy and we may use your information to:
      </p>
      <p>Improve our services, App and how we operate our businesses;</p>
      <p>
        Understand and enhance your experience using our App, products and
        services;
      </p>
      <p> Personalize our products or services and make recommendations;</p>
      <p> Provide and deliver products and services you request;</p>
      <p>Process, manage, complete, and account for transactions;</p>
      <p>
        Provide customer support and respond to your requests, comments, and
        inquiries;
      </p>
      <p>Create and manage the online accounts you manage on our App;</p>
      <p>
        {" "}
        Send you related information, including confirmations, invoices,
        technical notices, updates, security alerts and support and
        administrative messages;
      </p>
      <p>
        Communicate with you about promotions, upcoming events, and news about
        products and services;
      </p>
      <p>
        We may process your personal information without your knowledge or
        consent where required by applicable law or regulation for the purposes
        of verification of identity or for prevention, detection, or
        investigation, including of cyber incidents, prosecution, and punishment
        of offences;
      </p>
      <p>
        {" "}
        Protect, investigate, and deter against fraudulent, unauthorized, or
        illegal activity.
      </p>

      <h4>DATA TRANSFER</h4>
      <p>
        Information about our users is an important part of our business and we
        take due care.
      </p>
      <p>
        We share your data with your consent or to complete any transaction or
        provide any product or service you have requested or authorized. We also
        share data with our affiliates and subsidiaries, with vendors working on
        our behalf.
      </p>
      <p>
        We may employ other companies and individuals to perform functions on
        our behalf. The functions include fulfilling orders for products or
        services, delivering packages, sending postal mail and e-mail, removing
        repetitive information from customer lists, providing marketing
        assistance, providing search results and links (including paid listings
        and links), processing payments, transmitting content, scoring credit
        risk, and providing customer service.
      </p>

      <p>
        These third-party service providers have access to personal information
        needed to perform their functions but may not use it for other purposes.
        Further, they must process the personal information in accordance with
        this Privacy Policy and as permitted by applicable data protection laws.
      </p>
      <p>
        We release accounts and other personal information when we believe it is
        appropriate to comply with the law, enforce or apply our conditions of
        use, and other agreements, protect the rights, property or safety of Us,
        our users, or others. This includes exchanging information with other
        companies and organizations for fraud protection and credit risk
        reduction.
      </p>

      <h4> DATA SECURITY</h4>
      <p>
        We take due care to protect customer data. Technical measures are in
        place to prevent unauthorized or unlawful access to data and against
        accidental loss or destruction of, or damage to the data. The employees
        who are dealing with the data have been trained to protect the data from
        any illegal or unauthorized usage.
      </p>
      <p>
        We maintain physical, electronic, and procedural safeguards in
        connection with the collection, storage, and disclosure of personal
        customer information.
      </p>
      <p>
        We take reasonable steps to help protect your personal information in an
        effort to prevent the loss, misuse, and unauthorized access, disclosure
        alteration and destruction. It is your responsibility to protect your
        user names and passwords to help prevent anyone from accessing or
        abusing your accounts and services. You should not use or reuse the same
        passwords you use with other accounts as your password for our services.
      </p>
      <p>
        It is important for you to protect against unauthorized access to your
        password and your devices, and applications. Be sure to sign off when
        you finish using a non-personal device.
      </p>
      <p>
        The information you provide to us is shared on our secure servers. We
        have implemented appropriate physical, technical and organizational
        measures designed to secure your information against accidental loss and
        unauthorized access, use, alteration, or disclosure. In addition, we
        limit access to personal data to those employees, agents, contractors,
        and other third parties that have a legitimate business need for such
        access.
      </p>
      <p>
        Information collected from you will be stored for such period as
        required to complete the transaction entered into with you or such
        period as mandated under the applicable laws.
      </p>

      <h4>LINKS TO THIRD PARTY SITE/APPS</h4>
      <p>
        Our App may, from time to time, contain links to and from other
        Apps/Websites of third parties. Please note that if you follow a link to
        any of these Apps/Websites, such Apps/Websites will apply different
        terms to the collection and privacy of your personal data, and we do not
        accept any responsibility or liability for these policies. When you
        leave our Site, we encourage you to read the privacy policy of every
        App/Website you visit.
      </p>

      <h4>SHARING OF PERSONAL INFORMATION</h4>
      <p>
        We do not share your personal data with third parties without your prior
        consent other than:
      </p>
      <p>
        With third parties who work on our behalf provided such third parties
        adhere to the data protection principles set out in the IT Act, 2000 (21
        of 2000) and other applicable legislation, or enter into a written
        agreement with Us requiring that the third party provide at least the
        same level of privacy protection as is required by such principles;
      </p>
      <p>
        To comply with laws or to respond to lawful requests and legal process;
      </p>
      <p>
        To protect the rights and property of Us, our agents, customers, and
        others including to enforce our agreements, policies and terms of use;
      </p>
      <p>
        In an emergency, including to protect the personal safety of any person;
        and
      </p>
      <p>
        For the purpose of a business deal (or negotiation of a business deal)
        involving the sale or transfer of all or a part of our business or
        assets (business deals may include, for example, any merger, financing,
        acquisition, divestiture or bankruptcy transaction or proceeding).
      </p>

      <h4>CHILDREN</h4>
      <p>
        If you are under the age of 18 or the age of majority in the
        jurisdiction in which you reside, you may only use Our App with the
        consent of your parent or legal guardian. In any case, We will not be
        liable for any cause of action that arose due to non-compliance with
        this section.
      </p>

      <h4>YOUR INFORMATION CHOICES AND CHANGES</h4>
      <p>
        You can also make choices about the collection and processing of your
        data by Us. You can access your personal data and opt-out of certain
        services provided by the Us. In some cases, your ability to control and
        access to your data will be subject to applicable laws.
      </p>
      <p>
        You may opt-out of receiving promotional emails from Us by following the
        instructions in those emails. If you opt-out, we may still send you non-
        promotional emails, such as emails about our ongoing business
        relationship. You may also send requests about you got preferences,
        changes and deletions to your information including requests to opt-out
        of sharing your personal information with third parties by sending an
        email to the email address provided at the bottom of this document.
      </p>

      <h4>CHANGES TO THIS POLICY</h4>
      <p>
        We may change this policy from time to time. If we make any changes to
        this policy, we will change the &quot;Last Updated&quot; date above. You
        agree that your continued use of our services after such changes have
        been published to our services will constitute your acceptance of such
        revised policy.
      </p>
      <p>
        If you have any concern about privacy or grievances with Us, please
        contact us with a thorough description and we will try to resolve the
        issue for you.
      </p>

      <h4>Contact Details:</h4>
      <p>
        Managing Director, Avyantra Health Technologies Pvt Ltd, Hyderabad. mail
        : avyantra@gmail.com
      </p>
    </div>
  );
};

export default PrivacyPolicy;
