import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  InputAdornment,
  FormGroup,
} from "@mui/material";
import "../styles/DataEntry.scss";
import { ToastContainer, toast } from "react-toastify";
import {
  setFormTab,
  getMotherProfileByReadingId,
  getBabyProfileByReadingId,
  createBabySemiInvasive,
  updateBabySemiInvasive,
  getBabySemiInvasive,
} from "../reducers/patientDataThunk";
import { SKIN_COLOR, CRYSOUND, URINE_OUTPUT } from "../utils/mockData";
import CircularProgress from "@mui/material/CircularProgress";
const Numerics1 = /^[0-9 ]*$/;
const Numerics = /^[0-9 ]*$/;

const BabySemiInvasive = (props) => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const login = useSelector((state) => state.login);
  const dbbranchdata = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const hospital_id = user.userProfile.hospital_id;
  const study_id = patient.bmrndata?.study_id;
  const studyid = patient.bmrndata?.study_id;
  const reading = patient?.Selectedreadingid;
  const readingId =
    JSON.stringify(reading) != "{}" ? reading : patient?.bmrndata?.reading_id;
  const loggedUserId = user.userProfile?.user_id;
  const adddata = patient.babyHealthParameters;
  const [addFormData, setAddFormData] = useState(adddata || {});
  const [updateRecord, setUpdateRecord] = useState();
  const [errorFields, setErrorFields] = useState({});
  const [
    showFrequencyOfStoolsErrorMessage,
    setShowFrequencyOfStoolsErrorMessage,
  ] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showBPLLErrorMessage, setShowBPLLErrorMessage] = useState();
  const [showBPULErrorMessage, setShowBPULErrorMessage] = useState();
  const [showBPMAErrorMessage, setShowBPMAErrorMessage] = useState();
  const [showHeartRateErrorMessage, setShowHeartRateErrorMessage] = useState();
  const [showBreathingRateErrorMessage, setShowBreathingRateErrorMessage] =
    useState();
  const [
    showOxygenSaturationErrorMessage,
    setShowOxygenSaturationErrorMessage,
  ] = useState();
  const [
    showAbsoluteNeutrophilCountErrorMessage,
    setShowAbsoluteNeutrophilCountErrorMessage,
  ] = useState();
  const [
    showTotalLeucocuteCountErrorMessage,
    setShowTotalLeucocuteCountErrorMessage,
  ] = useState();
  const [
    showBabyBloodGlucoseErrorMessage,
    setShowBabyBloodGlucoseErrorMessage,
  ] = useState();
  const [showCRPLevelErrorMessage, setShowCRPLevelErrorMessage] = useState();
  const [showBilirubinLevelsErrorMessage, setShowBilirubinLevelsErrorMessage] =
    useState();
  const [showChlorineErrorMessage, setShowChlorineErrorMessage] = useState();
  const [showCalciumErrorMessage, setShowCalciumErrorMessage] = useState();
  const [showCreatinineErrorMessage, setShowCreatinineErrorMessage] =
    useState();
  const [showSodiumErrorMessage, setShowSodiumErrorMessage] = useState();
  const [showPotassiumErrorMessage, setShowPotassiumErrorMessage] = useState();
  const [showUreaErrorMessage, setShowUreaErrorMessage] = useState();
  const [
    showThrombocytopeniaErrorMessage,
    setShowThrombocytopeniaErrorMessage,
  ] = useState();

  const [
    showBabyHaemoglobinLevelsErrorMessage,
    setShowBabyHaemoglobinLevelsErrorMessage,
  ] = useState();
  const [isValid, setValid] = React.useState(true);
  useEffect(() => {
    verifyErrorFields();
  }, [errorFields]);

  const verifyErrorFields = async () => {
    let enableSubmitButton = true;
    const keys = Object.keys(errorFields);
    keys.map((key) => {
      errorFields[key] !== false ? (enableSubmitButton = false) : null;
    });
    setValid(enableSubmitButton);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (patient?.bmrndata?.reading_id && patient.bmrndata?.study_id) {
      updateData(patient?.bmrndata?.reading_id, patient.bmrndata?.study_id);
    }
  }, [patient?.bmrndata?.reading_id, patient.bmrndata?.study_id]);

  const updateData = (readingId, studyid) => {
    const req = { readingId, studyid };

    dispatch(getBabySemiInvasive(req))
      .unwrap()
      .then((resdata) => {
        let SemiInvData = {
          response: Object.assign({}, resdata.data.response[0]),
        };
        const data =
          SemiInvData.response && SemiInvData.response
            ? SemiInvData.response
            : undefined;
        const data1 =
          SemiInvData.response && SemiInvData.response.groaning
            ? setUpdateRecord(true)
            : setUpdateRecord(false);
        if (SemiInvData.response) {
          setAddFormData(
            SemiInvData.response && JSON.stringify(SemiInvData.response) != "{}"
              ? SemiInvData.response
              : {}
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;
    const fieldvalue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldname] = fieldvalue;
    setAddFormData(newFormData);
  };
  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();
    await submitData(12);
    const req = { study_id };
    dispatch(getMotherProfileByReadingId(req));
    dispatch(getBabyProfileByReadingId(req));
  };

  useEffect(() => {
    if (props.triggerChildFunction) submitData();
  }, [props.triggerChildFunction]);
  const submitData = async (nextTab) => {
    const newFormData = { ...addFormData };
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    newFormData.study_id = patient.bmrndata?.study_id;
    newFormData.loggedUserId = loggedUserId;
    newFormData.reading =
      JSON.stringify(readingId) != "{}"
        ? readingId
        : patient?.bmrndata?.reading_id;
    if (!updateRecord) {
      const req = {
        study_id,
        data: newFormData,
        readingId,
      };
      dispatch(createBabySemiInvasive(req))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getBabySemiInvasive(req)).then(() => {
            if (props.triggerChildFunction) {
              dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
            } else {
              toast.success("Data Updated Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          });
        });
    } else {
      newFormData.reading = patient.Selectedreadingid;
      const req = {
        study_id,
        reading:
          JSON.stringify(readingId) != "{}"
            ? readingId
            : patient?.bmrndata?.reading_id,
        data: newFormData,
        loggedUserId,
      };
      dispatch(updateBabySemiInvasive(req)).then((resdata) => {
        const req = { readingId, studyid };
        dispatch(getBabySemiInvasive(req)).then(() => {
          if (props.triggerChildFunction) {
            dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
          } else {
            toast.success("Data Updated Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
      });
    }
  };

  const verifyBreathingRate = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          breathing_rate: false,
        })
      );
      setShowBreathingRateErrorMessage("");
    } else {
      setShowBreathingRateErrorMessage(
        "Breathing Rate Value Should be Between 0 To 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          breathing_rate: true,
        })
      );
    }
  };
  const verifyOxygenSaturation = (event) => {
    if (event.target.value >= 0 && event.target.value <= 100) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          oxygen_saturation: false,
        })
      );
      setShowOxygenSaturationErrorMessage("");
    } else {
      setShowOxygenSaturationErrorMessage(
        "Oxygen Saturation Value Should be Between 0 To 100"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          oxygen_saturation: true,
        })
      );
    }
  };

  const verifyBabyBloodPressureUpperLimb = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_upper_limb: false,
        })
      );
      setShowBPULErrorMessage("");
    } else {
      setShowBPULErrorMessage("BP (Systolic) Value Should be Between 0 To 200");
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_upper_limb: true,
        })
      );
    }
  };

  const verifyBabyBloodPressureLowerLimb = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_lower_limb: false,
        })
      );
      setShowBPLLErrorMessage("");
    } else {
      setShowBPLLErrorMessage(
        "BP (Diastolic) Value Should be Between 0 To 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_lower_limb: true,
        })
      );
    }
  };

  const verifyBabyBloodPressureMeanArterial = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_mean_arterial_bp: false,
        })
      );

      setShowBPMAErrorMessage("");
    } else {
      setShowBPMAErrorMessage(
        "BP (Mean Arterial) Value Should be Between 0 To 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_mean_arterial_bp: true,
        })
      );
    }
  };
  const verifyFrequencyOfStools = (event) => {
    if (event.target.value >= 0 && event.target.value <= 12) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          frequency_of_stools: false,
        })
      );
      setShowFrequencyOfStoolsErrorMessage("");
    } else {
      setShowFrequencyOfStoolsErrorMessage(
        "Frequency Of Stools Value Should be Between 0 to 12"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          frequency_of_stools: true,
        })
      );
    }
  };

  const verifyTotalLeucocytesCount = (event) => {
    if (event.target.value > 1000 && event.target.value <= 500000) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          total_leucocute_count: false,
        })
      );
      setShowTotalLeucocuteCountErrorMessage("");
    } else {
      setShowTotalLeucocuteCountErrorMessage(
        "Total Leucocytes Count Value Should be Between 1000 - 500000."
      );
      //document.getElementById("TotalLeucocytesCount").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          total_leucocute_count: true,
        })
      );
    }
  };
  const verifyAbsoluteNeutrophilCount = (event) => {
    if (event.target.value >= 0 && event.target.value <= 500000) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          absolute_neutrophil_count: false,
        })
      );
      setShowAbsoluteNeutrophilCountErrorMessage("");
    } else {
      setShowAbsoluteNeutrophilCountErrorMessage(
        "Absolute Neutrophil Count Value Should be Between 0 - 500000"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          absolute_neutrophil_count: true,
        })
      );
    }
  };

  const verifyBabyBloodGlucose = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_glucose: false,
        })
      );
      setShowBabyBloodGlucoseErrorMessage("");
    } else {
      setShowBabyBloodGlucoseErrorMessage(
        "Baby Blood Glucose Value Should be Between 0 - 20, it should have only two decimal positions."
      );
      //document.getElementById("BabyBloodGlucose").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_glucose: true,
        })
      );
    }
  };

  const verifyBabyHaemoglobinLevels = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 10,
      maxValue = 45;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_haemoglobin_levels: false,
        })
      );
      setShowBabyHaemoglobinLevelsErrorMessage("");
    } else {
      setShowBabyHaemoglobinLevelsErrorMessage(
        "Baby Haemoglobin Levels Value Should be Between 10 - 45, it should have only two decimal positions."
      );
      //document.getElementById("BabyHaemoglobinLevels").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_haemoglobin_levels: true,
        })
      );
    }
  };

  const verifyCReactiveProtien = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_c_reactive_protien_levels: false,
        })
      );
      setShowCRPLevelErrorMessage("");
    } else {
      setShowCRPLevelErrorMessage(
        "C Reactive Protien Value Should be Between 0 - 20, it should have only two decimal positions."
      );
      //document.getElementById("CReactiveProtien").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_c_reactive_protien_levels: true,
        })
      );
    }
  };

  const verifyBilirubinLevels = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 200;
    if (
      event.target.value <= maxValue &&
      event.target.value > minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          bilirubin_levels: false,
        })
      );
      setShowBilirubinLevelsErrorMessage("");
    } else {
      setShowBilirubinLevelsErrorMessage(
        "Bilirubin Levels Value Should be Between 0.1 - 200, it should have only two decimal positions."
      );
      //document.getElementById("BilirubinLevels").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          bilirubin_levels: true,
        })
      );
    }
  };

  const verifyChlorine = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value > minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          chlorine: false,
        })
      );
      setShowChlorineErrorMessage("");
    } else {
      setShowChlorineErrorMessage(
        "Chlorine Value Should be Between 0.1 - 20, it should have only two decimal positions."
      );
      //document.getElementById("Chlorine").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          chlorine: true,
        })
      );
    }
  };

  const verifyCalcium = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");

    let minValue = 0,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value > minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          calcium: false,
        })
      );
      setShowCalciumErrorMessage("");
    } else {
      setShowCalciumErrorMessage(
        "Calcium Value Should be Between 0.1 - 20, it should have only two decimal positions."
      );
      //document.getElementById("Calcium").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          calcium: true,
        })
      );
    }
  };

  const verifyCreatinine = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          creatinine: false,
        })
      );
      setShowCreatinineErrorMessage("");
    } else {
      setShowCreatinineErrorMessage(
        "Creatinine Value Should be Between 0.1 - 20, it should have only two decimal positions."
      );
      //document.getElementById("Creatinine").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          creatinine: true,
        })
      );
    }
  };

  const verifySodium = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 200;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          sodium: false,
        })
      );
      setShowSodiumErrorMessage("");
    } else {
      setShowSodiumErrorMessage(
        "Sodium Value Should be Between 1 - 200, it should have only two decimal positions."
      );
      //document.getElementById("Sodium").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          sodium: true,
        })
      );
    }
  };

  const verifyUrea = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          urea: false,
        })
      );
      setShowUreaErrorMessage("");
    } else {
      setShowUreaErrorMessage(
        "Urea Value Should be Between 0.1 - 20, it should have only two decimal positions."
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          urea: true,
        })
      );
    }
  };

  const verifyPotassium = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");

    let minValue = 0,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value > minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          potassium: false,
        })
      );
      setShowPotassiumErrorMessage("");
    } else {
      setShowPotassiumErrorMessage(
        "Potassium Value Should be Between 0.1 - 20, it should have only two decimal positions."
      );
      //document.getElementById("Potassium").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          potassium: true,
        })
      );
    }
  };

  const verifyHeartRate = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          heart_rate: false,
        })
      );
      setShowHeartRateErrorMessage("");
    } else {
      setShowHeartRateErrorMessage(
        "Heart Rate Value Should be Between 0 To 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          heart_rate: true,
        })
      );
    }
  };

  const verifyThrombocytopenia = (event) => {
    if (event.target.value >= 1 && event.target.value <= 300000) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          thrombocytopenia: false,
        })
      );
      setShowThrombocytopeniaErrorMessage("");
    } else {
      setShowThrombocytopeniaErrorMessage(
        "Thrombocytopenia Should be Between 1 - 300000"
      );
      //document.getElementById("Thrombocytopenia").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          thrombocytopenia: true,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {isLoading ? (
        <div
          style={{
            height: "370px",
            paddingTop: "230px",
            textAlign: "center",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        <Card variant="none">
          <CardContent>
            <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Groaning"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Groaning
                    </label>
                  <ToggleButtonGroup
                    color="primary"
                    name="groaning"
                    id="diagnosis"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    value={addFormData?.groaning}
                    //required
                  >
                    <ToggleButton
                      name="groaning"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="groaning"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="groaning"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Oxygen Saturation"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Oxygen Saturation
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <FormGroup row>
                    <TextField
                      id="OxygenSaturation"
                      label=""
                      name="oxygen_saturation"
                      variant="outlined"
                      size="small"
                      className={
                        "dataentry_input " +
                        (errorFields.oxygen_saturation ? "errorField" : "")
                      }
                      value={addFormData?.oxygen_saturation}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        handleAddFormChange(e);
                        verifyOxygenSaturation(e);
                      }}
                      error={
                        errorFields.oxygen_saturation &&
                        showOxygenSaturationErrorMessage
                      }
                      errorText={showOxygenSaturationErrorMessage}
                      helperText={
                        errorFields.oxygen_saturation &&
                        showOxygenSaturationErrorMessage
                          ? showOxygenSaturationErrorMessage
                          : ""
                      }
                      onKeyDown={(event) => {
                        if (
                          !Numerics.test(event.key) &&
                          event.key != "Backspace"
                        ) {
                          event.preventDefault();
                          setShowOxygenSaturationErrorMessage(
                            "Only Numerics are Acceptable"
                          );
                          setErrorFields(
                            Object.assign({}, errorFields, {
                              oxygen_saturation: true,
                            })
                          );
                        }
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Breathing Rate"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Breathing Rate (Breaths Per Minute)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="BreathingRate"
                    name="breathing_rate"
                    variant="outlined"
                    size="small"
                    //required
                    className={
                      "dataentry_input " +
                      (errorFields.breathing_rate ? "errorField" : "")
                    }
                    value={addFormData?.breathing_rate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          Per Minute
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBreathingRate(e);
                    }}
                    error={
                      errorFields.breathing_rate &&
                      showBreathingRateErrorMessage
                    }
                    errorText={showBreathingRateErrorMessage}
                    helperText={
                      errorFields.breathing_rate &&
                      showBreathingRateErrorMessage
                        ? showBreathingRateErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBreathingRateErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            breathing_rate: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="HeartRate"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Heart Rate
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="heart_rate"
                    label=""
                    name="heart_rate"
                    variant="outlined"
                    size="small"
                    className="dataentry_input"
                    //required
                    value={addFormData?.heart_rate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          bpm
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyHeartRate(e);
                    }}
                    error={errorFields.heart_rate && showHeartRateErrorMessage}
                    errorText={showHeartRateErrorMessage}
                    helperText={
                      errorFields.heart_rate && showHeartRateErrorMessage
                        ? showHeartRateErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowHeartRateErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            heart_rate: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="BP (Systolic)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    BP (Systolic)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="BabyBloodPressureUpperLimb"
                    name="baby_blood_pressure_upper_limb"
                    className={
                      "dataentry_input " +
                      (errorFields.baby_blood_pressure_upper_limb
                        ? "errorField"
                        : "")
                    }
                    variant="outlined"
                    //required
                    size="small"
                    value={addFormData?.baby_blood_pressure_upper_limb}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyBloodPressureUpperLimb(e);
                    }}
                    error={
                      errorFields.baby_blood_pressure_upper_limb &&
                      showBPULErrorMessage
                    }
                    errorText={showBPULErrorMessage}
                    helperText={
                      errorFields.baby_blood_pressure_upper_limb &&
                      showBPULErrorMessage
                        ? showBPULErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBPULErrorMessage("Only Numerics are Acceptable");
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_blood_pressure_upper_limb: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mmHg
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="BP (Diastolic)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    BP (Diastolic)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    name="baby_blood_pressure_lower_limb"
                    id="BabyBloodPressureLowerLimb"
                    className={
                      "dataentry_input " +
                      (errorFields.baby_blood_pressure_lower_limb
                        ? "errorField"
                        : "")
                    }
                    variant="outlined"
                    size="small"
                    //required
                    value={addFormData?.baby_blood_pressure_lower_limb}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyBloodPressureLowerLimb(e);
                    }}
                    error={
                      errorFields.baby_blood_pressure_lower_limb &&
                      showBPLLErrorMessage
                    }
                    errorText={showBPLLErrorMessage}
                    helperText={
                      errorFields.baby_blood_pressure_lower_limb &&
                      showBPLLErrorMessage
                        ? showBPLLErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBPLLErrorMessage("Only Numerics are Acceptable");
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_blood_pressure_lower_limb: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mmHg
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="BP (Mean Arterial)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    BP (Mean Arterial)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    name="baby_blood_pressure_mean_arterial_bp"
                    id="BabyBloodPressureMeanArterial"
                    className={
                      "dataentry_input " +
                      (errorFields.baby_blood_pressure_mean_arterial_bp
                        ? "errorField"
                        : "")
                    }
                    variant="outlined"
                    //required
                    size="small"
                    value={addFormData?.baby_blood_pressure_mean_arterial_bp}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyBloodPressureMeanArterial(e);
                    }}
                    error={
                      errorFields.baby_blood_pressure_mean_arterial_bp &&
                      showBPMAErrorMessage
                    }
                    errorText={showBPMAErrorMessage}
                    helperText={
                      errorFields.baby_blood_pressure_mean_arterial_bp &&
                      showBPMAErrorMessage
                        ? showBPMAErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setshowBPMAErrorMessage("Only Numerics are Acceptable");
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_blood_pressure_mean_arterial_bp: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mmHg
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="AbdominalDistension"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Abdominal Distension
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.abdominal_dystension}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    //required
                  >
                    <ToggleButton
                      name="abdominal_dystension"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="abdominal_dystension"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="abdominal_dystension"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Frequency of Stools)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Frequency of Stools
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    name="frequency_of_stools"
                    id="FrequencyOfStools"
                    className={
                      "dataentry_input " +
                      (errorFields.frequency_of_stools ? "errorField" : "")
                    }
                    variant="outlined"
                    size="small"
                    //required
                    value={addFormData?.frequency_of_stools}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyFrequencyOfStools(e);
                    }}
                    error={
                      errorFields.frequency_of_stools &&
                      showFrequencyOfStoolsErrorMessage
                    }
                    errorText={showFrequencyOfStoolsErrorMessage}
                    helperText={
                      errorFields.frequency_of_stools &&
                      showFrequencyOfStoolsErrorMessage
                        ? showFrequencyOfStoolsErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowFrequencyOfStoolsErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            frequency_of_stools: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Thrombocytopenia (Platelet Count)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Thrombocytopenia (Platelet Count)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="Thrombocytopenia"
                    label=""
                    name="thrombocytopenia"
                    value={addFormData?.thrombocytopenia}
                    variant="outlined"
                    size="small"
                    //required
                    className={
                      "dataentry_input " +
                      (errorFields.thrombocytopenia ? "errorField" : "")
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          Count
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyThrombocytopenia(e);
                    }}
                    error={
                      errorFields.thrombocytopenia &&
                      showThrombocytopeniaErrorMessage
                    }
                    errorText={showThrombocytopeniaErrorMessage}
                    helperText={
                      errorFields.thrombocytopenia &&
                      showThrombocytopeniaErrorMessage
                        ? showThrombocytopeniaErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowThrombocytopeniaErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            thrombocytopenia: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Total Leucocytes Count"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Total Leucocytes Count
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="TotalLeucocytesCount"
                    name="total_leucocute_count"
                    label=""
                    variant="outlined"
                    //required
                    value={addFormData?.total_leucocute_count}
                    className={
                      "dataentry_input " +
                      (errorFields.total_leucocute_count ? "errorField" : "")
                    }
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          cells/µL
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyTotalLeucocytesCount(e);
                    }}
                    error={
                      errorFields.total_leucocute_count &&
                      showTotalLeucocuteCountErrorMessage
                    }
                    errorText={showTotalLeucocuteCountErrorMessage}
                    helperText={
                      errorFields.total_leucocute_count &&
                      showTotalLeucocuteCountErrorMessage
                        ? showTotalLeucocuteCountErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowTotalLeucocuteCountErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            total_leucocute_count: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Absolute Neutrophil Count"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Absolute Neutrophil Count
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="AbsoluteNeutrophilCount"
                    name="absolute_neutrophil_count"
                    label=""
                    variant="outlined"
                    //required
                    value={addFormData?.absolute_neutrophil_count}
                    className={
                      "dataentry_input " +
                      (errorFields.absolute_neutrophil_count
                        ? "errorField"
                        : "")
                    }
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          cells/µL
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyAbsoluteNeutrophilCount(e);
                    }}
                    error={
                      errorFields.absolute_neutrophil_count &&
                      showAbsoluteNeutrophilCountErrorMessage
                    }
                    errorText={showAbsoluteNeutrophilCountErrorMessage}
                    helperText={
                      errorFields.absolute_neutrophil_count &&
                      showAbsoluteNeutrophilCountErrorMessage
                        ? showAbsoluteNeutrophilCountErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowAbsoluteNeutrophilCountErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            absolute_neutrophil_count: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Blood Glucose"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Blood Glucose
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="BabyBloodGlucose"
                    name="baby_blood_glucose"
                    label=""
                    variant="outlined"
                    //required
                    value={addFormData?.baby_blood_glucose}
                    className={
                      "dataentry_input " +
                      (errorFields.baby_blood_glucose ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyBloodGlucose(e);
                    }}
                    error={
                      errorFields.baby_blood_glucose &&
                      showBabyBloodGlucoseErrorMessage
                    }
                    errorText={showBabyBloodGlucoseErrorMessage}
                    helperText={
                      errorFields.baby_blood_glucose &&
                      showBabyBloodGlucoseErrorMessage
                        ? showBabyBloodGlucoseErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBabyBloodGlucoseErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_blood_glucose: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mmol/L
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Haemoglobin Level"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Haemoglobin Level
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="BabyHaemoglobinLevels"
                    name="baby_haemoglobin_levels"
                    label=""
                    variant="outlined"
                    //required
                    value={addFormData?.baby_haemoglobin_levels}
                    className={
                      "dataentry_input " +
                      (errorFields.baby_haemoglobin_levels ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyHaemoglobinLevels(e);
                    }}
                    error={
                      errorFields.baby_haemoglobin_levels &&
                      showBabyHaemoglobinLevelsErrorMessage
                    }
                    errorText={showBabyHaemoglobinLevelsErrorMessage}
                    helperText={
                      errorFields.baby_haemoglobin_levels &&
                      showBabyHaemoglobinLevelsErrorMessage
                        ? showBabyHaemoglobinLevelsErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBabyHaemoglobinLevelsErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_haemoglobin_levels: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          g/dL
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="C-Reactive Protein Level"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    C-Reactive Protein Level
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="CReactiveProtien"
                    name="baby_c_reactive_protien_levels"
                    label=""
                    //required
                    variant="outlined"
                    value={addFormData?.baby_c_reactive_protien_levels}
                    className={
                      "dataentry_input " +
                      (errorFields.baby_c_reactive_protien_levels
                        ? "errorField"
                        : "")
                    }
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mg/l
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyCReactiveProtien(e);
                    }}
                    error={
                      errorFields.baby_c_reactive_protien_levels &&
                      showCRPLevelErrorMessage
                    }
                    errorText={showCRPLevelErrorMessage}
                    helperText={
                      errorFields.baby_c_reactive_protien_levels &&
                      showCRPLevelErrorMessage
                        ? showCRPLevelErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowCRPLevelErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_c_reactive_protien_levels: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Bilirubin level"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Bilirubin level
                   {/*  <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="BilirubinLevels"
                    name="bilirubin_levels"
                    label=""
                    variant="outlined"
                    //required
                    value={addFormData?.bilirubin_levels}
                    className={
                      "dataentry_input " +
                      (errorFields.bilirubin_levels ? "errorField" : "")
                    }
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mg/dL
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBilirubinLevels(e);
                    }}
                    error={
                      errorFields.bilirubin_levels &&
                      showBilirubinLevelsErrorMessage
                    }
                    errorText={showBilirubinLevelsErrorMessage}
                    helperText={
                      errorFields.bilirubin_levels &&
                      showBilirubinLevelsErrorMessage
                        ? showBilirubinLevelsErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBilirubinLevelsErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            bilirubin_levels: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Chloride"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Chlorine Level
                   {/*  <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="Chlorine"
                    name="chlorine"
                    label=""
                    variant="outlined"
                    value={addFormData?.chlorine}
                    className={
                      "dataentry_input " +
                      (errorFields.chlorine ? "errorField" : "")
                    }
                    size="small"
                    //required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mmol/L
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyChlorine(e);
                    }}
                    error={errorFields.chlorine && showChlorineErrorMessage}
                    errorText={showChlorineErrorMessage}
                    helperText={
                      errorFields.chlorine && showChlorineErrorMessage
                        ? showChlorineErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowChlorineErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            chlorine: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Calcium (Ca)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Calcium Level(Ca)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="Calcium"
                    name="calcium"
                    label=""
                    variant="outlined"
                    value={addFormData?.calcium}
                    //required
                    className={
                      "dataentry_input " +
                      (errorFields.calcium ? "errorField" : "")
                    }
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mmol/L
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyCalcium(e);
                    }}
                    error={errorFields.calcium && showCalciumErrorMessage}
                    errorText={showCalciumErrorMessage}
                    helperText={
                      errorFields.calcium && showCalciumErrorMessage
                        ? showCalciumErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowCalciumErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            calcium: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Sodium (Na)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Sodium Level (Na)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="Sodium"
                    name="sodium"
                    label=""
                    variant="outlined"
                    value={addFormData?.sodium}
                    //required
                    className={
                      "dataentry_input " +
                      (errorFields.sodium ? "errorField" : "")
                    }
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mEq/L
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifySodium(e);
                    }}
                    error={errorFields.sodium && showSodiumErrorMessage}
                    errorText={showSodiumErrorMessage}
                    helperText={
                      errorFields.sodium && showSodiumErrorMessage
                        ? showSodiumErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowSodiumErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            sodium: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Potassium (K)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Potassium Level(K)
                  
                  </label>
                  <TextField
                    id="Potassium"
                    name="potassium"
                    label=""
                    variant="outlined"
                    //required
                    value={addFormData?.potassium}
                    className={
                      "dataentry_input " +
                      (errorFields.potassium ? "errorField" : "")
                    }
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mmol/L
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyPotassium(e);
                    }}
                    error={errorFields.potassium && showPotassiumErrorMessage}
                    errorText={showPotassiumErrorMessage}
                    helperText={
                      errorFields.potassium && showPotassiumErrorMessage
                        ? showPotassiumErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowPotassiumErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            potassium: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Urea"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Urea Level
                   </label>
                  <TextField
                    id="Urea"
                    name="urea"
                    label=""
                    variant="outlined"
                    value={addFormData?.urea}
                    className={
                      "dataentry_input " +
                      (errorFields.urea ? "errorField" : "")
                    }
                    size="small"
                    //required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mmol/L
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyUrea(e);
                    }}
                    error={errorFields.urea && showUreaErrorMessage}
                    errorText={showUreaErrorMessage}
                    helperText={
                      errorFields.urea && showUreaErrorMessage
                        ? showUreaErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowUreaErrorMessage("Only Numerics are Acceptable");
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            urea: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Creatinine"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Creatinine Level
                    </label>
                  <TextField
                    id="Creatinine"
                    name="creatinine"
                    label=""
                    variant="outlined"
                    value={addFormData?.creatinine}
                    className={
                      "dataentry_input " +
                      (errorFields.creatinine ? "errorField" : "")
                    }
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mmol/L
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyCreatinine(e);
                    }}
                    error={errorFields.creatinine && showCreatinineErrorMessage}
                    errorText={showCreatinineErrorMessage}
                    helperText={
                      errorFields.creatinine && showCreatinineErrorMessage
                        ? showCreatinineErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowCreatinineErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            creatinine: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "20px",
                }}
              >
                {isValid ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    aria-label="add"
                    className="buttonStyle sign-up-button"
                    type="submit"
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="gray"
                    aria-label="add"
                    className="buttonStyle1 sign-up-button"
                    disabled
                  >
                    Save
                  </Button>
                )}
              </div>
            </form>
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  );
};

export default BabySemiInvasive;
