import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  TextField,
  IconButton,
  Alert,
  Snackbar,
} from "@mui/material";
import File from "../../common/assets/images/File.svg";
import Download from "../../common/assets/images/Download.svg";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import OtpInput from "react-otp-input";

import "../../login/styles/login.scss";
import "../../dataEntry/styles/DataEntry.scss";

import logo from "../../common/assets/images/avyantra_logo.png";
import prelogo from "../../common/assets/images/presco_logo.png";
import { adminVerificationKey } from "../reducers/signupDataThunk";
import {
  setErrorMessage,
  setsuccessMessage,
} from "../../dataEntry/reducers/patientDataThunk";

const AdminVerification = (props) => {
  // const userByVK = useSelector((state) => state.signup.userByVerificationKey);
  const patient = useSelector((state) => state.patient);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [OTPForm, setOTPForm] = React.useState("");
  const [OTP, setOTP] = useState("");

  const verifyKey = useParams();
  const verificationKey = verifyKey.id;

  /*  useEffect(() => {
    const req = { verification_key: verificationKey };
    dispatch(adminVerificationKey(req))
      .unwrap()
      .then((resdata) => {});
  }, []); */

  const handleFormChange = (otp) => {
    // console.log("We are in handle form change");
    const fieldname = otp;
    // console.log("Field Name is ");
    // console.log(fieldname);

    const fieldvalue = otp;
    // console.log("Field Value is ");
    // console.log(fieldvalue);

    const newFormData = { ...OTPForm };
    newFormData[fieldname] = fieldvalue;
    setOTP(otp);
  };
  const loginSubmit = (e) => {
    e.preventDefault();

    const req = { verification_key: verificationKey, otp: OTP };
    dispatch(adminVerificationKey(req))
      .unwrap()
      .then((resdata) => {
        if (resdata.error === false) {
          navigate("/reset-password/" + verificationKey);
        } else {
          dispatch(setErrorMessage(resdata.message));
        }
      });
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={12}>
        <Grid container style={{
              marginTop: "3%",
            }}>
            <Grid item xs={12} sm={12}>
              <img src={prelogo} className="pre_img" />
              <div className="pre_caption">
                Neonatal Sepsis Prediction Platform
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
        <Box className="login-container">
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              marginTop: "3%",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              //columns={{ xs: 1, sm: 12, md: 6 }}
            >
              <Card variant="outlined" className="login_radius">
                <CardContent style={{ padding: 0 }}>
                <p className="login_welcome">WELCOME!</p>
                  <p
                    className="forgot_cont"
                    style={{
                      fontSize: "15px",
                      marginTop: "10%",
                      fontWeight: 600,
                    }}
                  >Verification</p>
                  <p className="forgot_cont">
                  Please enter the verification code that has <br /> been sent
                  to your registered mobile number
                  </p>
                  <form id="mother-medical-form" onSubmit={loginSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} className="OTP_Grid">
                        <OtpInput
                          numInputs={6}
                          separator={<span style={{ paddingRight: 10 }}></span>}
                          onChange={(otp) => handleFormChange(otp)}
                          otpType="number"
                          value={OTP}
                          size="Large"
                          id="username"
                          name="username"
                          inputStyle={{
                            width: "2.4em",
                            height: "2.4em",
                            borderRadius: "7px",
                            background: "#F6F5FA",
                            border: "0.75px",
                            fontSize: "15px",
                            fontWeight: "600",
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}
                        className="OTP_Grid"
                        style={{ marginBottom: "5%" }}>
                        <Button
                          type="submit"
                          variant="contained"
                          className="sendcode_button sign-up-button"
                          style={{ textTransform: "none" }}
                        >
                          Confirm
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} 
                        style={{ marginBottom: "8%" }}>
                        <p className="newAccReDirect">
                          Don't have an account?
                          <Link to="/Signup" 
                          className="signup_link">Sign Up</Link>
                        </p>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <div className="signUpBg">
        <Grid className="copy-posi">
          <p className="Copyright">
            Copyright © 2019-2024 Avyantra Health Technologies. All Rights
            Reserved
          </p>
        </Grid>
      </div>
      {patient.errorMessage && (
        <Snackbar
          open={patient.errorMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {patient.errorMessage}
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
};

export default AdminVerification;
