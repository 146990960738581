import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Card,
  CardContent,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import AddAdmin from "./AddAdmin";

import {
  mapAdminWithBranch,
  getmapedAdminDetail,
} from "../../reducers/adminThunk";

import { mapData } from "../../reducers/wardInitialState";
import DeleteAdmin from "./DeleteAdmin";
import AdminEditImg from "../../../common/assets/images/components/admineditsvg";
import DeleteImg from "../../../common/assets/images/components/Deleteimg";
import "../../../dataEntry/styles/DataEntry.scss";

const AdminMap = (props) => {
  const [selectedBranches, setselectedBranches] = useState([]);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setselectedBranches(props.mapping.selectedBranches);
  }, [props.mapping]);

  const [branchdata, setBranchData] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [MapAdminList, setMapAdminList] = React.useState([]);
  const [deleteSelectedAdmin, setDelete] = React.useState(false);
  const [SelectedAdmin, setSelectedAdmin] = useState([]);
  const [showFormdata, setShowForm] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.mapping && props.mapping.branches)
      setBranchData(props.mapping.branches);
    else setBranchData([]);
  }, [props.mapping.branches]);

  const showForm = () => {
    setShowForm(!showFormdata);
  };

  const handleChange = (event) => {
    let selBranches = [...selectedBranches];
    if (event.target.checked) {
      selBranches.push(Number(event.target.value));
    } else {
      selBranches = selBranches.filter((s) => s != Number(event.target.value));
    }
    setselectedBranches(selBranches);
  };
  const HandleMapFormSubmit = (event) => {
    event.preventDefault();
    const req = {
      admin_id: props.mapping.id,
      branch_id: selectedBranches,
    };
    dispatch(mapAdminWithBranch(req));
    setShowForm(!showFormdata);
  };
  const closeDialog = () => {
    setDelete(false);
  };

  const editAdminData = () => {
    // console.log(props.mapping);
    props.editAdmin(props.mapping);
  };
  const deleteAdminData = () => {
    //props.deleteAdmin(props.mapping);
    setSelectedAdmin(props.mapping);
    setDelete(true);
  };

  const openDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid item xs={12} sm={4}>
      <Card>
        <CardContent>
          <form
            id="map-form"
            className="formSection"
            onSubmit={HandleMapFormSubmit}
          >
            <div className="branchname">
              {props.mapping.admin_name}
              {/* <span onClick={editAdminData} style={{ cursor: "pointer" }}>
                <AdminEditImg />
              </span>
              <span onClick={deleteAdminData} style={{ cursor: "pointer" }}>
                <DeleteImg />
              </span>
              {deleteSelectedAdmin && (
                <DeleteAdmin
                  SelectedAdmin={SelectedAdmin}
                  open={deleteSelectedAdmin}
                  onClose={closeDialog}
                />
              )} */}
            </div>
            <Grid container>
              <Grid item xs={12} sm={10}>
                <ListItem className="branch_map_title">Branches</ListItem>
              </Grid>
              <Grid item xs={12} sm={2}>
                <ListItem className="branch_map_length">
                  {selectedBranches.length}
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={12}></Grid>
              <Grid item xs={12} sm={12}>
                {!showFormdata && (
                  <Button
                    variant="contained"
                    className="wardadd"
                    type="submit"
                    onClick={showForm}
                  >
                    <span> + Branch</span>
                  </Button>
                )}
                {showFormdata && (
                  <Button variant="contained" className="wardadd" type="submit">
                    <span>Save</span>
                  </Button>
                )}
              </Grid>
            </Grid>
            {!showFormdata && selectedBranches.length > 0 && (
              <List className="branch-wardlist">
                {branchdata
                  .filter((w) =>
                    selectedBranches.includes(w.hospital_branch_id)
                  )
                  .map((value) => {
                    return (
                      <ListItem key={value.hospital_branch_id} disablePadding>
                        <ListItemText
                          className="wardlistitem"
                          primary={value.branch_name}
                        />
                      </ListItem>
                    );
                  })}
              </List>
            )}
            {showFormdata && (
              <List className="branch-wardlist">
                {branchdata.map((value) => {
                  // console.log('abcd',value.hospital_branch_id)
                  return (
                    <ListItem key={value.hospital_branch_id} disablePadding>
                      <ListItemIcon className="wardlistcheck">
                        <Checkbox
                          checked={selectedBranches.includes(
                            value.hospital_branch_id
                          )}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          value={value.hospital_branch_id}
                        />
                      </ListItemIcon>
                      <ListItemText
                        className="wardlistitem"
                        primary={value.branch_name}
                      />
                    </ListItem>
                  );
                })}
              </List>
            )}
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AdminMap;
