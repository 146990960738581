import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, MenuItem, Select, Card, CardContent } from "@mui/material";
import "../../dataEntry/styles/DataEntry.scss";
import "../../common/styles/header.scss";
import * as _ from "lodash";
import {
  getBranchDetail,
  getBranchDetailByUser,
} from "../../Dashboard/reducers/Dashboardthunk";

import {
  getDashBoardStatistics,
  setSelectedBranch,
} from "../reducers/ReportsDataThunk";
const ReportsHeader = () => {
  const user = useSelector((state) => state.user);
  const dbbranchdata = useSelector((state) => state.dashboard);
  const statistics = useSelector((state) => state.Reports?.DashBoardStatistics);

  const selbranch = useSelector((state) => state.Reports?.SelectedBranchdata);
  const [selectedBranch, updateSelectedBranch] = React.useState(
    selbranch && selbranch.hospital_branch_id
      ? selbranch.hospital_branch_id
      : -1
  );
  const [branchdata, setbranchdata] = React.useState([]);
  const [branchname, setbranchname] = useState(selbranch);
  const dispatch = useDispatch();
  const userdata = sessionStorage.getItem("user");
  const is_admin = userdata ? JSON.parse(userdata).is_admin : false;
  useEffect(() => {
    if (is_admin) {
      setbranchdata(dbbranchdata.branchList?.branches);
    } else {
      setbranchdata(dbbranchdata.branchList);
    }
  }, [dbbranchdata.branchList]);

  useEffect(() => {
    if (user?.userProfile?.hospital_id) {
      if (is_admin) {
        const req1 = { userId: user.userProfile?.user_id };
        dispatch(getBranchDetailByUser(req1));
      } else {
        const req = { hospital_id: user.userProfile.hospital_id };
        dispatch(getBranchDetail(req));
      }
    }
  }, [user?.userProfile?.hospital_id]);

  const onBranchSelected = (e) => {
    setbranchname(branchname);
    const branch_id = e.target.value;
    const hospitalId = user.userProfile.hospital_id;
    const req = { branch_id, hospitalId };

    const index = _.findIndex(branchdata, { hospital_branch_id: branch_id });

    updateSelectedBranch(branch_id);
    dispatch(setSelectedBranch(branchdata[index]));
    dispatch(getDashBoardStatistics(req));
  };

  return (
    <div className="dataoutlet">
      <Grid container>
        <Grid item xs={12} sm={12}>
          <div className="bmrncontent">
            <Card variant="none">
              <CardContent className="bmrn-data">
                <div>
                  <Grid container>
                    <Grid item xs={12} sm={2}>
                      <Grid container>
                        {/* <Grid item xs={12} sm={5} className="bmrn">
                          Hospital
                          <br />
                          <div className="report-header-value">
                            {user.userProfile?.hospital_name}
                          </div>
                        </Grid> */}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          className="bmrn"
                          style={{ textAlign: "center" }}
                        >
                          BRANCH
                          <br />
                          <Select
                            labelId="Bramch"
                            id="Bramch"
                            name="Bramch"
                            label=""
                            size="small"
                            className="dataentry_select_edu"
                            value={selectedBranch}
                            onChange={onBranchSelected}
                          >
                            {branchdata && branchdata.length
                              ? branchdata.map((num) => (
                                  <MenuItem value={num.hospital_branch_id}>
                                    {num.branch_name}
                                  </MenuItem>
                                ))
                              : undefined}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          className="bmrn"
                          style={{ textAlign: "center", fontSize: "1.1em" }}
                        >
                          Babies Admitted
                          <br />
                          <div
                            className="report-header-value"
                            style={{ color: "#000" }}
                          >
                            {statistics.babiesAdmitted < 10
                              ? `0${statistics.babiesAdmitted}`
                              : statistics.babiesAdmitted}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          className="bmrn"
                          style={{ textAlign: "center", fontSize: "1.1em" }}
                        >
                          Sepsis Positive
                          <br />
                          <div
                            className="report-header-value"
                            style={{ color: "#F48351" }}
                          >
                            {statistics.positiveSepsis < 10
                              ? `0${statistics.positiveSepsis}`
                              : statistics.positiveSepsis}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          className="bmrn"
                          style={{ textAlign: "center", fontSize: "1.1em" }}
                        >
                          Sepsis Negative
                          <br />
                          <div
                            className="report-header-value"
                            style={{ color: "#74D976" }}
                          >
                            {statistics.negativeSepsis < 10
                              ? `0${statistics.negativeSepsis}`
                              : statistics.negativeSepsis}
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={2}
                          className="bmrn"
                          style={{ textAlign: "center", fontSize: "1.1em" }}
                        >
                          Discharged
                          <br />
                          <div
                            className="report-header-value"
                            style={{ color: "#000" }}
                          >
                            {statistics.babiesDischarged < 10
                              ? `0${statistics.babiesDischarged}`
                              : statistics.babiesDischarged}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          className="bmrn"
                          style={{ textAlign: "center", fontSize: "1.1em" }}
                        >
                          Deceased
                          <br />
                          <div
                            className="report-header-value"
                            style={{ color: "#F56D6D" }}
                          >
                            {statistics.babiesDeceased < 10
                              ? `0${statistics.babiesDeceased}`
                              : statistics.babiesDeceased}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          className="bmrn"
                          style={{ textAlign: "center", fontSize: "1.1em" }}
                        >
                          LAMA
                          <br />
                          <div
                            className="report-header-value"
                            style={{ color: "#F56D6D" }}
                          ></div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportsHeader;
