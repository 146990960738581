import { createAsyncThunk } from "@reduxjs/toolkit";
import PatientDataService from "../../api/apiCommonService";

export const setFormTab = createAsyncThunk("formTab", async (data) => data);
export const getUserDetails = createAsyncThunk(
  "getUserDetails",
  async (req) => {
    const res = await PatientDataService.getUserDetails(req);
    return res.data;
  }
);
export const updateProfileData = createAsyncThunk(
  "updateProfileData",
  async (req) => {
    const res = await PatientDataService.updateProfileData(req);
    return res.data;
  }
);

export const ProfileFileUpload = createAsyncThunk(
  "ProfileFileUpload",
  async (req) => {
    const res = await PatientDataService.ProfileFileUpload(req);
    return res.data;
  }
);

export const getStaffDetails = createAsyncThunk(
  "getStaffDetails",
  async (req) => {
    const res = await PatientDataService.getStaffDetails(req);
    return res.data;
  }
);
export const deleteUser = createAsyncThunk("deleteUser", async (userId) => {
  const res = await PatientDataService.deleteUser(userId);
  return res.data;
});
export const updateStaffPermission = createAsyncThunk(
  "updateStaffPermission",
  async (req) => {
    const res = await PatientDataService.updateStaffPermission(req);
    return res.data;
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async (req) => {
    const res = await PatientDataService.changePassword(req);
    return res.data;
  }
);

export const setErrorMessage = createAsyncThunk(
  "errorMessage",
  async (data) => data
);

export const getSubscriptions = createAsyncThunk(
  "getSubscriptions",
  async () => {
    const res = await PatientDataService.getSubscriptions();
    return res.data;
  }
);
