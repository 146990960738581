export const AP ="Andhra Pradesh";
export const ANI = "Andaman and Nicobar Islands";
export const ANP= "Arunachal Pradesh";
export const ASSAM ="Assam";
export const BIHAR ="Bihar";
export const CHANDIGARH= "Chandigarh";
export const CHHATTISGARH="Chhattisgarh";
export const DNH = "Dadar and Nagar Haveli";
export const DD= "Daman and Diu";
export const DELHI= "Delhi";
export const LAKSHADEEP= "Lakshadweep";
export const GOA="Goa";
export const GUJARAT = "Gujarat";
export const HARYANA = "Haryana";
export const HP = "Himachal Pradesh";
export const JK = "Jammu and Kashmir";
export const JHARKHAND= "Jharkhand";
export const KARNATAKA= "Karnataka";
export const KERALA = "Kerala";
export const MP= "Madhya Pradesh";
export const MAHARASHTRA= "Maharashtra";
export const MANIPUR= "Manipur";
export const MEGHALAYA = "Meghalaya";
export const MIZORAM= "Mizoram";
export const NAGALAND= "Nagaland";
export const ODISHA = "Odisha";
export const PUNJAB = "Punjab";
export const PUDUCHERRY= "Puducherry";
export const RAJASTHAN = "Rajasthan";
export const SIKKIM = "Sikkim";
export const TAMILNADU = "Tamil Nadu";
export const TELANGANA= "Telangana";
export const TRIPURA = "Tripura";
export const UP = "Uttar Pradesh";
export const UTTARAKHAND = "Uttarakhand";
export const WB= "West Bengal";

export const TELUGU_STATES =[AP,ANI,ANP,ASSAM,BIHAR,CHANDIGARH,CHHATTISGARH,DNH,DD,DELHI,LAKSHADEEP,GOA,GUJARAT,HARYANA,HP,JK,JHARKHAND,KARNATAKA,KERALA,MP,MAHARASHTRA,MANIPUR,MEGHALAYA,MIZORAM,NAGALAND,ODISHA,PUNJAB,PUDUCHERRY,RAJASTHAN,SIKKIM,TAMILNADU,TELANGANA,TRIPURA,UP,UTTARAKHAND,WB]