import { createAsyncThunk } from "@reduxjs/toolkit";
import PatientDataService from "../../api/apiCommonService";

export const setFormTab = createAsyncThunk("formTab", async (data) => data);

export const accountcreation = createAsyncThunk(
  "accountcreation",
  async (req) => {
    const res = await PatientDataService.accountcreation(req);
    return res.data;
  }
);

export const multiPartFileUpload = createAsyncThunk(
  "multiPartFileUpload",
  async (req) => {
    const res = await PatientDataService.multiPartFileUpload(req);
    return res.data;
  }
);

export const isacccreate = createAsyncThunk(
  "isacccreate",
  async (data) => data
);
export const isaddinfo = createAsyncThunk("isaddinfo", async (data) => data);

export const AddAdditionalinformation = createAsyncThunk(
  "AddAdditionalinformation",
  async (req) => {
    const res = await PatientDataService.AddAdditionalinformation(req);
    return res.data;
  }
);

export const approveUser = createAsyncThunk("approveUser", async (req) => {
  const res = await PatientDataService.approveUser(req);
  return res.data;
});
export const VerifyRefDr = createAsyncThunk("VerifyRefDr", async (req) => {
  const res = await PatientDataService.VerifyRefDr(req);
  return res.data;
});

export const rejectUser = createAsyncThunk("rejectUser", async (req) => {
  const res = await PatientDataService.rejectUser(req);
  return res.data;
});

export const getusertypeDetails = createAsyncThunk(
  "getusertypeDetails",
  async () => {
    const res = await PatientDataService.getusertypeDetails();
    return res.data;
  }
);

export const getSubscriptionPlans = createAsyncThunk(
  "getSubscriptionPlans",
  async () => {
    const res = await PatientDataService.getSubscriptionPlans();
    return res.data;
  }
);
export const getActivePlans = createAsyncThunk("getActivePlans", async () => {
  const res = await PatientDataService.getActivePlans();
  return res.data;
});
export const getUserDetailsByVerificationKey = createAsyncThunk(
  "getUserDetailsByVerificationKey",
  async (verificationKey) => {
    const res = await PatientDataService.getUserDetailsByVerificationKey(
      verificationKey
    );
    return res.data;
  }
);
export const adminVerificationKey = createAsyncThunk(
  "adminVerificationKey",
  async (req) => {
    const res = await PatientDataService.adminVerificationKey(req);
    return res.data;
  }
);
export const subscriptionPopup = createAsyncThunk(
  "subscriptionPopup",
  async (data) => data
);

export const BacktoAccCreation = createAsyncThunk(
  "BacktoAccCreation",
  async (data) => data
);

export const BacktoAdddiInfo = createAsyncThunk(
  "BacktoAdddiInfo",
  async (data) => data
);

export const idverification = createAsyncThunk(
  "idverification",
  async (data) => data
);

export const termsconditions = createAsyncThunk(
  "termsconditions",
  async (data) => data
);

export const applyCoupon = createAsyncThunk("applyCoupon", async (req) => {
  const res = await PatientDataService.applyCoupon(req);
  return res.data;
});

export const initiatePayment = createAsyncThunk(
  "initiatePayment",
  async (req) => {
    const res = await PatientDataService.initiatePayment(req);
    return res.data;
  }
);
