import React from "react";

const DeleteImg = () => {
  return (
    <div
    //className="nav_fill_effect" style={{ display: "inline" }}
    >
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.92105 2.6V2.8H9.07895V2.6C9.07895 2.17565 8.91259 1.76869 8.61648 1.46863C8.32037 1.16857 7.91876 1 7.5 1C7.08124 1 6.67963 1.16857 6.38352 1.46863C6.08741 1.76869 5.92105 2.17565 5.92105 2.6ZM4.93421 2.8V2.6C4.93421 1.91044 5.20453 1.24912 5.68571 0.761522C6.16689 0.273928 6.81951 0 7.5 0C8.18049 0 8.83311 0.273928 9.31429 0.761522C9.79547 1.24912 10.0658 1.91044 10.0658 2.6V2.8H14.5066C14.6374 2.8 14.7629 2.85268 14.8555 2.94645C14.948 3.04021 15 3.16739 15 3.3C15 3.43261 14.948 3.55979 14.8555 3.65355C14.7629 3.74732 14.6374 3.8 14.5066 3.8H13.3551L12.5657 13.3424C12.5057 14.067 12.1792 14.7424 11.651 15.2346C11.1228 15.7269 10.4313 16 9.71368 16H5.28632C4.56876 15.9999 3.87741 15.7267 3.34926 15.2345C2.82112 14.7423 2.49471 14.067 2.43474 13.3424L1.64526 3.8H0.493421C0.362558 3.8 0.237054 3.74732 0.14452 3.65355C0.0519853 3.55979 0 3.43261 0 3.3C0 3.16739 0.0519853 3.04021 0.14452 2.94645C0.237054 2.85268 0.362558 2.8 0.493421 2.8H4.93421ZM6.51316 6.5C6.51316 6.43434 6.5004 6.36932 6.4756 6.30866C6.4508 6.248 6.41446 6.19288 6.36864 6.14645C6.32282 6.10002 6.26843 6.06319 6.20856 6.03806C6.1487 6.01293 6.08453 6 6.01974 6C5.95494 6 5.89078 6.01293 5.83091 6.03806C5.77105 6.06319 5.71665 6.10002 5.67084 6.14645C5.62502 6.19288 5.58867 6.248 5.56388 6.30866C5.53908 6.36932 5.52632 6.43434 5.52632 6.5V12.3C5.52632 12.3657 5.53908 12.4307 5.56388 12.4913C5.58867 12.552 5.62502 12.6071 5.67084 12.6536C5.71665 12.7 5.77105 12.7368 5.83091 12.7619C5.89078 12.7871 5.95494 12.8 6.01974 12.8C6.08453 12.8 6.1487 12.7871 6.20856 12.7619C6.26843 12.7368 6.32282 12.7 6.36864 12.6536C6.41446 12.6071 6.4508 12.552 6.4756 12.4913C6.5004 12.4307 6.51316 12.3657 6.51316 12.3V6.5ZM8.98026 6C8.7079 6 8.48684 6.224 8.48684 6.5V12.3C8.48684 12.4326 8.53883 12.5598 8.63136 12.6536C8.7239 12.7473 8.8494 12.8 8.98026 12.8C9.11113 12.8 9.23663 12.7473 9.32917 12.6536C9.4217 12.5598 9.47368 12.4326 9.47368 12.3V6.5C9.47368 6.224 9.25263 6 8.98026 6Z"
          fill="#F16565"
        />
      </svg>
    </div>
  );
};
export default DeleteImg;
